import { render, staticRenderFns } from "./Restrictions.vue?vue&type=template&id=08ae4779&scoped=true&"
import script from "./Restrictions.vue?vue&type=script&lang=js&"
export * from "./Restrictions.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08ae4779",
  null
  
)

export default component.exports