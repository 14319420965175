<style lang="scss" scoped>
.full-page-landscape {
  width: 10.2cm !important;
  height: 5.4cm !important;
}
.page-break {
  page-break-after: always;
}

</style>
<template>
    <v-container class="page-break full-page-landscape ma-0 pa-0">
      <v-layout
        column
        fill-height
        justify-space-between
      >
        <v-row class="fill-height" align="center" justify="center">
          <v-col cols="4">
            <div class="logo">
              <img :src="qrCodeDataURL" :style="`max-width:${qrCodeSize}px; max-height:${qrCodeSize}px;`"/>
<!--              <span class="d-print-none"><br/>{{ qrCodeValue }}</span>-->
            </div>
          </v-col>
          <v-col>
            <span style="font-size: 50px"> {{ labelShort }}</span>
          </v-col>
        </v-row>
      </v-layout>
    </v-container>
</template>
<script>

import BagCalc from "@/store/bag-calc";

export default {
  name: 'BinLabel',
  components: {},
  props: {
    label: String,
    qrCodeValue: String,
  },
  async mounted() {
    if (this.qrCodeValue) {
      this.qrCodeDataURL = await BagCalc.generateQRCodes(this.qrCodeValue, {size: this.qrCodeSize});
    }
  },
  methods: {
    capitalise(str) {
      if (!str) {
        return '';
      }
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
  },
  data() {
    return {
      qrCodeDataURL: null,
      qrCodeSize: 100
    }
  },
  computed: {
    labelShort() {
      const s = this.label;
      const parts = s.split('.');
      if (parts.length === 1) {
        const parts2 = s.split('-');
        return `${parts2[0][0]}-${parts2[1]}`;
      } else {
        const regexp = /^([A-Za-z]*)\.?([A-Za-z]).*-(\d+)$/;
        const [, rackName, rackNumber, binNumber] = (s || '').match(regexp);
        return `▣${rackName[0]}`
          + (rackNumber[0] ? `.${rackNumber}` : '')
          + `-${binNumber}`;
      }

    }
  }
};
</script>
