<template>
  <v-card flat>
    <v-card-actions class="d-print-none">
      <v-text-field
        class="d-print-none"
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        clearable
      />
      <v-spacer/>
      <v-select
        label="Filter by team"
        v-model="teamsSelected"
        :items="loading ? [] : teams"
        clearable
        multiple
        single-line
        hide-details
        :disabled="loading || disableTeamFilter"
      />
      <v-spacer/>
      <v-select
        label="Filter by diet (stream)"
        v-model="dietsSelected"
        :items="loading ? [] : diets"
        clearable
        multiple
        single-line
        hide-details
        :disabled="loading"
      />
      <v-spacer/>
      <v-checkbox
        label="Filing Breakdown"
        v-model="showFilingBreakdown"
      />
      <v-spacer/>
      <ProductionSelector
        :dates="dates"
        :production="production"
        v-on:update:dates="dates=$event"
        v-on:update:production="production=$event"
      />
    </v-card-actions>
    <v-card-actions class="d-print-none">
      <v-btn @click="showChecklist=!showChecklist">Show Checklist</v-btn>
    </v-card-actions>
    <v-card-text v-if="loading">
      Fetching {{ fetchingItem }}
    </v-card-text>
    <v-card-text v-if="!loading && showChecklist">

      <v-btn outlined @click="csvExport(dataAsTable,exportFilename())">Download CSV</v-btn>
      <br/>
      <v-data-table
        :headers="dataHeaders"
        :items="dataAsTable"
        disable-pagination
        hide-default-footer
        dense
      />
    </v-card-text>
    <v-card-text v-if="!loading && !showChecklist">
      <v-alert type="warning"
               v-if="hasUnassigned">
        Unassigned Components. Team Filter not unavailable until this is fixed. Click the meal links below to assign a
        team to each component.
      </v-alert>
      <v-alert color="error" outlined v-if="hasUnassigned">
        The following components do not have a team assigned
        <li v-for="(teamToMealIdMap,componentId) of unassignedComponents()"
            v-bind:key="componentId">
          {{ getComponentName(componentId) }} (C{{ componentId }}) -
          <router-link
            v-for="mealId of teamToMealIdMap.unassigned" v-bind:key="mealId"
            :to="{ name:'MealPrepWork', params:{ id: mealId}}"
            class="pr-2"
          >
            M{{ mealId }}
          </router-link>
        </li>
      </v-alert>
      <v-alert color="error" outlined v-if="Object.keys(overassignedComponents()).length>0">
        The following components have more than one team assigned
        <div v-for="(teamToMealIdMap,componentId) of overassignedComponents()"
             v-bind:key="componentId">
          <OverAssignedComponent :component="getComponent(componentId)" :teamToMealIdMap="teamToMealIdMap"/>
        </div>
      </v-alert>
      <v-card
        v-for="({component, totalAmount, totalAmountAllergy, meals, production_priority,id, batch}) of componentsFiltered"
        v-bind:key="id"
        class="mb-2"
        :class="`${production}-production`"
        style="page-break-inside: avoid"
      >
        <v-card-title>
          <v-chip outlined v-if="production_priority">P{{ production_priority }}</v-chip>
          <PrepTeamIcon :assignment="findAssignedTeam(component.id,meals).team"/>
          <span class="fed-component-name">{{ component.name }} (C{{ id }})</span>
          <v-icon color="blue" v-if="component.verified">mdi-shield-check</v-icon>
          <v-spacer/>
          <div class="circle" v-if="batch">{{ batch }}</div>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <template v-if="component.notes">
                <span class="caption">Notes for {{ component.name }}: </span>
                <br v-if="toLineArray(component.notes).length>1"/>
                <span class="dense" v-for="(line,i) of toLineArray(component.notes)" v-bind:key="i">{{
                    line
                  }}<br/>
              </span>
              </template>
            </v-col>
            <v-col class="text-lg-body-1" cols="3">
              <b>{{ formatWeightWithUnits(totalAmount - totalAmountAllergy) }}</b> Amount (no allergy): <br/>
              <template v-if="totalAmountAllergy>0">
                <b>{{ formatWeightWithUnits(totalAmountAllergy) }}</b> Allergy Sub Amount <br/>
                {{ formatWeightWithUnits(totalAmount) }} Total <br/>
              </template>
              Size Ratios:
              <template v-if="component.monosized">Monosize</template>
              <template v-if="!component.monosized">
                Small {{ Number(component.small_ratio).toFixed(2) }}
                Large {{ Number(component.large_ratio).toFixed(2) }}
              </template>
            </v-col>
          </v-row>
        </v-card-text>
        <!--        <h2>countUsingDefault(component,meals) {{ countUsingDefault(component, meals) }}</h2>-->
        <v-card-text v-if="hasDefaultComponent(component) && countUsingDefault(component,meals)>0">
          Component Ingredients
          <MealDetailTable
            :read-only="true"
            :title="component.name"
            :component="component || {}"
            :ingredients="component.component_foods || []"
            :show-prep-status="true"
            :show-nutrient-controls="false"
            sort-ingredients
            :target-total-cooked-amount="targetTotalCookedAmount(component,meals)"
            :target-portion-cooked-amount="targetPortionCookedAmount(component,meals)"
          />
        </v-card-text>
        <v-card-text>
          <h3 class="text-center" v-if="meals.filter(m => !isDifferentThanDefault(component,m)).length>0">
            {{ meals.filter(m => !isDifferentThanDefault(component, m)).length }} Meals Using This Component
          </h3>
          <v-row
            v-for="(meal,index) of meals.filter(m => !isDifferentThanDefault(component,m)).filter(m => getTotalMealCount(m.meal_id)>0)"
            v-bind:key="meal.meal_id"
            :style="index>0 ? 'border-top: 1px solid black':''"
          >
            <v-col cols="2" style="font-size: large" align-self="center">
              <span style="font-size: large">{{ index + 1 }} of {{
                  meals.filter(m => !isDifferentThanDefault(component, m)).filter(m => getTotalMealCount(m.meal_id) > 0).length
                }} </span>
              <p class="font-weight-bold">
                Recipe {{ formatWeightWithUnits(meal.totalAmount - meal.totalAmountAllergy) }}
              </p>
              <p v-if="meal.totalAmountAllergy">
                Sub {{ formatWeightWithUnits(meal.totalAmountAllergy) }}
              </p>
              <div style="font-size: small" class="" v-if="showFilingBreakdown">
                <v-card>
                  <v-card-text class="pa-2">
                    <strong>M</strong>
                    {{
                      formatWeightWithUnits(meal.totalAmount - (getTotalAllergyPlatedAmount(component, meal)))
                    }} recipe
                  </v-card-text>
                </v-card>
                <v-card v-if="getTotalAllergyPlatedAmount(component, meal)>0" class="mt-2">
                  <v-card-text class="pa-2">
                    <!--                    <div v-if="getTotalAllergyPlatedAmount(component, meal) - meal.totalAmountAllergy>0">-->
                    <div>
                      <strong>AL</strong>
                      {{
                        formatWeightWithUnits(getTotalAllergyPlatedAmount(component, meal) - meal.totalAmountAllergy)
                      }} recipe
                    </div>
                    <div v-if="meal.totalAmountAllergy>0">
                      <strong>S</strong> {{ formatWeightWithUnits(meal.totalAmountAllergy) }} sub
                    </div>
                  </v-card-text>
                </v-card>
              </div>
            </v-col>
            <v-col>
              <v-row class="text-center font-weight-bold">
                <!--                {{ allergyCountByMealId[meal.meal_id] }}-->
                <template v-if="component.monosized">
                  <v-col>
                    <v-chip outlined>
                      M
                      {{ formatWeightWithUnits(getComponentAmountForMeal(component, meal, 'medium')) }}
                      ({{ getTotalMealCount(meal.meal_id) }})
                    </v-chip>
                    <template
                      v-if="getRawComponentAmountForMeal(component, meal, 'medium')!==getComponentAmountForMeal(component, meal, 'medium')">
                      <br/>
                      raw {{ formatWeightWithUnits(getRawComponentAmountForMeal(component, meal, 'medium')) }}
                    </template>
                  </v-col>
                </template>
                <template v-if="!component.monosized">
                  <v-col outlined v-for="(count,size) of sortBySize(mealCountByMealId[meal.meal_id])" v-bind:key="size">
                    <template v-if="count>0">
                      <v-chip outlined>
                        {{ `${size[0]}`.toUpperCase() }}
                        {{ formatWeightWithUnits(getComponentAmountForMeal(component, meal, size)) }}
                        ({{ count }})
                      </v-chip>
                      <template
                        v-if="getRawComponentAmountForMeal(component, meal, size)!==getComponentAmountForMeal(component, meal, size)">
                        <br/>
                        raw {{ formatWeightWithUnits(getRawComponentAmountForMeal(component, meal, size)) }}
                      </template>
                    </template>
                  </v-col>
                </template>
              </v-row>
              <ChoppingMealDescription
                :meal="meal"
                :component="component"
                :date-formatted="dateFormatted(meal.date, {formatString: 'dddd'})"
                hide-component
              />
              <MealRestrictionSummary
                :meal="meal"
                :ingredients="(meal.meal_food||[]).filter(mf => mf.component_id === component.id)"
                :orders="orders.filter(o=>o.meal_id==meal.meal_id)"
                only-problems
              />
              <MealDetailTable
                v-if="!hasDefaultComponent(component) || differences[component.id] && differences[component.id][meal.meal_id]"
                :meal-id="meal.meal_id"
                :read-only="true"
                :title="component.name"
                :component="component || {}"
                :ingredients="(meal.meal_food||[]).filter(mf => mf.component_id === component.id)"
                :show-prep-controls="true"
                :show-nutrient-controls="false"
                :target-total-cooked-amount="targetCookedAmount(component,[meal])"
                sort-ingredients
              />
            </v-col>
          </v-row>
          <h3 v-if="meals.filter(m => isDifferentThanDefault(component,m)).length>0">
            {{ meals.filter(m => isDifferentThanDefault(component, m)).length }} Component Variations (different)
          </h3>
          <!--          <v-row v-for="(meal) of meals" v-bind:key="`variation-${meal.meal_id}`">-->
          <v-row v-for="(meal) of meals.filter(m => isDifferentThanDefault(component,m))"
                 v-bind:key="`variation-${meal.meal_id}`">
            <v-col cols="2" style="font-size: x-large" align-self="center">
              <p class="font-weight-bold">{{ formatWeightWithUnits(meal.totalAmount - meal.totalAmountAllergy) }}</p>
              <p v-if="meal.totalAmountAllergy">
                A{{ formatWeightWithUnits(meal.totalAmountAllergy) }}
              </p>
              <div style="font-size: small" class="" v-if="showFilingBreakdown">
                <v-card>
                  <v-card-text class="pa-2">
                    <strong>M</strong>
                    {{
                      formatWeightWithUnits(meal.totalAmount - (getTotalAllergyPlatedAmount(component, meal) + meal.totalAmountAllergy))
                    }} recipe
                  </v-card-text>
                </v-card>
                <v-card v-if="getTotalAllergyPlatedAmount(component, meal)>0" class="mt-2">
                  <v-card-text class="pa-2">
                    <div v-if="getTotalAllergyPlatedAmount(component, meal) - meal.totalAmountAllergy>0">
                      <strong>AL</strong>
                      {{
                        formatWeightWithUnits(getTotalAllergyPlatedAmount(component, meal) - meal.totalAmountAllergy)
                      }} recipe
                    </div>
                    <div v-if="meal.totalAmountAllergy>0">
                      <strong>S</strong> {{ formatWeightWithUnits(meal.totalAmountAllergy) }} sub
                    </div>
                  </v-card-text>
                </v-card>
              </div>
            </v-col>
            <v-col>
              <v-row class="text-center font-weight-bold">
                <template v-if="component.monosized">
                  <v-col>
                    <v-chip outlined>
                      M
                      {{ formatWeightWithUnits(getComponentAmountForMeal(component, meal, 'medium')) }}
                      ({{ getTotalMealCount(meal.meal_id) }})
                    </v-chip>
                    <template
                      v-if="getRawComponentAmountForMeal(component, meal, 'medium')!==getComponentAmountForMeal(component, meal, 'medium')">
                      <br/>
                      raw {{ formatWeightWithUnits(getRawComponentAmountForMeal(component, meal, 'medium')) }}
                    </template>
                  </v-col>
                </template>
                <template v-if="!component.monosized">
                  <v-col outlined v-for="(count,size) of sortBySize(mealCountByMealId[meal.meal_id])" v-bind:key="size">
                    <template v-if="count>0">
                      <v-chip outlined>
                        {{ `${size[0]}`.toUpperCase() }}
                        {{ formatWeightWithUnits(getComponentAmountForMeal(component, meal, size)) }}
                        ({{ count }})
                      </v-chip>
                      <template
                        v-if="getRawComponentAmountForMeal(component, meal, size)!==getComponentAmountForMeal(component, meal, size)">
                        <br/>
                        raw {{ formatWeightWithUnits(getRawComponentAmountForMeal(component, meal, size)) }}
                      </template>
                    </template>
                  </v-col>
                </template>
              </v-row>
              <ChoppingMealDescription
                :meal="meal"
                :component="component"
                :date-formatted="dateFormatted(meal.date, {formatString: 'dddd'})"
                hide-component
              />
              <MealRestrictionSummary
                :meal="meal"
                :ingredients="(meal.meal_food||[]).filter(mf => mf.component_id === component.id)"
                :orders="orders.filter(o=>o.meal_id==meal.meal_id)"
                only-problems
              />
              <MealDetailTable
                v-if="!hasDefaultComponent(component) || differences[component.id] && differences[component.id][meal.meal_id]"
                :meal-id="meal.meal_id"
                :read-only="true"
                :title="component.name"
                :component="component || {}"
                :ingredients="(meal.meal_food||[]).filter(mf => mf.component_id === component.id)"
                :show-prep-controls="true"
                :show-nutrient-controls="false"
                :target-total-cooked-amount="targetCookedAmount(component,[meal])"
                sort-ingredients
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<style scoped>

.ship-class {
  border-left: 4px solid blue;
}

.local-class {
  border-left: 4px solid grey;
}

.circle {
  text-align: center;
  padding: 0;
  margin: 5px;
  border: 3px solid black;
  height: 70px;
  border-radius: 50%;
  min-width: 75px;
  font-size: 20px;
  line-height: 65px;
  font-weight: bolder;
}
</style>


<script>
import {
  convertIngredient,
  csvExport,
  dateFormatted,
  formatWeight,
  formatWeightWithUnits, getComponentAmountForMeal,
  getRawComponentAmountForMeal,
  sortBySize
} from "@/store/utils";
import moment from "moment/moment";
import urlState from "@/router/urlState";
import api from "@/api";
import PrepTeamIcon from "@/components/PrepTeamIcon.vue";
import ChoppingMealDescription from "@/views/ChoppingMealDescription.vue";
import {mapActions, mapGetters, mapState} from "vuex";
import MealDetailTable from "@/components/MealDetailTable.vue";
import MealRestrictionSummary from "@/components/MealRestrictionSummary.vue";
import OverAssignedComponent from "@/components/OverAssignedComponent.vue";
import ProductionSelector from "@/components/ProductionSelector.vue";
import diff from 'microdiff';

export default {
  name: "ComponentPrep2",
  components: {
    ProductionSelector,
    OverAssignedComponent, MealRestrictionSummary, MealDetailTable, ChoppingMealDescription, PrepTeamIcon
  },
  mixins: [urlState],
  data() {
    return {
      dates: [],
      search: null,
      teamsSelected: null,
      dietsSelected: null,
      showDatePicker: null,
      loading: null,
      datePickerDate: null,
      ingredients: null,
      components: [],
      mealCountByMealId: null,
      allergyCountByMealId: null,
      meals: {},
      differences: null,
      orders: null,
      componentsWithSteps: [],
      showFilingBreakdown: true,
      fetchingItem: null,
      showChecklist: null,
      production: null,
      currentData: {dates: [], production: null}
    }
  },
  async mounted() {
    this.syncToUrl({
      param: 'dates', urlParam: 'dates', initFromRoute: true,
      parseCallback: (v) => Array.isArray(v) ? v : [v]
    });
    this.syncToUrl({
      param: 'production', urlParam: 'production', initFromRoute: true,
    });
    this.syncToUrl({
      param: 'search', urlParam: 'search', initFromRoute: true,
      // parseCallback: (v) => v === 'true'
    });
    this.syncToUrl({
      param: 'showFilingBreakdown', urlParam: 'filing', initFromRoute: true,
      parseCallback: (v) => v === 'true'
    });
    this.syncToUrl({
      param: 'dietsSelected', urlParam: 'diets', initFromRoute: true,
      parseCallback: (v) => Array.isArray(v) ? v.map(v => Number(v)) : [Number(v)]
    });
    this.syncToUrl({
      param: 'teamsSelected', urlParam: 'teams', initFromRoute: true,
      parseCallback: (v) => Array.isArray(v) ? v : [v]
    });
    await this.fetchDiets();
    this.$nextTick(() => this.fetchData());
  },
  watch: {
    dates: 'fetchData',
    production: 'fetchData',
  },
  methods: {
    ...mapActions(['fetchDiets']),
    csvExport,
    formatWeightWithUnits,
    formatWeight,
    dateFormatted,
    async fetchData() {
      const dates = this.dates;
      const production = this.production;
      if (!dates || dates.length === 0) {
        console.log('no dates, no fetch')
        return;
      }
      if (!production) {
        console.log('no production, no fetch');
        return;
      }

      const {dateFrom, dateTo} = this;
      if (diff(this.currentData, {dates, production}).length === 0) {
        console.log('already loaded', this.currentData);
        return
      } else {
        console.log('fetching prep', {dates, production});
        this.currentData = {dates, production};
      }

      this.loading = true;
      this.fetchingItem = 'component demand';
      const productions = [production];
      const result = await api.get('/v2/prep', {params: {dates, productions: productions, excludeCosts: true}});
      const {data} = result;
      console.log('fetched /v2/prep', data);
      this.allergyCountByMealId = data.allergyCountByMealId;
      this.mealCountByMealId = data.mealCountByMealId;
      const componentMap = data.components;
      let mealIds = Object.keys(this.mealCountByMealId);
      mealIds = mealIds.filter(id => {
        if (isNaN(id)) {
          console.warn('missing meal ' + id, {mealCount: this.mealCountByMealId[id]});
          return false;
        } else {
          return true;
        }
      });

      this.ingredients = data.ingredients;

      // sort by production priority
      function key(e) {
        const [, val] = e;
        if (val.batch) {
          const letters = val.batch.substring(0, 2);
          const numbers = val.batch.substring(2);
          return letters + `${numbers}`.padStart(3, '0');
        } else {
          const priority = val.production_priority || 'Z';
          return `${priority} ${val.component.name}`;
        }
      }

      if (mealIds.length === 0) {
        alert('there does not appear to be any orders for this time period');
        this.loading = false;
        this.meals = {};
        this.components = [];
        return;
      }
      this.fetchingItem = 'component recipes, meals and orders (for allergy information)';
      const [resultComponents, resultMeals, resultAudit, ordersResult] = await Promise.all([
        api.get('/v2/component/', {
          params: {
            where: {id: {in: Object.keys(componentMap).map(id => Number(id))}},
            limit: Object.keys(componentMap).length + 10
          }
        }),
        api.get('/v2/meal/', {
          params: {
            where: {id: {in: mealIds.map(id => Number(id))}},
            keepMealFood: true
          }
        }),
        api.get('v2/meal/audit', {params: {mealIds}}),
        api.get('v2/order/search', {
          params: {
            productions,
            from: dateFrom, to: dateTo,
            properties: ['meal_size', 'quantity', 'allergyDetected', 'allergies', 'meal_id']
          }
        })
      ]);

      console.log('fetched components', resultComponents.data);
      resultComponents.data.rows.forEach(c => {
        const component = componentMap[c.id].component;
        const componentFoods = c.component_foods;
        componentFoods.forEach(cf => {
          cf.component = component;
        });
        component.component_foods = componentFoods.map(convertIngredient);
      })
      this.components = Object
        .entries(componentMap)
        .sort((a, b) => key(a).localeCompare(key(b)))
        .map(([k, v]) => ({...v, id: Number(k)}));
      console.log('sorted', this.components);


      const meals = resultMeals.data.meals;
      console.log('loaded meals', meals);
      this.meals = Object.fromEntries(meals.map(m => ([m.id, m])));
      this.components.forEach(c => {
        c.meals = c.meals
          // no idea why this filter was here - does not make sense - mealId isn't even a valid prop here
          // .filter(m => this.getTotalMealCount(m.mealId) === 0)
          .map(m => {
            const meal = this.meals[m.meal_id];
            if (!meal.meal_food) {
              console.warn('no meal_food for' + m.meal_id, meal, m);
            }
            const meal_food = (meal.meal_food || []).map(convertIngredient);
            return {...meal, ...m, meal_food};
          })
      });

      {
        const data = resultAudit.data;
        console.log('audit data', data);
        // this.components = data.components.sort((a, b) => a.name.localeCompare(b.name));
        this.componentsWithSteps = data.components;
        this.componentAmounts = data.componentAmounts;
        this.defaultComponents = data.defaultComponents;
        this.variations = data.variations;
        this.differences = data.differences;
        // this.meals = data.meals;
        // this.loading = false;
      }

      const orders = ordersResult.data;
      console.log('orders', orders)
      this.orders = orders;
      this.loading = false;
    },
    getTotalMealCount(meal_id) {
      const count = this.mealCountByMealId[meal_id] || {};
      return Object.values(count).reduce((i, sum) => i + sum, 0);
    },
    toLineArray(str) {
      return str && str.split('\n');
    },
    // not in use
    updateMealPriority(meal_id, priority) {
      const productionPriority = priority === 'clear' ? null : priority;
      console.log('updateMealPriority', meal_id, productionPriority);
      return api.put(`v2/meal/${meal_id}`, {meal: {production_priority: productionPriority}})
        .then(r => console.log('saved', r))
        // this is overkill
        .then(() => this.fetchData());
    },
    hasDefaultComponent(component) {
      return component.component_foods && component.component_foods.length > 0;
    },
    combineCounts(meals) {
      const result = {};
      meals.forEach(({meal_id}) => {
        const count = this.mealCountByMealId[meal_id];
        console.log('count ' + meal_id, count);
        Object.keys(count)
          .forEach(size => {
            result[size] = result[size] || 0;
            result[size] += count[size];
          })
      });
      console.log('combined', result);
      return result;
    },
    isDifferentThanDefault(component, meal) {
      return !this.hasDefaultComponent(component) || this.differences[component.id] && this.differences[component.id][meal.meal_id]
    },
    targetTotalCookedAmount(component, meals) {
      return this.targetCookedAmount(component, meals.filter(m => !this.isDifferentThanDefault(component, m)));
    },
    targetCookedAmount(component, meals) {
      return meals.reduce((sum, i) => (i.totalAmount - i.totalAmountAllergy) + sum, 0);
    },
    targetPortionCookedAmount(component, meals) {
      const meal = meals.filter(m => !this.isDifferentThanDefault(component, m))[0];
      if (!meal) {
        return undefined;
      } else {
        return this.getComponentAmountForMeal(component, meal, 'medium');
      }
    },
    getRawComponentAmountForMeal,
    getComponentAmountForMeal,
    sortBySize,
    unassignedComponents() {
      return Object.fromEntries(
        this.components
          .map(({id, meals}) => [id, this.findAssignedTeams(meals)])
          .filter(e => !!e[1].unassigned)
      );
    },
    overassignedComponents() {
      return Object.fromEntries(
        this.componentsWithSteps
          .map(({id, meals}) => [id, this.findAssignedTeams(meals)])
          .filter(e => Object.keys(e[1]).length > 1)
      );
      // return componentIds.filter(componentId => this.findAssignedTeam(componentId, steps).team);
    },
    findAssignedTeam(componentId, componentMealAssignments) {
      const mealIds = componentMealAssignments.map(a => a.meal_id);
      const teams = componentMealAssignments.map(a => a.team);
      const team = teams[0];
      const allSameTeam = teams.every(t => t == team);
      if (!allSameTeam && allSameTeam) {
        api.showError({message: `more than one team was assigned to component C${componentId} : ${teams.join(',')}`});
      }
      return {componentId, team, mealIds}
    },
    findAssignedTeams(componentMealAssignments = []) {
      const result = {};
      componentMealAssignments.forEach(({team, meal_id}) => {
        if (team === null) {
          team = 'unassigned';
        }
        result[team] = result[team] || [];
        result[team].push(meal_id);
      })
      return result;
    },
    getComponentName(componentId) {
      const component = this.componentsWithSteps.find(c => c.id == componentId);
      if (!component) {
        console.error('could not find component id ', componentId, this.componentsWithSteps);
      }
      return component && component.name;
    },
    getComponent(componentId) {
      const component = this.componentsWithSteps.find(c => c.id == componentId);
      if (!component) {
        console.error('could not find component id ', componentId, this.componentsWithSteps);
      }
      return component;
    },
    countUsingDefault(component, meals) {
      return meals.map(m => this.isDifferentThanDefault(component, m) ? 0 : 1)
        .reduce((sum, i) => i + sum, 0);
    },
    getTotalAllergyPlatedAmount(component, meal) {
      const entries = Object.entries(this.allergyCountByMealId[meal.meal_id]);
      const map = entries
        .map(([size, count]) => count * this.getComponentAmountForMeal(component, meal, size));
      return map.reduce((sum, i) => i + sum, 0);
    },
    exportFilename() {
      return `component-checklist-${this.dates.join('-')}-${this.production}`;
    },
  },
  computed: {
    ...mapGetters(['getDietName']),
    ...mapState(['productionPriorities']),
    datesFormatted() {
      const format = 'dddd MMMM D';
      if (!this.dateFrom) {
        return '';
      } else if (this.dateFrom === this.dateTo) {
        return `${moment(this.dateFrom).format(format)}`;
      } else {
        return `${moment(this.dateFrom).format(format)} - ${moment(this.dateTo).format(format)}`
      }
    },
    dateFrom() {
      return [...this.dates].sort()[0];
    },
    dateTo() {
      return [...this.dates].sort().reverse()[0];
    },
    diets() {
      return [...new Set(this.components.flatMap(c => c.meals.map(m => m.diet)))]
        .map(id => ({text: this.getDietName(id), value: id}))
        .sort((a, b) => a.text.localeCompare(b.text));
    },
    teams() {
      return [...new Set(this.components.flatMap(c => c.meals.map(m => m.team)))].sort();
    },
    disableTeamFilter() {
      // should check that all components have team assigned and teams are same
      return this.hasUnassigned;
    },
    componentsFiltered() {
      let components = this.components;
      if (this.search) {
        const regExp = new RegExp(this.search, 'i')
        components = components.filter(c => regExp.test(c.component.name) || regExp.test(c.batch || ''))
      }
      if (this.teamsSelected && this.teamsSelected.length > 0) {
        components = components.filter(c => c.meals.some(m => this.teamsSelected.includes(m.team)));
      }
      if (this.dietsSelected && this.dietsSelected.length > 0) {
        components = components.filter(c => c.meals.some(m => this.dietsSelected.includes(m.diet)));
      }

      return components;
    },
    hasUnassigned() {
      return Object.keys(this.unassignedComponents()).length > 0;
    },
    dataAsTable() {
      const rows = [];
      for (const component of this.componentsFiltered) {
        const {totalAmountAllergy, production_priority, batch, id} = component;
        rows.push({
          priority: production_priority,
          batch,
          component: this.getComponentName(id),
          main: '',
          allergy: '', // totalAmountAllergy === 0 ? 'N/A' : '',
          substitute: totalAmountAllergy === 0 ? 'N/A' : ''
        });
      }
      console.log('rows', rows);

      function key(val) {
        let batch = '';
        if (val.batch) {
          const letters = val.batch.substring(0, 2);
          const numbers = val.batch.substring(2);
          batch = letters + `${numbers}`.padStart(3, '0');
        }
        const priority = val.priority || 'Z';
        return `${priority}`.padStart(2, '0') + `${batch} ${val.component.name}`;
      }

      rows.sort((a, b) => key(a).localeCompare(key(b)))
      return rows;
    },
    dataHeaders() {
      function format(text) {
        const result = text.replace(/([A-Z])/g, " $1");
        return result.charAt(0).toUpperCase() + result.slice(1);
      }

      return Object.keys(this.dataAsTable[0] || {})
        .map(k => ({text: format(k), value: k}));
    },
  },
  props: {
    readOnly: {type: Boolean, default: false, required: false},
  }
}
</script>
