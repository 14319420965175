import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.meal)?_c(VContainer,[_c(VRow,[_c(VCol,[_c('h1',[_vm._v(_vm._s(_vm.meal.name))])])],1),(_vm.missingComponentIds.length>0)?_c(VRow,[_c(VCol,[_c(VAlert,{attrs:{"text":"","outlined":"","type":"warning","value":_vm.missingComponentIds.length>0}},[_vm._v("Missing component ids "+_vm._s(_vm.missingComponentIds)+" ")])],1),_c(VCol,[_c(VBtn,{attrs:{"outlined":"","color":"success"},on:{"click":_vm.fixMissing}},[_vm._v("Create Missing Components")])],1)],1):_vm._e(),_vm._l((_vm.mealComponents),function(mc){return _c(VRow,{key:mc.id,attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"1"}},[_c('router-link',{attrs:{"to":{ name: 'ComponentDetail', params: { id : mc.component_id }}}},[_vm._v("C"+_vm._s(mc.component_id)+" ")])],1),_c(VCol,{staticClass:"my-0 py-0",attrs:{"cols":"1"}},[_c(VTextField,{staticClass:"mt-0",attrs:{"reverse":"","prefix":"g","dense":"","single-line":"","value":_vm.formatWeight(mc.amount),"type":"number"},model:{value:(mc.amount),callback:function ($$v) {_vm.$set(mc, "amount", $$v)},expression:"mc.amount"}})],1),_c(VCol,[_vm._v(" "+_vm._s(_vm.getComponentName(mc.component_id))+" ")])],1)}),_c(VRow,[_c(VCol,{attrs:{"cols":"1"}}),_c(VCol,{staticClass:"my-0 py-0 pr-1",staticStyle:{"text-align":"right"},attrs:{"cols":"1"}},[_vm._v(" "+_vm._s(_vm.formatWeight(_vm.totalWeight))+" g ")]),_c(VCol)],1),(_vm.missingComponentIds.length>0)?_c(VRow,[_c(VCol,[_c('h2',[_vm._v("The following ingredients do not have a meal component (debug, to clean up)")]),_c('pre',[_vm._v(_vm._s(_vm.ingredients.filter(function (i) { return _vm.missingComponentIds.includes(i.component_id); })))])])],1):_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }