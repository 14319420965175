<template>
  <v-card flat>
    <v-card-title>Driver Confirmation for {{ date }}
      <v-spacer/>
      <v-chip class="mr-2" outlined color="green">{{ count(status, 'confirmed') }} Confirmed</v-chip>
      <v-chip class="mr-2" outlined color="red">{{ count(status, 'rejected') }} Rejected</v-chip>
      <v-chip class="mr-2" outlined color="orange">{{ count(status, 'sent') }} Waiting for Reply</v-chip>
      <v-chip class="mr-2" outlined color="black">{{ count(status, 'new') }} No Message Sent</v-chip>
    </v-card-title>
    <v-card-text>
      Confirmation messages are sent at 7am to all drivers who are assigned deliveries today.  If a driver is added after 7am, they will not receive any confirmation text.
      <v-row dense>
        <v-col cols="6" md="3" v-for="({phone,...driverStatus}) of driverStatuses" v-bind:key="phone">
          <v-card>
            <v-card-text>
              <v-chip small outlined :color="getColor(driverStatus.status)">{{ driverStatus.status }}</v-chip>
              {{ driverStatus.name }} <a v-bind:href="`tel:${phone}`">{{ phone }}</a>
              <br/>
              first eta {{ driverStatus.firstEta }} - last eta {{ driverStatus.lastEta }} | {{ driverStatus.deliveryCount }} deliveries
              <ul>
                <li v-for="({time,text},i) of driverStatus.messages||[]" v-bind:key="i">
                  {{ dateFormatted(time, {formatString: 'LLL'}) }} : {{ text }}
                </li>
              </ul>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import api from "@/api";
import moment from "moment";
import {dateFormatted} from "@/store/utils";

export default {
  name: "Drivers",
  mounted() {
    this.fetchDriverStatus()
  },
  destroyed() {
  },
  methods: {
    dateFormatted,
    fetchDriverStatus() {
      api.get(`/v2/drivers/status/${this.date}`)
        .then(({data}) => {
          this.status = data;
        });
    },
    getColor(s) {
      const map = {
        rejected: 'red',
        sent: 'orange',
        confirmed: 'green',
        new: 'black'
      }
      return map[s] || 'blue';
    },
    count(statuses, statusToCount) {
      return Object.values(statuses || {})
        .filter(({status}) => statusToCount === status)
        .length;
    }
  },
  data() {
    return {
      date: moment().format(moment.HTML5_FMT.DATE),
      status: {}
    }
  },
  computed: {
    driverStatuses() {

      function key(a) {
        const map ={
          rejected: 1,
          sent: 2,
          confirmed : 4,
          new: 3
        }
        return `${map[a.status]} ${a.name} ${a.phone}`;
      }
      return Object.entries(this.status || {})
        .map(([phone,values])=> ({ ...values,phone}))
        .sort((a,b)=> key(a).localeCompare(key(b)));
    }

  }
}
</script>

<style scoped>

</style>