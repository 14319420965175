import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mb-4",attrs:{"outlined":""}},[_c(VCardTitle,{staticClass:"pt-1"},[(_vm.meal)?_c('span',[(_vm.meal.diet)?_c(VChip,{attrs:{"text-color":"white","small":"","color":_vm.getDietColor(_vm.meal.diet)}},[_vm._v(" "+_vm._s(_vm.getDietName(_vm.meal.diet))+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.meal.name)+" "),_c('router-link',{attrs:{"to":{ name: 'MealDesign', params: { date: _vm.formatDate(_vm.meal.date), id: _vm.meal.id }}}},[_vm._v("M"+_vm._s(_vm.meal.id))]),(_vm.meal.is_selected)?[_c(VIcon,{attrs:{"color":"yellow"}},[_vm._v("mdi-star")])]:_vm._e(),_vm._v(" "+_vm._s(_vm.formatDate(_vm.meal.date))+" "),_c('router-link',{attrs:{"to":{ name: 'MealVersions', params: { sku: _vm.meal.sku }}}},[_vm._v(" "+_vm._s(_vm.meal.sku)+" ")]),_vm._v("  ")],2):_vm._e(),(!_vm.meal)?_c('span',[_vm._v(" "+_vm._s(_vm.component.name)+" ingredients ")]):_vm._e(),_c(VSpacer)],1),_c(VCardText,{staticClass:"pt-0"},[(!_vm.readOnly || _vm.assigned)?_c(VRow,{staticClass:"pa-2"},[_c(VCol,{attrs:{"cols":"3"}},[_c(VSelect,{attrs:{"disabled":_vm.readOnly,"label":"Assigned Team","chips":"","items":_vm.defaultTeams,"clearable":""},on:{"change":function($event){return _vm.saveComponentAssignment($event)}},model:{value:(_vm.assigned),callback:function ($$v) {_vm.assigned=$$v},expression:"assigned"}})],1)],1):_vm._e(),_c('MealDetailTable',{attrs:{"read-only":_vm.readOnly,"subtitle":"","meal-id":_vm.meal && _vm.meal.id,"component":_vm.component,"ingredients":_vm.ingredients,"dense":_vm.dense,"show-prep-controls":_vm.showPrepControls,"show-prep-status":true,"save-ingredient-assignment":_vm.saveIngredientAssignment,"on-update-ingredient":_vm.onUpdateIngredient}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }