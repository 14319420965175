<template>
  <v-card
    outlined
    class="mb-4"
  >
    <v-card-title class="pt-1 ">
      <!-- only show links to meals with dates, won't work otherwise -->
      <span v-if="meal">
        <v-chip v-if="meal.diet" text-color="white" small :color="getDietColor(meal.diet)">
              {{ getDietName(meal.diet) }}
        </v-chip>
          {{ meal.name }}
            <router-link
              :to="{ name: 'MealDesign', params: { date: formatDate(meal.date), id: meal.id }}"
            >M{{ meal.id }}</router-link>
        <template v-if="meal.is_selected">
          <v-icon color="yellow">mdi-star</v-icon>
        </template>
         {{ formatDate(meal.date) }}
        <router-link
          :to="{ name: 'MealVersions', params: { sku: meal.sku }}"
        >
          {{ meal.sku }}
        </router-link>&nbsp;
        </span>
      <span v-if="!meal">
        {{ component.name }} ingredients
      </span>
      <v-spacer/>
    </v-card-title>
    <v-card-text class="pt-0">
      <v-row class="pa-2" v-if="!readOnly || assigned">
        <v-col cols="3">
          <v-select
            :disabled="readOnly"
            label="Assigned Team"
            chips
            :items="defaultTeams"
            v-model="assigned"
            clearable
            @change="saveComponentAssignment($event)"
          />
        </v-col>
      </v-row>
      <MealDetailTable
        :read-only="readOnly"
        subtitle=""
        :meal-id="meal && meal.id"
        :component="component"
        :ingredients="ingredients"
        :dense="dense"
        :show-prep-controls="showPrepControls"
        :show-prep-status="true"
        :save-ingredient-assignment="saveIngredientAssignment"
        :on-update-ingredient="onUpdateIngredient"
      />
    </v-card-text>
  </v-card>
</template>
<script>
import MealDetailTable from '@/components/MealDetailTable'
import moment from "moment";
import {mapGetters, mapState} from "vuex";

export default {
  name: 'ComponentIngredients',
  components: {MealDetailTable},
  props: {
    component: {},
    dense: {},
    meal: {type: Object, default: null, required: false},
    ingredients: {type: Array, required: true},
    readOnly: {type: Boolean, default: false, required: false},
    showPrepControls: {type: Boolean, default: false, required: false},
    showPrepStatus: {type: Boolean, default: false, required: false},
    saveIngredientAssignment: {type: Function, default: null, required: false},
    saveComponentAssignment: {type: Function, default: null, required: false},
    onUpdateIngredient: {type: Function, default: null, required: false},
  },
  mounted() {
    const {component, meal} = this;
    if (!meal) {
      if (component.component_steps && component.component_steps.length > 0) {
        const step = component.component_steps[0];
        this.assigned = step.team;
      }
    }
  },
  methods: {
    isMonosized(id) {
      return this.components[id] && this.components[id].monosized;
    },
    formatDate(date) {
      let text = '';
      if (date !== null) {
        // text = moment(date).format('Do MMM YYYY');
        text = moment(date).format(moment.HTML5_FMT.DATE);
      }
      return text;
    },
  },
  data() {
    return {
      assigned: null
    }
  },
  computed: {
    ...mapState(['defaultTeams']),
    ...mapGetters(['getDietName', 'getDietColor'])
  }
}
</script>