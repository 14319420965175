<template>
  <v-container fluid class="ma-0 pa-0 fill-height ">
    <TaskPanel
      :v-show="false"
      v-if="tasks"
      team="plating"
      :tasks-in="tasks"
      hidden
      :task-confirm-start="taskConfirmStart"
      v-on:close-start-confirm="taskConfirmStart=null"
      :task-confirm-stop="taskConfirmStop"
      v-on:close-stop-confirm="taskConfirmStop=null"
    />
    <v-dialog v-model="showMealDialog" max-width="800">
      <v-card v-if="selectedMeal">
        <v-card-title>{{ selectedMeal.name }}
          {{ getMealCount(selectedMeal.id) }}CT +
          {{ getAllergyMealCount(selectedMeal.id) }}CT
          ({{ getMealCount(selectedMeal.id) + getAllergyMealCount(selectedMeal.id) }}CT)
          <v-spacer/>
          <span class="text-capitalize">{{ mealNameShort(selectedMeal) }}</span>
        </v-card-title>
        <template v-if="!enterShort">
          <v-alert type="warning" v-if="mealNameShort(selectedMeal).indexOf('LC')===0">
            Note: meal counts shown here include both low carb and diabetes meals!
          </v-alert>
          <v-alert v-if="!platingTaskMealMap[selectedMeal.id]" type="warning">
            Could not find task for meal
          </v-alert>
          <template v-if="platingTaskMealMap[selectedMeal.id]">
            <v-card-subtitle>
              {{ platingTaskMealMap[selectedMeal.id].description }}
            </v-card-subtitle>
            <v-card-text>
              <v-alert v-if="isDisconnected" type="warning">
                Disconnected from Server. Tasks will not update!
              </v-alert>

              <div class="text-center">
                <h2 v-if="getSafeTimeLeft(selectedMeal)===0" style="color: red">
                  <v-icon color="red">mdi-skull-crossbones</v-icon>
                  Over 40 minutes by: {{ formatDuration(getExceededSafeTime(selectedMeal)) }}
                  <v-icon color="red">mdi-skull-crossbones</v-icon>
                </h2>
                <h2 v-if="getSafeTimeLeft(selectedMeal)">
                  <template v-if="isColdWarning(selectedMeal)">
                    <v-icon color="orange">mdi-skull-crossbones</v-icon>
                  </template>
                  <span style="color: green">SAFE TIME LEFT: {{
                      formatDuration(getSafeTimeLeft(selectedMeal))
                    }}</span>
                </h2>
              </div>
              <v-text-field
                label="Rack"
                v-model="platingTaskMealMap[selectedMeal.id].source.rack"
                @blur="saveRack(platingTaskMealMap[selectedMeal.id], allergyPlatingTaskMealMap[selectedMeal.id])"
              />
            </v-card-text>
          </template>
          <v-card-text>
            <v-row dense>
              <v-col v-if="platingTaskMealMap[selectedMeal.id]">
                <v-card-title>
                  <v-spacer/>
                  Main Plating ({{ getMealCount(selectedMeal.id) }}CT)
                  <v-spacer/>
                  <template v-if="isMealPlated(selectedMeal.id)">
                    <v-chip outlined small color="green">M
                      <v-icon fab>mdi-check</v-icon>
                    </v-chip>
                  </template>
                  <template v-if="isStarted(selectedMeal.id)">
                    <v-chip outlined small color="orange">M
                      <v-icon fab>mdi-play</v-icon>
                    </v-chip>
                  </template>
                </v-card-title>
                <v-card-actions>
                  <v-spacer/>
                  <v-btn v-if="!isStarted(selectedMeal.id)"
                         @click="showMealDialog=false;  taskConfirmStart=platingTaskMealMap[selectedMeal.id]">Start
                  </v-btn>
                  <template v-if="isStarted(selectedMeal.id)">
                    <v-btn
                      @click="showMealDialog=false;  taskConfirmStop=platingTaskMealMap[selectedMeal.id]">Stop
                    </v-btn>
                  </template>

                  <v-spacer/>
                </v-card-actions>
                <v-card-text>
                  <p class="text-center" style="font-weight: bold"
                     v-if="getElapsedTime(platingTaskMealMap[selectedMeal.id])">
                    Actual Time {{ formatDuration(getElapsedTime(platingTaskMealMap[selectedMeal.id])) }}
                  </p>
                  <p class="text-center">Estimate {{
                      formatDuration(getPlatingTimeEstimate(getMealCount(selectedMeal.id)))
                    }}
                    (1 person)
                  </p>
                  <v-card-text>
                    <p v-if="platingTaskMealMap[selectedMeal.id].note">
                      Note: {{ platingTaskMealMap[selectedMeal.id].note }}
                    </p>
                    <ul>
                      <li v-for="(e,index) of platingTaskMealMap[selectedMeal.id].execution" v-bind:key="index">
                        {{ formatTime(e.start) }}-{{ formatTime(e.stop) }}
                        ({{ formatDuration(asMilli(e.stop || now) - asMilli(e.start)) }})
                        - {{ e.team_members.map(t => t.name).join(', ') }}
                        <v-chip color="green" outlined v-if="!e.stop">Currently Working</v-chip>
                      </li>
                    </ul>
                  </v-card-text>
                </v-card-text>

              </v-col>
              <v-col v-if="allergyPlatingTaskMealMap[selectedMeal.id]">
                <v-card-title>
                  <v-spacer/>
                  Allergy Plating ({{ getAllergyMealCount(selectedMeal.id) }}CT)
                  <v-spacer/>
                  <template v-if="isAllergyStarted(selectedMeal.id)">
                    <v-chip outlined small color="orange">A
                      <v-icon fab>mdi-play</v-icon>
                    </v-chip>
                  </template>

                  <template v-if="isAllergyComplete(selectedMeal.id)">
                    <v-chip outlined small color="green">A
                      <v-icon fab>mdi-check</v-icon>
                    </v-chip>
                  </template>
                </v-card-title>
                <v-card-actions>
                  <v-spacer/>
                  <v-btn v-if="!isAllergyStarted(selectedMeal.id)"
                         @click="showMealDialog =false; taskConfirmStart = allergyPlatingTaskMealMap[selectedMeal.id]">
                    Start
                  </v-btn>
                  <v-btn v-if="isAllergyStarted(selectedMeal.id)"
                         @click="showMealDialog =false; taskConfirmStop = allergyPlatingTaskMealMap[selectedMeal.id]">
                    Stop
                  </v-btn>
                  <v-spacer/>
                </v-card-actions>
                <v-card-text>
                  <p class="text-center" style="font-weight: bold"
                     v-if="getElapsedTime(allergyPlatingTaskMealMap[selectedMeal.id])">
                    Actual Time {{ formatDuration(getElapsedTime(allergyPlatingTaskMealMap[selectedMeal.id])) }}
                  </p>
                  <p class="text-center">Estimate
                    {{ formatDuration(getPlatingTimeEstimate(getAllergyMealCount(selectedMeal.id))) }}
                    (1 person)
                  </p>
                  <p v-if="allergyPlatingTaskMealMap[selectedMeal.id]">
                    Note: {{ allergyPlatingTaskMealMap[selectedMeal.id].note }}
                  </p>
                  <ul>
                    <li v-for="(e,index) of allergyPlatingTaskMealMap[selectedMeal.id].execution" v-bind:key="index">
                      {{ formatTime(e.start) }}-{{ formatTime(e.stop) }}
                      ({{ formatDuration(asMilli(e.stop || now) - asMilli(e.start)) }})
                      - {{ e.team_members.map(t => t.name).join(', ') }}
                      <v-chip color="green" outlined v-if="!e.stop">Currently Working</v-chip>
                    </li>
                  </ul>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card-text>
        </template>
        <v-card-text v-if="enterShort && !shortComponent">
          Which component is short?
          <v-row>
            <v-col v-if="platingTaskMealMap[selectedMeal.id]">
              <h2>Main Line</h2>
              <br/>
              <v-row v-for="t of getMainLineAuditTasks(selectedMeal.id)" v-bind:key="t.id">
                <v-col>
                  <v-btn @click="shortComponent=t" color="primary">
                    <span style="max-width: 200px; overflow: clip">{{ t.source.batch }}</span>
                  </v-btn>
                  {{ t.description }}
                </v-col>
              </v-row>
              <!--              {{ getMainLineAuditTasks(selectedMeal.id) }}-->
            </v-col>
            <v-col v-if="allergyPlatingTaskMealMap[selectedMeal.id]">
              <h2>Allergy Line</h2>
              <br/>
              <v-row v-for="t of getAllergyLineAuditTasks(selectedMeal.id)" v-bind:key="t.id">
                <v-col>
                  <v-btn @click="shortComponent=t"
                         :color="t.source.line==='S'?'warning':'default'">
                    <span style="max-width: 200px; overflow: clip">{{ t.source.batch }}</span>
                  </v-btn>
                  <span v-if="t.source.line==='S'"> SUB for </span> {{ t.description }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-if="enterShort && shortComponent">
          <h4>How much
            <span v-if="shortComponent.source.line ==='S'">SUB for </span>
            {{ shortComponent.description }} do you need?
          </h4>
          <v-row>
            <v-col v-for="size of ['small','medium','large'].filter(size => shortComponent.source.count[size]>0)"
                   v-bind:key="size">
              <v-text-field
                :label="`missing ${size} portions of ${shortComponent.source.count[size]} (${formatWeightWithUnits(shortComponent.source.portionSizes[size])})`"
                :value="shortComponent.source.count[size]"
                clearable
                v-model="short[size]"
                type="number"
                min="0"
                :max="shortComponent.source.count[size]"
                :rules="[v => v<=shortComponent.source.count[size] || `Too many. Only ${shortComponent.source.count[size]} ${size} portions ordered`]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center"><h3>Total Amount Needed {{ formatWeightWithUnits(totalShortAmount) }}</h3>
            </v-col>
          </v-row>
        </v-card-text>
        <v-dialog v-model="showShorts" max-width="600">
          <v-card>
            <v-card-title>Short Requests to Kitchen</v-card-title>
            <v-card-text>
              <v-row v-for="t of getMealShorts(selectedMeal.id)" v-bind:key="t.id" dense>
                <v-col cols="1">
                  {{
                    formatWeightWithUnits(t.source.totalAmountMain || t.source.totalAmountAllergy || t.source.totalAmountSub)
                  }}
                </v-col>
                <v-col>
                  <strong>{{ t.source.batch }}</strong>
                  {{ t.source.line === 'S' ? 'SUB for ' : t.source.line }}
                  {{ t.source.component.name }}
                </v-col>
                <v-col cols="4">
                  {{ (t.complete && 'Completed') || (t.isStarted && 'Started') || 'Requested' }}
                  <span v-if="getTeamMembers(t)">By {{ getTeamMembers(t).join(', ') }}</span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-card-actions>
          <v-btn v-if="enterShort" outlined @click="shortComponent=null;enterShort=false">Cancel</v-btn>
          <v-spacer/>
          <v-btn v-if="!enterShort" outlined @click="shortComponent=null;enterShort=!enterShort">Enter Short</v-btn>
          <v-btn class="ml-4" outlined v-if="isMealShort(selectedMeal.id)" @click="showShorts=true">Show Shorts</v-btn>
          <v-spacer/>
          <v-btn :disabled="!shortComponent" v-if="enterShort" outlined @click="submitShort">
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
    <v-container v-if="loading" fluid class="justify-center text-center fill-height">
      <v-progress-circular indeterminate/>
    </v-container>
    <!--    <v-container v-if="!hasDates" fluid class="justify-center text-center fill-height flex-column">-->
    <!--      <v-alert type="info" @click="showDatePicker=true">-->
    <!--        Please select production dates-->
    <!--      </v-alert>-->
    <!--      <ProductionSelector-->
    <!--        :dates.sync="dates"-->
    <!--        :production.sync="production"-->
    <!--      />-->
    <!--    </v-container>-->
    <v-container
      v-if="!showTaskPanel && !loading"
      fluid
      class="align-self-start"
      style="margin-bottom: 10rem"
    >
      <v-alert v-if="isDisconnected" type="warning">
        Disconnected from Server. Tasks will not update!
      </v-alert>

      <v-toolbar flat dense style="font-size: smaller" :class="`${production}-production`">
        <v-chip :style="chipWidth" outlined small color="green">K
          <v-icon fab color="green">mdi-check</v-icon>
        </v-chip>
        Components Ready
        <v-spacer/>
        <span class="red--text font-weight-bold">A1 </span> Rack
        <v-spacer/>
        <div>
          <span class="font-weight-bold">C#4</span> Component Count
          <br/>
          <span class="font-weight-bold">G#2</span> Garnish Count
        </div>
        <v-spacer/>
        <span class="red--text font-weight-bold"><v-icon color="red">mdi-skull-crossbones</v-icon></span>
        Foodsafe Warning
        <v-spacer/>
        <v-chip :style="chipWidth" outlined small color="green">A
          <v-icon fab color="green">mdi-check</v-icon>
        </v-chip>
        Allergy Plated
        <v-spacer/>
        <v-chip :style="chipWidth" outlined small color="green">M
          <v-icon fab color="green">mdi-check</v-icon>
        </v-chip>
        Main Plated
        <v-spacer/>
        <ProductionSelector
          :dates.sync="dates"
          :production.sync="production"
          v-on:update="fetchData"
        />
        <v-btn icon @click="SET_HIDE_TOP_NAV({hideTopNav:!hideTopNav})">
          <v-icon>mdi-fullscreen</v-icon>
        </v-btn>
      </v-toolbar>
      <v-toolbar flat dense style="font-size: smaller">
        <v-spacer/>
        <v-btn-toggle v-model="streamSelect" dense rounded>
          <v-btn :value="null" small>All</v-btn>
          <v-btn small :value="stream" v-for="stream of streams" v-bind:key="stream">{{ stream }}</v-btn>
        </v-btn-toggle>
        <v-spacer/>
        <v-checkbox hide-details dense v-model="includeCCMeals" label="Include CC"/>
      </v-toolbar>
      <v-row dense :class="`${production}-production`">
        <v-col v-for="date of datesWithOrders" v-bind:key="`${date}-stream`">
          <v-list dense>
            <v-list-item dense
                         v-for="(meal, i) in mealsWithOrders[date]"
                         :key="i"
            >
              <v-list-item-content style="padding: 0" class="text-capitalize" @click="showMeal(meal)">
                <div :style="isMealPlated(meal.id) && isAllergyComplete(meal.id) ? 'text-decoration: line-through':''">
                  <template v-if="isColdWarning(meal)">
                    <v-icon color="orange">mdi-skull-crossbones</v-icon>
                  </template>
                  <template v-if="isColdDanger(meal)">
                    <v-icon color="red">mdi-skull-crossbones</v-icon>
                  </template>
                  <template v-if="isComplete(meal.id)">
                    <v-chip :style="chipWidth" outlined small color="green">
                      K
                      <v-icon fab color="green">mdi-check</v-icon>
                    </v-chip>
                  </template>
                  <span v-if="getMealRack(meal.id)" class="red--text font-weight-bold">{{
                      getMealRack(meal.id)
                    }} </span>
                  <b v-if="meal.production_priority">P{{ meal.production_priority }}</b>
                  {{ mealNameShort(meal) }}
                  {{ getMealCount(meal.id) }}<span
                  v-if="getAllergyMealCount(meal.id)>0">+{{ getAllergyMealCount(meal.id) }}</span>CT
                  C#{{ getComponentCount(meal.id) }}
                  <span v-if="getGarnishCount(meal.id)">G#{{ getGarnishCount(meal.id) }}</span>
                  <template v-if="isAllergyStarted(meal.id)">
                    <v-chip :style="chipWidth" outlined small color="orange">A
                      <v-icon fab>mdi-play</v-icon>
                    </v-chip>
                  </template>
                  <template v-if="isAllergyComplete(meal.id)">
                    <v-chip :style="chipWidth" outlined small color="green">A
                      <v-icon fab>mdi-check</v-icon>
                    </v-chip>
                  </template>
                  <template v-if="isStarted(meal.id)">
                    <v-chip :style="chipWidth" outlined small color="orange">M
                      <v-icon fab>mdi-play</v-icon>
                    </v-chip>
                  </template>
                  <template v-if="isMealPlated(meal.id)">
                    <v-chip :style="chipWidth" outlined small color="green">M
                      <v-icon fab>mdi-check</v-icon>
                    </v-chip>
                  </template>
                  <template v-if="isMealShort(meal.id)">
                    <v-icon v-if="isMealShortComplete(meal.id)" color="green" fab>
                      mdi-comment-check-outline
                    </v-icon>
                    <v-icon v-else color="pink" fab>
                      mdi-comment-question-outline
                    </v-icon>
                  </template>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
    <v-footer class="" fixed>
      <v-container fluid class="mb-0 pa-0">
        <v-row dense v-if="overTimeTasks.length>0">
          <v-col class="text-center">
            <v-chip
              v-for="(ot,index) of overTimeTasks" v-bind:key="index"
              :color="isLateClass(now)?'red':'orange'"
              @click="showMeal(ot.meal)"
              class="mr-1 mb-1"
              outlined
              :small="overTimeTasks.length>4 && overTimeTasks.length<=6"
              :x-small="overTimeTasks.length>6"
            >
              {{ ot.team_members.join(', ') }} {{ formatMinutes(ot.lateBy) }}
            </v-chip>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col class="text-left">
            {{ formatTime(now) }}
          </v-col>
          <v-col>

            Total Time Est. {{ formatDuration(totalPlatingTime) }} (1 person)
          </v-col>
          <v-col class="text-right">
            <template v-if="streamSelect">
              <v-chip class="mr-2" color="red" outlined>M {{ countSelectedMainCompleted }}/{{
                  countSelectedMain
                }}
              </v-chip>
              <v-chip class="mr-2" color="red" outlined>A {{ countSelectedAllergyCompleted }}/{{
                  countSelectedAllergy
                }}
              </v-chip>
            </template>
            <v-chip class="mr-2" color="" outlined>M {{ countMainCompleted }}/{{ countMain }}</v-chip>
            <v-chip color="" outlined>A {{ countAllergyCompleted }}/{{ countAllergy }}</v-chip>
          </v-col>

          <!--          <v-col class="text-right">-->
          <!--            Meal Count {{ totalMealCount }}-->
          <!--          </v-col>-->
        </v-row>
      </v-container>
    </v-footer>


  </v-container>
</template>


<script>
import urlState from "@/router/urlState";
import {mapActions, mapGetters, mapMutations} from "vuex";
import moment from "moment/moment";
import {
  dateFormatted, ensureValuesNumbers,
  formatWeightWithUnits,
  getDatesInRange,
  getPlatingTimeEstimate,
  getProductionDays,
  sortBySize
} from "@/store/utils";
import api from "@/api";
import TaskPanel from "@/components/tasks/TaskPanel.vue";
import ProductionSelector from "@/components/ProductionSelector.vue";

export default {
  name: "PlatingTasks",
  components: {ProductionSelector, TaskPanel},
  mixins: [urlState],
  data() {
    return {
      team: 'plating',
      dates: [],
      search: null,
      teamsSelected: null,
      showDatePicker: null,
      loading: null,
      datePickerDate: null,
      showGarnish: null,
      onlyGarnish: null,
      teams: ['allergy', 'main'],
      plateTaskFilter: [],
      meals: {},
      componentTaskMap: {},
      auditTaskMap: {},
      showTaskPanel: null,
      platingTaskMap: {},
      taskMap: null,  // this just points to platingTaskMap
      platingTaskMealMap: {},
      allergyPlatingTaskMealMap: {},
      complete: {},
      selectedMeal: null,
      showMealDialog: null,
      team_members: null,
      hideTopNav: true,
      taskConfirmStart: null,
      taskConfirmStop: null,
      now: null,
      timerIntervals: {},
      timeElapsed: {},
      timeStarted: {},
      timeStopped: {},
      chipWidth: 'padding:5px; width: 40px',
      isDisconnected: false,
      enterShort: null,
      shortComponent: null,
      short: {},
      showShorts: null,
      streamSelect: null,
      includeCCMeals: false,
      production: null
    }
  },
  watch: {
    isDisconnected(val, old) {
      if (!val && old) {
        console.log('reconnected, refetch tasks');
        this.fetchData();
      } else {
        console.log('isDisconncted skip, ', {val, old});
      }
    },
    shortComponent(v) {
      console.log('short component', v);
      if (v && !v.source.portionSizes) {
        alert('no portion sizes found for ' + v.description);
      }
      this.short = v
        ? Object.fromEntries(Object.keys(v.source.count).map(k => ([k, 0])))
        : {};
    }
  },
  mounted() {
    this.taskMap = this.platingTaskMap;
    this.SET_HIDE_TOP_NAV({hideTopNav: this.hideTopNav});
    this.syncToUrl({
      param: 'plateTaskFilter', urlParam: 'plating_filter', initFromRoute: true,
      parseCallback: (v) => Array.isArray(v) ? v : [v]
    });
    this.syncToUrl({
      param: 'taskFilter', urlParam: 'status', initFromRoute: true,
      // parseCallback: (v) => v === 'true'
    });
    this.syncToUrl({
      param: 'dates', urlParam: 'dates', initFromRoute: true,
      parseCallback: (v) => Array.isArray(v) ? v : [v]
    });
    this.syncToUrl({
      param: 'production', urlParam: 'production', initFromRoute: true,
      // parseCallback: (v) => Array.isArray(v) ? v : [v]
    });
    this.syncToUrl({
      param: 'streamSelect', urlParam: 'streams', initFromRoute: true,
      // parseCallback: (v) => Array.isArray(v) ? v : [v]
    });
    this.syncToUrl({
      param: 'includeCCMeals', urlParam: 'cc', initFromRoute: true,
      parseCallback: (v) => v === 'true'
    });
    this.syncToUrl({
      param: 'search', urlParam: 'search', initFromRoute: true,
      // parseCallback: (v) => v === 'true'
    });
    if (this.platingMode === 'Allergy') {
      if (this.plateTaskFilter.length === 0) {
        this.plateTaskFilter.push('racked');
      }
    }
    this.$socket.on('task:started', (task) => {
      console.log('task started', task);
      this.updateComponentTask(task);
      this.updatePlatingTask(task);
      this.updateAuditTask(task);
    });
    this.$socket.on('task:stopped', (task) => {
      console.log('task stopped', task);
      this.updateComponentTask(task);
      this.updatePlatingTask(task);
      this.updateAuditTask(task);
      this.stopTimer(task);
    });
    this.$socket.on('task:updated', (task) => {
      console.log('task updated', task);
      this.updateComponentTask(task);
      this.updatePlatingTask(task);
      this.updateAuditTask(task);
    });
    this.$socket.on('task:created', (task) => console.log('task created', task));
    this.$socket.emit('members:fetch',
      {team: this.team},
      (result) => {
        const {error, teamMembers} = result;
        if (error) {
          console.warn(`members:fetch failed: ${error}`);
        } else {
          console.log('plating members:fetch result', teamMembers);
          this.team_members = teamMembers;
        }
      }
    );
    this.$socket.on('connect', () => this.isDisconnected = false);
    this.$socket.on('disconnect', () => this.isDisconnected = true);
    this.$nextTick(() => this.fetchData());
    const intervalId = setInterval(() => {
      this.now = moment();
    }, 1000);
    this.$set(this.timerIntervals, 'main-clock', intervalId);
  },
  destroyed() {
    // for all events
    console.log('removing listeners/intervals');
    this.$socket.removeAllListeners();
    Object.values(this.timerIntervals)
      .forEach(timer => clearInterval(timer));
  },
  methods: {
    isLateClass(now) {
      if (now.second() % 2 === 1) {
        return true;
      } else {
        return false;
      }
    },
    formatMinutes(ms) {
      if (ms && !isNaN(ms)) {
        if (ms > (1000 * 60 * 60 * 24)) {
          return (moment.duration(ms).asHours()).toFixed(1) + ' hours';
        } else {
          const m = moment.utc(ms || 0);
          if (m.hours() > 0) {
            return m.format('H[h] m[m]');
          } else if (m.minutes() > 0) {
            return m.format('m[m]');
          } else {
            return m.format('s[s]');
          }
        }
      }
    },
    formatDuration(ms) {
      if (ms && !isNaN(ms)) {
        if (ms > (1000 * 60 * 60 * 24)) {
          return (moment.duration(ms).asHours()).toFixed(1) + ' hours';
        } else {
          const m = moment.utc(ms || 0);
          if (m.hours() > 0) {
            return m.format('HH[h] mm[m] ss[s]');
          } else if (m.minutes() > 0) {
            return m.format('mm[m] ss[s]');
          } else {
            return m.format('ss[s]');
          }
        }
      }
    },
    ...mapActions(['fetchDiets', 'fetchOrdersByDate', 'fetchMeals', 'fetchMealsById']),
    ...mapMutations(['SET_HIDE_TOP_NAV']),
    getDatesInRange,
    trunc(s, len) {
      return s.slice(0, len);
    },
    dateFormatted,
    closeDatePicker() {
      this.showDatePicker = false;
      this.dates = getProductionDays(this.datePickerDate);
      console.log('setting dates', this.dates);
      this.clearData();
      return this.fetchData();
    },
    clearData() {
      this.meals = {};
    },
    async fetchData() {
      this.error = this.post = null

      if (!(this.dates && this.dates.length > 0 && this.production)) {
        return;
      }
      this.loading = true;

      this.meals = {};
      this.platingTaskMap = {};
      this.componentTaskMap = {};
      this.auditTaskMap = {};
      this.platingTaskMealMap = {};
      this.allergyPlatingTaskMealMap = {};
      this.dates.map(date => this.$set(this.meals, date, []));
      await Promise
        .all([
          this.fetchDiets(),
          // ...this.dates.map(date => this
          // .fetchOrdersByDate({date})
          // .then(orders => this.$set(this.orders, date, orders))),
          ...this.dates.map(date => this.fetchMeals(date).then(meals => this.$set(this.meals, date, meals))),
          this.fetchComponentTasks(),
          this.fetchAuditorTasks(),
          this.fetchPlatingTasks(),
        ])
        .finally(() => this.loading = false);

      // get CC meals
      const mealIds = [];
      for (const task of this.tasks) {
        if (task.source.dietName === 'Chefs Choice') {
          mealIds.push(task.source.mealId)
        }
      }
      const ccMeals = await this.fetchMealsById(mealIds);
      this.meals[this.dates[0]].push(...ccMeals)
    },
    fetchPlatingTasks() {
      const dates = this.dates;
      if (dates && dates.length > 0)
        return new Promise(resolve => {
          this.$socket.emit('tasks:fetch',
            {dates, team: this.team, productions: [this.production]},
            (result) => {
              const {error, tasks} = result;
              if (error) {
                console.warn(`fetch tasks ${dates} failed: ${error}`);
              } else {
                console.log(`tasks:fetch team=${this.team}- ${dates} result`, result);
                tasks.forEach(task => {
                  const {id} = task;
                  this.$set(this.platingTaskMap, id, {...(this.platingTaskMap[id] || {}), ...task});
                  this.updatePlatingTask(task);
                });
              }
              resolve();
            }
          );
        })
    },
    async fetchAuditorTasks() {
      const dates = this.dates;
      this.incomplete = {};
      this.auditTaskMap = {};
      if (dates && dates.length > 0)
        return new Promise(resolve => {
          const team = 'audit';
          this.$socket.emit('tasks:fetch',
            {dates, team, productions: [this.production]},
            (result) => {
              const {error, tasks} = result;
              if (error) {
                alert(`fetch tasks ${dates} failed: ${error}`);
              } else {
                console.log(`tasks:fetch team=${team} - ${dates} result`, result);
                tasks.forEach(task => {
                  const {id} = task;
                  this.$set(this.auditTaskMap, id, {...(this.auditTaskMap[id] || {}), ...task});
                  this.updateAuditTask(task);
                });
              }
              resolve();
            }
          );
        });
    },
    async fetchComponentTasks() {
      const dates = this.dates;
      this.incomplete = {};
      this.componentTaskMap = {};
      if (dates && dates.length > 0)
        return new Promise(resolve => {
          const team = 'components';
          this.$socket.emit('tasks:fetch',
            {dates, team, productions: [this.production]},
            (result) => {
              const {error, tasks} = result;
              if (error) {
                alert(`fetch tasks ${dates} failed: ${error}`);
              } else {
                console.log(`tasks:fetch team=${team} - ${dates} result`, result);
                tasks.forEach(task => {
                  const {id} = task;
                  this.$set(this.componentTaskMap, id, {...(this.componentTaskMap[id] || {}), ...task});
                  this.updateComponentTask(task);
                });
              }
              resolve();
            }
          );
        });
    },
    sortMeals(meals) {
      meals.sort((m1, m2) => {
        const meal1Plated = this.isMealPlated(m1.id) && this.isAllergyComplete(m1.id);
        const meal2Plated = this.isMealPlated(m2.id) && this.isAllergyComplete(m2.id);
        if (meal1Plated && !meal2Plated) {
          return 1;
        }
        if (!meal1Plated && meal2Plated) {
          return -1;
        }

        const m1Racked = this.getMealRack(m1.id);
        const m2Racked = this.getMealRack(m2.id);
        if (m1Racked && !m2Racked) {
          return -1;
        }
        if (m2Racked && !m1Racked) {
          return 1;
        }


        const diff = this.getMealCount(m2.id) - this.getMealCount(m1.id);
        if (diff !== 0) {
          return diff;
        } else {
          return m1.name.localeCompare(m2.name);
        }
      });
      return meals;
    },
    getMealRack(mealId) {
      return this.platingTaskMealMap[mealId] && this.platingTaskMealMap[mealId].source.rack;
    },
    getMealCount(mealId) {
      const count = (this.platingTaskMealMap[mealId] && this.platingTaskMealMap[mealId].source.mealCount) || 0;
      return count - this.getAllergyMealCount(mealId);
    },
    getAllergyMealCount(mealId) {
      return (this.allergyPlatingTaskMealMap[mealId] && this.allergyPlatingTaskMealMap[mealId].source.mealCount) || 0;
    },
    getComponentCount(mealId) {
      return (this.platingTaskMealMap[mealId] && this.platingTaskMealMap[mealId].source.componentNotGarnishCount) || 0;
    },
    getGarnishCount(mealId) {
      return (this.platingTaskMealMap[mealId] && this.platingTaskMealMap[mealId].source.garnishCount) || 0;
    },
    isMealPlated(mealId) {
      return this.platingTaskMealMap[mealId] && this.platingTaskMealMap[mealId].complete;
    },
    isComplete(mealId) {
      if (this.complete[mealId]) {
        return Object.values(this.complete[mealId]).every(t => t);
      } else {
        return false;
      }
    },
    updatePlatingTask(task) {
      const {id} = task;
      if (!this.platingTaskMap[id]) {
        // console.log('skipping, not in map', task);
        return;
      }
      task = this.platingTaskMap[id] = {...this.platingTaskMap[id], ...task};

      if (task.source && task.source.mealId) {
        // some tasks may not have a meal
        const mealId = task.source.mealId;
        if (task.source.allergy) {
          this.$set(this.allergyPlatingTaskMealMap, mealId, task);
        } else {
          this.$set(this.platingTaskMealMap, mealId, task);
        }
      }
      const intervalId = setInterval(() => {
        this.updateElapsed(id);
      }, 1000);
      this.$set(this.timerIntervals, id, intervalId);
      this.$set(this.timeElapsed, task.id, task.elapsed);
      const executing = task.execution.find(e => !e.stop)
      if (executing) {
        // console.log('starting timer for task', {task, executing});
        this.startedTimer({id: task.id, start: executing.start});
      }
      // console.log(`updating ${mealId} ac=${task.source.allergyComplete} r=${task.source.rack}`)
    },
    startedTimer(task) {
      let {id, start} = task;
      // console.log('start timer', task);
      if (!this.taskMap[id]) {
        console.log('ignoring task, not in task map', task);
        return;
      }
      this.taskMap[id] = {...this.taskMap[id], ...task};
      if (this.isStarted(id)) {
        // console.log('already started, nothing to do', {id});
      } else {
        start = Date.parse(start);
        if (!start) {
          alert('no start time!');
          return;
        }
        this.timeStarted[id] = start;
        this.timeStopped[id] = false;
        if (this.timerIntervals[id]) {
          clearInterval(this.timerIntervals[id]);
          this.timerIntervals[id] = false;
        }
        const intervalId = setInterval(() => {
          this.updateElapsed(id);
        }, 1000);
        this.$set(this.timerIntervals, id, intervalId);
      }
    },
    stopTimer(task) {
      const {id} = task;
      console.log('stopping', task);
      if (!this.taskMap[id]) {
        console.log('ignoring task, not in task map', task);
        return;
      }
      this.taskMap[id] = {...this.taskMap[id], ...task};
      if (this.timeStopped[id]) {
        console.log('already stopped, nothing to do', {id});
      } else {
        this.timeStopped[id] = Date.now();
        // this.teamAssignments[id] = [];
        if (this.timerIntervals[id]) {
          clearInterval(this.timerIntervals[id]);
          this.timerIntervals[id] = false;
        }
        this.updateElapsed(id);
      }
    },
    updateElapsed(id) {
      let elapsed = this.timeElapsed[id];
      let started = this.timeStarted[id];
      let stopped = this.timeStopped[id];
      if (started && !stopped) {
        const now = Date.now();
        elapsed += now - started;
        // a bit hacky, but since elapsed has been updated, ensure started is updated too
        this.timeStarted[id] = now;
      }
      // console.log('update elapsed', {id, elapsed, started, stopped});
      this.$set(this.timeElapsed, id, elapsed);
    },
    updateComponentTask(task) {
      const {id} = task;
      if (!this.componentTaskMap[id]) {
        // console.log('skipping, not in map', task);
        return;
      }
      task = this.componentTaskMap[id] = {...this.componentTaskMap[id], ...task};

      // REMAINDER NO LONGER USED - as complete is based on audit tasks
      // console.log('updating task', task);
      // const source = task.source;
      // if (!source) {
      //   // console.log('no source, skipping', task);
      //   return;
      // }
      // const componentId = source.component && source.component.id;
      // const componentName = source.component && source.component.name;
      // if (componentName.match(/garnish/i)) {
      //   // console.log('skipping garnish', componentName);
      //   return;
      // }
      // const complete = task.complete;
      // if (source && source.meals) {
      //   for (const meal of source.meals) {
      //     const {meal_id} = meal;
      //     this.$set(this.complete, meal_id, this.complete[meal_id] || {});
      //     this.$set(this.complete[meal_id], componentId, complete);
      //   }
      // }
    },
    updateAuditTask(task) {
      const {id} = task;
      if (!this.auditTaskMap[id]) {
        return;
      }
      // console.log('updating task', task);
      task = this.auditTaskMap[id] = {...this.auditTaskMap[id], ...task};
      const {source, description} = task;
      if (/garnish/i.test(description)) {
        // console.log('skipping garnish',description);
        return;
      }

      if (!source) {
        // console.log('no source, skipping', task);
        return;
      }
      if (source.line === 'M') {
        const totalAmountMeasured = source.measurements.reduce((sum, m) => sum + m.net_weight, 0);
        let complete;

        if (source.skipped) {
          complete = true;
        } else if (totalAmountMeasured >= source.totalAmount) {
          complete = true;
        } else {
          //check counts
          const {count} = source;
          const measurement = source.measurements[source.measurements.length - 1];
          complete = count && measurement && measurement.counts && Object.keys(count).every((size) => count[size] === measurement.counts[size]);
          //
          // if (source.mealId == '30592') {
          //   console.log('adding complete status', task.title, description, complete, count, measurement, source);
          // }
        }

        this.$set(this.complete, source.mealId, this.complete[source.mealId] || {});
        // this.$set(this.complete[source.mealId], `${(source.componentId)}-${source.line}`, complete);
        this.$set(this.complete[source.mealId], source.componentId, complete);
      }
    },
    mealNameShort(meal) {
      if (meal.diet === 23) {
        return meal.name;
      }

      const dietName = this.getDietName(meal.diet);

      function getInitials(str) {
        return str.split(' ').map(word => word[0]).join('');
      }

      let initials = getInitials(dietName).toUpperCase();
      if (initials === 'P') {
        initials = 'PB';
      }
      return (`${initials}-` +
        `${this.trunc(this.getTimeOfDay(meal.tod), 1)}-` +
        `${dateFormatted(meal.date, {formatString: 'ddd'})}`)
        .toUpperCase();

    },
    formatWeightWithUnits,
    allowedDates(val) {
      const notAllowed = {
        0: true,
        // 2: true,
        // 4: true
      };
      return !notAllowed[moment(val).day()];
    },
    saveRack(confirmTask, allergyTask) {
      console.log('save rack', confirmTask)
      api.put(`v2/meal/${confirmTask.source.mealId}`, {meal: {'plating_rack': confirmTask.source.rack}});
      // this lets other clients know to update the rack
      this.$socket.emit('task:update', confirmTask);
      // a bit janky, allergy rack should be updated too
      allergyTask.source.rack = confirmTask.source.rack;
      this.$socket.emit('task:update', allergyTask);
    },
    isStarted(mealId) {
      const task = this.platingTaskMealMap[mealId];
      return task && !!task.execution.find(e => !e.stop);
    },
    isAllergyStarted(mealId) {
      const task = this.allergyPlatingTaskMealMap[mealId];
      return task && task.execution && !!task.execution.find(e => !e.stop);
    },
    isPaused(mealId) {
      const task = this.platingTaskMealMap[mealId];
      return task.execution.length > 0 && !task.execution.find(e => !e.stop);
    },
    isAllergyComplete(mealId) {
      return !this.allergyPlatingTaskMealMap[mealId]
        ? false // note that there may not be an allergy meal task if there are no allergies
        : this.allergyPlatingTaskMealMap[mealId].complete
    },
    formatTime(t) {
      return t ? moment(t).format('HH:mm') : '';
    },
    getElapsedTime(task) {
      return this.timeElapsed[task.id];
    },
    getMealElapsedTime(meal) {
      const mealTask = this.platingTaskMealMap[meal.id];
      const allergyTask = this.allergyPlatingTaskMealMap[meal.id];
      return (mealTask ? this.getElapsedTime(mealTask) : 0) +
        (allergyTask ? this.getElapsedTime(allergyTask) : 0);
    },
    isColdWarning(meal) {
      return !this.isColdDanger(meal) && this.getMealElapsedTime(meal) > (30 * 60 * 1000);
    },
    isColdDanger(meal) {
      const mealElapsedTime = this.getMealElapsedTime(meal);
      return mealElapsedTime > (40 * 60 * 1000);
    },
    getSafeTimeLeft(meal) {
      const mealElapsedTime = this.getMealElapsedTime(meal);
      return Math.max((40 * 60 * 1000) - mealElapsedTime, 0);
    },
    getExceededSafeTime(meal) {
      const mealElapsedTime = this.getMealElapsedTime(meal);
      return Math.max(mealElapsedTime - (40 * 60 * 1000), 0);
    },
    asMilli(s) {
      return s ? moment(s).valueOf() : 0;
    },

    getCurrentTeamMembers(task) {
      const execution = task.execution[task.execution.length - 1];
      // console.log('execution',execution);
      return execution.team_members.map(m => m.name);
    },
    showMeal(meal) {
      this.selectedMeal = meal;
      this.showMealDialog = true;
    },
    getPlatingTimeEstimate,
    getMainLineAuditTasks(mealId) {
      // console.log('looking for mainline audit tasks',mealId);
      const key = (a) => `${a.source.batch} ${a.source.line}`;
      return Object.values(this.auditTaskMap)
        .filter(t => t.source.mealId === mealId)
        .filter(t => t.source.line === 'M')
        .sort((a, b) => key(a).localeCompare(key(b)));
    },
    getAllergyLineAuditTasks(mealId) {
      // console.log('looking for allergy line audit tasks',mealId);
      const key = (a) => `${a.source.batch} ${a.source.line}`;
      return Object.values(this.auditTaskMap)
        .filter(t => t.source.mealId === mealId)
        .filter(t => t.source.line !== 'M')
        .sort((a, b) => key(a).localeCompare(key(b)));
    },
    submitShort() {
      this.$socket.emit('task:create', this.shortTask, response => {
        console.log('task:create response', response)
        alert('Short request has been submitted');
        this.$set(this.componentTaskMap, response.id, response);
      });
      this.shortComponent = null;
      this.enterShort = false
    },
    isMealShort(mealId) {
      return this.shortComponentTasks.find(t => t.source.shortMealId === mealId);
    },
    isMealShortComplete(mealId) {
      return this.getMealShorts(mealId).every(t => t.complete);
    },
    getMealShorts(mealId) {
      return this.shortComponentTasks.filter(t => t.source.shortMealId === mealId);
    },
    getTeamMembers(t) {
      const [execution] = t.execution;
      if (execution) {
        return execution.team_members.map(t => t.name);
      }
    },

  },
  computed: {
    ...mapGetters(['getComponent', 'getMeal', 'getDietName', 'getTimeOfDay']),
    shortComponentTasks() {
      return Object.values(this.componentTaskMap).filter(t => t.source.isShort)
    },
    countMain() {
      return Object.values(this.platingTaskMealMap).length;
    },
    countSelectedMain() {
      return this.mealsWithOrdersFlat.filter(t => this.platingTaskMealMap[t.id]).length;
    },
    countSelectedAllergy() {
      return this.mealsWithOrdersFlat.filter(t => this.allergyPlatingTaskMealMap[t.id]).length;
    },
    countSelectedMainCompleted() {
      return this.mealsWithOrdersFlat.filter(t => this.platingTaskMealMap[t.id] && this.platingTaskMealMap[t.id].complete).length;
    },
    countSelectedAllergyCompleted() {
      return this.mealsWithOrdersFlat.filter(t => this.allergyPlatingTaskMealMap[t.id] && this.allergyPlatingTaskMealMap[t.id].complete).length;
    },
    countMainCompleted() {
      return Object.values(this.platingTaskMealMap).filter(i => i.complete).length;
    },
    countAllergy() {
      return Object.values(this.allergyPlatingTaskMealMap).length;
    },
    countAllergyCompleted() {
      return Object.values(this.allergyPlatingTaskMealMap).filter(i => i.complete).length;
    },
    mealsWithOrders() {
      const result = {};
      for (const date of this.dates) {
        const mealsOnDate = this.meals[date];
        if (mealsOnDate) {
          result[date] = this.meals[date]
            .filter(({id, diet}) => {
              if (!this.includeCCMeals) {
                if (diet === 23) {
                  return false;
                }
              }
              if (this.streamSelect) {
                const platingTask = this.platingTaskMealMap[id];
                if (platingTask && this.streamSelect !== platingTask.source.dietName) {
                  return false;
                }
              }
              return this.getMealCount(id) + this.getAllergyMealCount(id) > 0;
            })

          ;
          result[date] = this.sortMeals(result[date]);
        }
      }
      return result;
    },
    mealsWithOrdersFlat() {
      return Object.values(this.mealsWithOrders).flat();
    },
    datesFormatted() {
      const format = 'ddd MMM D';
      if (!this.dateFrom) {
        return '';
      } else if (this.dateFrom === this.dateTo) {
        return `${moment(this.dateFrom).format(format)}`;
      } else {
        return `${moment(this.dateFrom).format(format)} - ${moment(this.dateTo).format(format)}`
      }
    },
    dateFrom() {
      return [...this.dates].sort()[0];
    },
    dateTo() {
      return [...this.dates].sort().reverse()[0];
    },
    hasDates() {
      return this.dates.length > 0;
    },
    datesWithOrders() {
      return this.dates.filter(date => (this.mealsWithOrders[date] || []).length > 0);
    },
    countRacked() {
      return 0;
    },
    tasks() {
      return [
        ...Object.values(this.platingTaskMealMap),
        ...Object.values(this.allergyPlatingTaskMealMap)
      ];
    },
    mealIds() {
      return Object.values(this.meals).flatMap(meals => meals.map(m => m.id));
    },
    mealMap() {
      return Object.fromEntries(
        Object.values(this.meals).flatMap(meals => meals.map(m => [m.id, m]))
      );
    },
    totalPlatingTime() {
      let time = 0;
      if (!this.loading) {
        this.mealIds.forEach(id => {
          time += this.getPlatingTimeEstimate(this.getMealCount(id));
          time += this.getPlatingTimeEstimate(this.getAllergyMealCount(id));

        });
      }
      return time;
    },
    totalMealCount() {
      //console.log('this.mealIds', this.mealIds);
      let count = 0;
      if (!this.loading) {
        this.mealIds.forEach(id => {
          count += this.getMealCount(id);
          count += this.getAllergyMealCount(id);
        });
      }
      return count;
    },
    overTimeTasks() {
      const overtime = [];
      this.mealIds.forEach(id => {
        // main plating
        if (this.isStarted(id)) {
          const task = this.platingTaskMealMap[id];
          const actual = this.getElapsedTime(task);
          const estimate = this.getPlatingTimeEstimate(this.getMealCount(id));
          if (actual > estimate) {
            overtime.push({
              lateBy: actual - estimate,
              team_members: this.getCurrentTeamMembers(task),
              meal: this.mealMap[id]
            })
          }
        }

        // allergy plating
        if (this.isAllergyStarted(id)) {
          const task = this.allergyPlatingTaskMealMap[id];
          const actual = this.getElapsedTime(task);
          const estimate = this.getPlatingTimeEstimate(this.getAllergyMealCount(id));
          if (actual > estimate) {
            overtime.push({
              lateBy: actual - estimate,
              team_members: this.getCurrentTeamMembers(task),
              meal: this.mealMap[id]
            })
          }
        }
      });
      return overtime.sort((a, b) => a.lateBy - b.lateBy);
    },
    totalShortAmount() {
      const portionSizes = this.shortComponent.source.portionSizes;
      return Object.entries(this.short)
        .map(([size, count]) => portionSizes[size] * count)
        .reduce((sum, i) => sum + i, 0);
    },
    shortTask() {
      const c = this.shortComponent;
      const source = this.shortComponent.source;
      const {mealId, mealName, componentId, line, rack, batch, priority, key} = source;
      const totalKeyMap = {
        M: 'totalAmountMain',
        AL: 'totalAmountAllergy',
        S: 'totalAmountSub'
      }
      const countKeyMap = {
        M: 'count',
        AL: 'allergyCount',
        S: 'substituteCount'
      }
      const counts = {};
      counts[mealId] = {
        "key": `${line}-SHORT`,
        "rack": rack,
      };
      counts[mealId][totalKeyMap[line]] = this.totalShortAmount;
      const count = ensureValuesNumbers(this.short);
      counts[mealId][countKeyMap[line]] = count;
      const title = line === 'S'
        ? `${c.description} (SHORT SUB)`
        : `${c.description} (SHORT ${line})`;
      const countString = Object
        .entries(sortBySize(count))
        .map(([k, v]) => `${k}:${v}`)
        .join(' ');
      const task = {
        title,
        "description": `SHORT for ${line}${rack} of ${key} ${countString}`,
        "reference": `${c.reference}-SHORT`,
        "team_id": 3,  // hard-coded - components team
        "source": {
          mealName,
          componentId,
          isShort: true,
          shortMealId: mealId,
          line,
          rack,
          priority,
          batch,
          "component": {name: c.description},
          count,
          counts,
          totalAmount: this.totalShortAmount
          // "totalAmountAllergy": 456,
          // "production_priority": 2
        },
        // "isStarted": false,
        // "isPaused": false,
      }
      task.source[totalKeyMap[line]] = this.totalShortAmount;
      task.from = this.dateFrom;
      task.to = this.dateTo;
      task.team = 'components'
      return task;
    },
    streams() {
      const streamSet = new Set(this.tasks.map(t => t.source.dietName));
      if (!this.includeCCMeals) {
        streamSet.delete('Chefs Choice');
      }
      return [...streamSet].sort();
    }
  },
  props: {
    platingMode: {
      type: String,
      default:
        'Standard', required:
        false
    }
  }
}
</script>

<style scoped>

</style>