<template>
  <v-card flat>
    <v-card-title>Streams</v-card-title>
    <v-card-actions>
      <v-text-field
          class="ml-4"
          label="Search name and description"
          v-model="search"
      />
      <v-spacer/>
      <v-btn @click="createStream" outlined>Create New Stream</v-btn>
    </v-card-actions>
    <v-card-text>
      <v-alert type="warning">
        Note that visible streams <b>must</b> have a full menu otherwise app.fedfedfed.com will be broken!
      </v-alert>
      <v-card class="mb-2" v-for="(stream,id) of filteredStreams" v-bind:key="id">
        <v-form :ref="`${stream.id}`">
          <v-card-title>
            <v-text-field
                label="Stream ID (spaces not allowed)"
                :disabled="readOnly"
                v-model="stream.id"
            />
            <v-spacer/>
            <v-text-field
                label="Stream Name"
                :disabled="readOnly"
                v-model="stream.name"
            />
            <v-spacer/>
            <v-checkbox v-model="stream.visible" label="Visible"/>
            <v-checkbox v-model="stream.alacarte" label="À la carte"/>
            <v-spacer/>
            <v-chip-group>
              <v-chip color="success" v-if="stream.visible">Available on app.fedfedfed.com</v-chip>
              <v-chip color="warning" v-if="!stream.visible">Hidden</v-chip>
              <template v-if="getDiet(stream.id)">
                <v-chip>DIET STREAM</v-chip>
              </template>
            </v-chip-group>
          </v-card-title>
          <v-card-text>
            <v-text-field
                :disabled="readOnly"
                v-model="stream.description"
            />
            <ul>
              <v-row
                  v-for="(s,index) of getSizes(stream.sizes)"
                  v-bind:key="index">
                <v-col cols="1">
                  {{ s.id }}
                </v-col>
                <v-col>
                  <v-text-field
                      :disabled="readOnly"
                      label="Kitchen Size Description"
                      v-model="s.kitchen_description"/>
                </v-col>
                <v-col>
                  <v-text-field
                      :disabled="readOnly"
                      label="Customer Size Description"
                      v-model="s.description"/>
                </v-col>
                <v-col>
                  <v-checkbox
                      :disabled="readOnly"
                      v-model="s.available"
                      label="Available"/>
                </v-col>
              </v-row>
            </ul>

            <v-textarea rows="1" auto-grow v-model="stream.warning" label="Warning"/>
          </v-card-text>
          <v-card-actions>
            <!--            <v-btn @click="cancelEdit(stream)" outlined>Cancel Changes</v-btn>-->
            <v-spacer/>
            <v-btn @click="save(stream)" outlined>Save</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-card-text>
  </v-card>

</template>

<style scoped>

</style>

<script>
import {mapActions, mapState} from "vuex";
import {deepCopy, escapeRegex} from "@/store/utils";
import diff from "microdiff";
import api from "@/api";
import urlState from "@/router/urlState";

export default {
  name: "Streams",
  mixins: [urlState],
  data() {
    return {
      streamsCopy: {},
      search: null
    }
  },
  props: {
    readOnly: {type: Boolean, default: null, required: false}
  },

  mounted() {
    this.syncToUrl({
      param: 'search', urlParam: 'stream', initFromRoute: true,
      // parseCallback: (v) => Array.isArray(v) ? v : [v]
    });

    return Promise.all([
      this.fetchDiets(),
      this.fetchStreams(),
    ]).then(() => this.copyStreams())
  },
  watch: {
    streams: 'copyStreams',
  },
  methods: {
    ...mapActions(['fetchDiets', 'fetchStreams']),
    getDiet(streamId) {
      return Object.values(this.diets || {}).find(d => d.stream === streamId);
    },
    copyStreams() {
      this.streamsCopy = Object.entries(deepCopy(this.streams)).map(([id, val]) => ({...val, id}));
    },
    hasChanges(stream) {
      return stream.changed;
    },
    cancelEdit(stream) {
      const original = this.streams[stream.id];
      console.log('cancel - original ', original)
      console.log('cancel - editted  ', stream)
      Object.assign(stream, original);
      this.$set(stream, 'sizes', original.sizes);
    },
    save(stream) {
      console.log('saving', stream);
      api.post(`v2/stream/${stream.id}`, stream)
          .then(r => {
            console.log('saved', r);
            alert('saved!')
          })
          .catch(e => {
            console.error('fail', e);
            throw e;
          })
    },
    checkChanges(stream) {
      const original = this.streams[stream.id];
      const difference = diff(original, stream);
      console.log('check diff', difference);
      this.$set(stream, 'changed', difference.length > 0);
    },
    createStream() {
      // use plantbased as the template since size does not mention protein.
      const templateStream = this.streams['plantbased'];
      let newStream = {...deepCopy(templateStream), id: '', name: ''};
      this.streamsCopy.unshift(newStream);
    }
  },
  computed: {
    ...mapState(['diets', 'streams']),
    filteredStreams() {
      const streams = this.streamsCopy;
      if (this.search) {
        const regex = new RegExp(escapeRegex(this.search), 'i');
        return streams.filter(s => regex.test(s.id) || regex.test(s.name) || regex.test(s.description))
      } else {
        return streams;
      }
    },
    getSizes() {
      return sizes => {
        console.log('get sizes', sizes);
        return Object
            .entries(sizes || {})
            .map(([id, size]) => {
              size.id = size.id || id;
              return size;
            })
            .sort((a, b) => a.description.localeCompare(b.description))
      }
    },
  },
}
</script>
