<template>
  <v-card flat v-if="editItem">
    <v-card-title>{{ editItem.name || 'Add New Menu Item' }}</v-card-title>
    <v-card-text>
      <v-form>
        <v-row>
          <v-col>
            <v-text-field
              label="meal id"
              v-model="editItem.id"
              disabled
            />
          </v-col>
          <v-col>
            <MealAutocomplete
              :meal="editMeal"
              v-on:update:meal="editMeal=$event"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              label="name"
              v-model="editItem.name"
              disabled
            />
          </v-col>
          <v-col>
            <v-text-field
              type="number"
              label="price (cents)"
              v-model="editItem.price"
            />
          </v-col>
        </v-row>
        <v-row class="">
          <v-col>
            <v-combobox
              label="Category"
              v-model="editItem.category"
              :items="categories"
              @input="checkIfNew(editItem.category)"
            />
          </v-col>
        </v-row>
        <v-row class="">
          <v-col>
            <v-text-field
              v-model="editItem.index"
              label="Index"
              hint="Sort index"
              persistent-hint
            />
          </v-col>
          <v-col>
            <v-combobox
              v-model="editItem.tags"
              label="Tags"
              multiple
              :items="tags"
              @input="addTags"
              hint="hit enter after each new tag"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-dialog v-model="showDatePicker" width="300px">
            <v-date-picker
              v-model="datePickerValue"
              :allowed-dates="v => moment(v).day()===0"
            />
            <v-toolbar>
              <v-btn @click="showDatePicker=false">Cancel</v-btn>
              <v-spacer/>
              <v-btn @click="saveAndCloseDatePicker">Ok</v-btn>
            </v-toolbar>
          </v-dialog>
          <v-col>
            <v-text-field
              label="Available From"
              v-model="editItem.availableFrom"
              @click="showDatePicker=true; datePickerProp='availableFrom'"
              clearable
            />
          </v-col>
          <v-col>
            <v-text-field
              label="Available To"
              v-model="editItem.availableTo"
              @click="showDatePicker=true; datePickerProp='availableTo'"
              clearable
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-combobox
              label="Allowed in these regions (blank means all)"
              v-model="editItem.regions"
              multiple
              chips deletable-chips
              return-object
              :item-text="i => JSON.stringify(i)"
              :items="availableRegions"/>
          </v-col>
          <v-col/>
        </v-row>
        <!--            <v-text-field-->
        <!--              v-for="header of headers" v-bind:key="header"-->
        <!--              :label="header.text"-->
        <!--              v-model="editItem[header.value]"-->
        <!--            />-->
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn outlined color="red" @click="confirmDelete">Delete</v-btn>
      <v-spacer/>
      <v-btn outlined @click="$emit('close')">Cancel</v-btn>
      <v-spacer/>
      <v-btn outlined @click="$emit('save',editItem); $emit('close')">Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import MealAutocomplete from "@/components/MealAutocomplete";
import {deepCopy} from "@/store/utils";
import api from "@/api";
import moment from 'moment';
import {getRegions} from "fed-lib/src/billing/postcodes";

export default {
  name: "MenuEditDialog",
  components: {MealAutocomplete},
  props: {
    item: {type: Object, default: () => ({}), required: false},
    tags: {},
    categories: {type: Array, default: null, required: false}
  },
  data() {
    return {
      editMeal: null,
      editItem: null,
      editTags: [],
      datePickerValue: null,
      showDatePicker: null,
      datePickerProp: null,
      availableRegions: getRegions()
    }
  },
  watch: {
    editMeal(m) {
      console.log('editMeal', m);
      if (m) {
        this.editItem.name = m.name;
        this.editItem.id = m.id;
      }
    },
    item: 'fetchData'
  },
  async mounted() {
    this.editTags = deepCopy(this.tags);
    return this.fetchData();
  },
  methods: {
    moment,
    fetchData() {
      const item = this.item;
      this.editItem = deepCopy(item);
      // if (item.id) {
      //   return this.fetchMeal(item.id)
      // }
    },
    fetchMeal(id) {
      id = Number.parseInt(id);
      return api
        .get(`v2/meal/${id}`)
        .then(({data}) => {
          console.log('got', data);
          this.editMeal = data;
        })
    },
    checkIfNew(category) {
      if (category && !this.categories.includes(category)) {
        if (confirm(`would you like to create a new category called ${category}?`)) {
          //
          this.categories.push(category);
        } else {
          this.editItem.category = '';
        }
      }

    },
    addTags(tags) {
      tags.forEach(tag => {
        if (!this.tags.includes(tag)) {
          this.$emit('add-tag', tag);
          // this.tags.push(tag);
        }
      });
    },
    saveAndCloseDatePicker() {
      const prop = this.datePickerProp;
      this.editItem[prop] = `${this.datePickerValue}`;
      this.showDatePicker = false;
    },
    confirmDelete() {
      if (confirm('Are you sure?\n\nDelete can cause problems if anyone has ordered this meal.  It is better to mark it unavailable by setting Available To')) {
        this.$emit('delete', this.editItem);
        this.$emit('close')
      }
    }
  },

}
</script>

<style scoped>

</style>