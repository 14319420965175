<template>
  <div>
    <v-snackbar
      top
      outlined
      v-model="showSnack"
      color="success"
      timeout="1000"
      centered
    >
      {{ snackMessage }}
    </v-snackbar>
    <v-progress-linear v-if="loading" :indeterminate="true"/>
    <v-container class="users" fluid v-if="!loading">
      <v-row flat class="d-print-none">
        <v-col class="text-right" style="font-size: smaller" v-if="metricsShown">
          Current orders are from {{ dateFormatted(dateFrom) }} to {{ dateFormatted(dateTo) }}<br/>
          Upcoming orders are from {{ dateFormatted(upcomingDateFrom) }} to {{ dateFormatted(upcomingDateTo) }}<br/>
          If you want this page to load faster, hide "First Week/Day", "Current Meals", and "Upcoming Meals"
          <!--          <v-btn outlined @click="adjustDate(-1,'week')">-->
          <!--            <v-icon>mdi-chevron-left</v-icon>-->
          <!--          </v-btn>-->
          <!--          Adjust Dates-->
          <!--          <v-btn outlined @click="adjustDate(1,'week')">-->
          <!--            <v-icon>mdi-chevron-right</v-icon>-->
          <!--          </v-btn>-->
        </v-col>
      </v-row>
      <v-toolbar flat class="d-print-none pb-10">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search for customer"
          single-line
          hide-details
          clearable
        />
        <v-btn v-if="canBulkEdit && selected.length>0"
               @click="showBulkEditDialog=true"
               text outlined class="ml-4">Bulk Edit
        </v-btn>
        <v-btn v-if="selected.length>0"
               @click="showEmails=true"
               text outlined class="ml-4">Copy Emails
        </v-btn>
        <v-select
          class="pl-4"
          label="Filter by Plan"
          v-model="filterByPlan"
          :items="plans"
          hide-details
          clearable
        />
        <v-spacer/>
        <v-checkbox
          v-model="showShopify"
          label="Show Shopify"
          hide-details
        />
        <v-checkbox
          v-model="showDeleted"
          label="Show Deleted"
          hide-details
        />
        <template v-if="finance">
          <span>Apply</span>
          <v-checkbox
            style="max-width: 80px"
            persistent-hintcomponent
            v-model="applyFilter"
            type="number"
            hide-details
            true-value="0"
          />
          <v-text-field
            :disabled="!applyFilter"
            reverse
            prefix="days"
            style="max-width: 110px"
            label="Last Invoice Within"
            v-model="daysBack"
            type="number"
            min="1"
            hide-details
          />
        </template>
        <v-checkbox
          class="pl-4"
          :label="`Upcoming Meals ${finance ? totalMealCount : ''}`"
          v-model="hasUpcomingMeals"
          hide-details
        />

        <v-spacer/>
        <!--        <v-combobox-->
        <!--            label="View"-->
        <!--            single-line-->
        <!--            hide-details-->
        <!--            style="max-width: 160px"-->
        <!--            v-model="viewName"-->
        <!--            :items="views"-->
        <!--            @change="saveSettings"-->
        <!--        />-->

        <v-select
          style="max-width: 80px"
          single-line
          label="Columns"
          v-model="selectedHeaders"
          :items="headers"
          multiple
          hide-details
          return-object
          @change="saveSettings"
          append-icon="mdi-dots-vertical"
        >
          <template v-slot:selection="{ index }">
            <span v-if="index===0" class="caption">
              Columns
            </span>
          </template>
        </v-select>
        <v-btn class="pl-1" x-small text @click="selectedHeaders=defaultHeaders;saveSettings()">Reset</v-btn>
      </v-toolbar>
      <v-toolbar flat v-if="finance">
        <v-btn :loading="finaliseRunning" outlined @click="finaliseInvoices">Finalise Invoices</v-btn>
        <v-spacer/>
        <v-btn outlined @click="csvExport(dataAsTable,'customers.csv')">Download CSV</v-btn>
      </v-toolbar>
      <div>
        <v-data-table
          :headers="sortedHeaders"
          :items="customers"
          item-key="uid"
          :search="search"
          :loading="loading"
          sort-by="name"
          dense
          calculate-widths
          :show-group-by="customers.length<pagingLimit"
          :expanded.sync="expanded"
          :footer-props="footerProps"
          :group-desc="false"
          :group-by.sync="groupBy"
          :disable-pagination="customers.length<pagingLimit"
          :hide-default-footer="customers.length<pagingLimit"
          :items-per-page="20"
          :no-results-text="applyFilter?`No results found, try unchecking 'Apply Filter'`:undefined"
          show-select
          selectable-key="uid"
          v-model="selected"
        >
          <template v-slot:item.address="{ item } ">
            {{ formatAddress(item.address) }} {{ formatDeliveryTime(item) }}
          </template>
          <template v-slot:item.useAddress="{ item } ">
            <ul v-if="item.useAddress ">
              <li v-for="(e,index) of Object.entries(item.useAddress)" v-bind:key="index">
                <b>{{ e[0] }}</b> {{ formatAddress(e[1]) }} {{ formatDeliveryTime(e[1]) }}
              </li>
            </ul>
          </template>
          <template v-slot:item.stripe_id="{ item } ">
            <a target="_blank" :href="`https://dashboard.stripe.com/customers/${item.stripe_id}`">
              {{ item.stripe_id }}
            </a>
          </template>
          <template v-slot:item.currentOrderCount="{ item} ">
            <router-link :to="{name:'OrderEdit', query: { customer: item.uid, dates: [dateFrom,dateTo]}}">
              {{ item.currentOrderCount }}
            </router-link>
          </template>
          <template v-slot:item.upcomingOrderCount="{ item} ">
            <router-link
              :to="{name:'OrderEdit', query: { customer: item.uid, dates: [upcomingDateFrom,upcomingDateTo]}}">
              {{ item.upcomingOrderCount }}
            </router-link>
          </template>
          <template v-slot:item.net="{ item} ">
            <v-row>
              <v-col></v-col>
              <v-col>{{ item.net ? `$${(item.net / 100).toFixed(2)}` : '' }}</v-col>
            </v-row>
          </template>
          <template v-slot:item.refunds="{ item} ">
            <v-row>
              <v-col></v-col>
              <v-col>{{ item.refunds ? `$${(item.refunds / 100).toFixed(2)}` : '' }}</v-col>
            </v-row>
          </template>
          <template v-slot:item.total="{ item} ">
            <v-row>
              <v-col></v-col>
              <v-col>{{ item.total ? `$${(item.total / 100).toFixed(2)}` : '' }}</v-col>
            </v-row>
          </template>


          <template v-slot:item.allergies="{ item }">
            <v-chip
              text outlined
              color="red"
              x-small
              class="ma-1"
              v-for="a of item.allergies"
              v-bind:key="a"
            >
              {{ a }}
            </v-chip>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn small outlined v-if="!readOnly"
                   :to="{name:(finance?'CustomerFinance':'Customer'), params: { id: item.uid} }">Edit
            </v-btn>
          </template>
          <template v-slot:item.email="{ item }">
            <span class="d-inline-block text-truncate caption" style="max-width: 150px">
              <router-link :to="{name:(finance?'CustomerFinance':'Customer'), params: { id: item.uid}}">
                {{ item.email }}
              </router-link>
            </span>
          </template>
          <template v-slot:item.paidByEmails="{ item }">
            <v-btn v-if="item.paidBy.length > 1" icon small>
              <v-icon onclick="alert(`paid by more than one person!`)" color="red">mdi-alert</v-icon>
            </v-btn>
            <span v-if="item.paidByEmails" class="d-inline-block text-truncate caption" style="max-width: 150px">
            <router-link
              v-for="(c,i) of item.paidBy" v-bind:key="i"
              :to="{name:(finance?'CustomerFinance':'Customer'), params: { id: c.uid}}">
              {{ c.email }}
            </router-link>
            </span>
          </template>
          <template v-slot:item.subAccountCount="{ item }">
            <v-btn x-small v-if="item.subCustomers.length>0"
                   @click="expanded = expanded[0]!==item?[item]:[]"
            >
              {{ item.subCustomers.length }} Sub Accounts
            </v-btn>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-simple-table class="ma-0 pa-0">
                <thead>
                <th v-for="header of headers"
                    v-bind:key="header.value"
                ><b>{{ header.text }}</b>
                </th>
                </thead>
                <tbody>
                <tr
                  v-for="subCustomer of item.subCustomers"
                  v-bind:key="subCustomer.uid"
                >
                  <td
                    v-for="header of headers"
                    v-bind:key="header.value"
                  >
                    {{ subCustomer[header.value] }}
                    <v-btn small outlined v-if="header.value==='actions' && !readOnly"
                           :to="{name:(finance?'CustomerFinance':'Customer'), params: { id: item.uid} }">Edit
                    </v-btn>
                  </td>
                </tr>
                </tbody>
              </v-simple-table>
            </td>
          </template>
        </v-data-table>
      </div>
    </v-container>
    <v-dialog v-model="showBulkEditDialog">
      <v-card v-if="savingCount>0">
        <v-card-title>Saving {{ savingCount }}</v-card-title>
        <v-card-actions>
          <v-btn @click="cancelBulkEdit=true">Cancel</v-btn>
          <v-spacer/>
          <v-btn disabled @click="saveBulkEdit">Save</v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else>
        <v-card-title>Bulk Edit {{ selected.length }} Customers</v-card-title>
        <v-card-text>
          <v-select
            label="Attribute"
            v-model="attributeToEdit"
            :items="customerAttributes"
          />
          <v-select v-if="attributeOptions[attributeToEdit]"
                    v-model="newValue"
                    :items="attributeOptions[attributeToEdit]"
          />
          <v-text-field v-else v-model="newValue"/>

        </v-card-text>
        <v-card-actions>
          <v-btn @click="showBulkEditDialog=false">Cancel</v-btn>
          <v-spacer/>
          <v-btn @click="saveBulkEdit">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showEmails">
      <v-card>
        <v-card-title>{{ selected.length }} email addresses shown below</v-card-title>
        <v-card-actions>
          <v-btn @click="showEmails=false">Close</v-btn>
          <v-spacer/>
          <v-btn @click="copyEmails">Copy</v-btn>
        </v-card-actions>
        <v-card-text>
          <v-textarea
            ref="emailsToCopy"
            :value="selected.map(c=>c.email).join('\n')"
            auto-grow
            style="max-height: fit-content"
          />
        </v-card-text>

      </v-card>

    </v-dialog>
  </div>
</template>


<script>
import {mapActions, mapGetters} from 'vuex'
import moment from 'moment';
import api from '@/api';
import {getDateString, dateFormatted, csvAsTable, csvExport, formatAddress} from '@/store/utils';
import urlState from "@/router/urlState";


export default {
  mixins: [urlState],
  mounted() {
    this.syncToUrl({
      param: 'search', urlParam: 'search', initFromRoute: true,
    })
    this.syncToUrl({
      param: 'showShopify', urlParam: 'shopify', initFromRoute: true,
      parseCallback: (v) => v === 'true'
    })
    this.syncToUrl({
      param: 'showDeleted', urlParam: 'deleted', initFromRoute: true,
      parseCallback: (v) => v === 'true'
    })
    this.syncToUrl({
      param: 'applyFilter', urlParam: 'filter', initFromRoute: true,
      parseCallback: (v) => v === 'true'
    })
    this.syncToUrl({
      param: 'hasUpcomingMeals', urlParam: 'upcoming', initFromRoute: true,
      parseCallback: (v) => v === 'true'
    })
    this.syncToUrl({
      param: 'daysBack', urlParam: 'days', initFromRoute: true,
      parseCallback: (v) => Number(v)
    })
    this.syncToUrl({
      param: 'filterByPlan', urlParam: 'plan', initFromRoute: true,
      // parseCallback: (v) => Number(v)
    })

    // this.syncToUrl({
    //   param: 'dateFrom', urlParam: 'dateFrom', initFromRoute: true
    // });
    // this.syncToUrl({
    //   param: 'dateTo', urlParam: 'dateTo', initFromRoute: true
    // });
    //
    // this.syncToUrl({
    //   param: 'firstWeekOnly', urlParam: 'new', initFromRoute: true,
    //   parseCallback: (v) => v === 'true'
    // });

    this.defaultHeaders.push(...this.headers.slice(0, 4));
    if (this.finance) {
      // we want to keep actions as the last element
      const last = this.headers.pop();
      this.headers.push(...this.financeHeaders, last);
      // keep different settings
      this.viewName = `${this.viewName}-finance`;
      this.defaultHeaders.push(...this.financeHeaders);
      this.defaultHeaders.push(this.headers.find(h => h.value === 'lastCharge'))
      this.defaultHeaders.push(this.headers.find(h => h.value === 'lastChargeMaster'))
      this.defaultHeaders.push(this.headers.find(h => h.value === 'lastChargeEither'))
      this.defaultHeaders.push(this.headers.find(h => h.value === 'invoiceCount'))
      this.defaultHeaders.push(this.headers.find(h => h.value === 'upcomingOrderCount'))
    }
    this.loadView(this.viewName);

    if (!this.dateFrom) {
      this.dateFrom = moment().startOf('week'); //.add(1, 'week');
      if (this.finance) {
        this.dateFrom.add(1, 'week');
      }
      if (moment().day() >= 5) {
        this.dateFrom.add(1, 'week');
      }
      this.dateTo = this.dateFrom.clone().add(6, 'days');
      this.dateFrom = this.dateFrom.format(moment.HTML5_FMT.DATE);
      this.dateTo = this.dateTo.format(moment.HTML5_FMT.DATE);
    }

    return Promise.all([
      this.fetchData(),
      this.fetchPlans()
        .then(plans => {
          this.attributeOptions.plan = Object.keys(plans);
        })
    ]);

  },
  data() {
    return {
      selectedHeaders: [],
      units: ['g', 'kg'],
      decimalPlaces: [1, 2],
      respondToRouteChanges: true,
      streamsSelected: [],
      selected: [],
      missing_meals: new Set(),
      missing_count: {},
      showDatePicker: false,
      dates: [],
      search: '',
      loading: false,
      post: null,
      error: null,
      multiview: false,
      multiviewMealSelected: {},
      financeHeaders: [
        {text: 'Stripe', value: 'stripe_id'},
        {text: 'Invoice Charges', value: 'total', align: 'right'},
        {text: 'Invoice Refunds', value: 'refunds', align: 'right'},
        {text: 'Invoice Net', value: 'net', align: 'right'},
        {text: 'First Invoice Date', value: 'firstCharge'},
        {text: 'Age (Last-First Invoice Date)', value: 'age'},
        {text: 'Old Admin', value: 'is_admin'},
        {text: 'Sub Account Admin', value: 'is_subaccount_admin'},
        {text: 'Weeks', value: 'metrics.weeks'},
        {text: 'Stream Meals', value: 'metrics.order_type.full'},
        {text: 'CC Meals', value: 'metrics.order_type.chefs_choice'},
        {text: 'Total Deliveries', value: 'metrics.shipments'},
        {text: 'MonTue Deliveries', value: 'metrics.shippingDays.mon'},
        {text: 'WedThu Deliveries', value: 'metrics.shippingDays.wed'},
        {text: 'FriSat Deliveries', value: 'metrics.shippingDays.fri'},
        {text: 'initial_utm_campaign', value: 'mixpanel.initial_utm_campaign'},
        {text: 'initial_utm_term', value: 'mixpanel.initial_utm_term'},
        {text: 'initial_utm_source', value: 'mixpanel.initial_utm_source'},
        {text: 'initial_referrer', value: 'mixpanel.initial_referrer'},
        {text: 'initial_referring_domain', value: 'mixpanel.initial_referring_domain'},
        {text: 'last seen(mixpanel)', value: 'mixpanel.last_seen'}
      ],
      defaultHeaders: [],
      headers: [
        {text: 'Customer Name', value: 'name', groupable: false},
        {text: 'Email', value: 'email', width: '10px', groupable: false},
        {text: 'Phone', value: 'phone_number'},
        // {text: 'Paused', value: 'pause'},
        {text: 'Pause From', value: 'pauseFrom'},
        {text: 'Pause To', value: 'pauseTo'},
        {text: 'Plan', value: 'plan'},
        {text: 'Plan (change)', value: 'pendingEdits.plan'},
        {text: 'Coupon', value: 'coupon'},
        {text: 'Meal Size', value: 'meal_size'},
        {text: 'Delivery Times', value: 'deliveryTimes'},
        {text: 'Address', value: 'addressFormatted'},
        {text: 'Address (change)', value: 'pendingAddressFormatted'},
        {text: 'Alternate Addresses', value: 'useAddress'},
        {text: 'Production Group', value: 'production'},
        {text: 'Location', value: 'address.location'},
        {text: 'Unit', value: 'address.unit'},
        {text: 'Post Code', value: 'address.postal_code'},
        {text: 'City', value: 'address.city'},
        {text: 'Province', value: 'address.state'},
        {text: 'Use Shipping', value: 'address.use_shipping'},
        {
          text: 'Delivery Instructions',
          value: 'delivery_instruction',
          groupable: false
        },
        {text: 'Meal At Door', value: 'meal_at_door'},
        {text: 'Comment', value: 'comment', groupable: false},
        {text: 'Newsletter', value: 'newsletter'},
        {text: 'Active', value: 'is_active'},
        {text: 'Free Shipping', value: 'free_shipping'},
        {text: 'Special Request', value: 'special_request', groupable: false},
        {text: 'Company', value: 'company'},
        {text: 'User ID', value: 'uid', groupable: false},
        {text: 'Sub Accounts', value: 'subAccountCount'},
        {text: 'Paid By', value: 'paidByEmails'},
        {text: 'Dietary Restrictions', value: 'allergies'},
        {text: 'First Week', value: 'isFirstOrder'},
        {text: 'Never Ordered', value: 'neverOrdered'},
        {text: 'First Delivery Day', value: 'firstOrderDay'},
        {text: 'Invoices', value: 'invoiceCount'},
        {text: 'Found Fed Channel', value: 'foundOutBy.channel'},
        {text: 'Found Fed Detail', value: 'foundOutBy.other'},
        {text: 'Referral Credits', value: 'referralCredits'},
        {text: 'Last Invoice Date', value: 'lastCharge'},
        {text: 'Last Invoice Date (Master)', value: 'lastChargeMaster'},
        {text: 'Last Invoice Date (either)', value: 'lastChargeEither'},
        {text: 'Current Meals', value: 'currentOrderCount'},
        {text: 'Upcoming Meals', value: 'upcomingOrderCount'},
        {text: 'Extra Charge Label', value: 'price_adjustment_label'},
        {text: 'Extra Charge', value: 'price_adjustment'},
        {text: 'Signup (stripe)', value: 'stripe.created_at'},
        {text: 'Closing Balance (stripe)', value: 'stripe.closingBalance'},
        {text: 'Login Disabled', value: 'login_disabled'},
        {text: 'Actions', value: 'actions', groupable: false, width: 100}
      ],
      showMissing: false,
      filteredIngredients: [],
      expanded: [],
      footerProps: {
        itemsPerPageOptions: [10, 20, 100, 500, 1000, -1],
        showFirstLastPage: true,
      },
      viewName: 'customer-columns',
      groupBy: [],
      daysBack: 30,
      applyFilter: false,
      showBulkEditDialog: false,
      newValue: null,
      attributeToEdit: 'plan',
      savingCount: 0,
      cancelBulkEdit: null,
      customerAttributes: ['plan'],
      showEmails: false,
      attributeOptions: {},
      dateFrom: null,
      dateTo: null,
      hasUpcomingMeals: true,
      pagingLimit: 200,
      finaliseRunning: false,
      showSnack: null,
      snackMessage: null,
      filterByPlan: null,
      ordersByCustomer: {},
      firstWeekOnly: null,
      customersRaw: [],
      showShopify: null,
      fetchCustomerPromise: null,
      metricHeaders: ['currentOrderCount', 'upcomingOrderCount', 'isFirstOrder', 'firstOrderDay'],
      showDeleted: null,
      fetchedWithMetrics: null
    }
  },
  watch: {
    showShopify() {
      return this.fetchData()
    },
    metricsShown(newVal, oldVal) {
      if (newVal && !oldVal) {
        // need to refetch customer data with metrics in it
        return this.fetchData()
      }
    }
  },
  computed: {
    ...mapGetters([
      'diets',
      'getDietName',
      'getDietStream',
      'getRestrictionText',
      'getOrdersOnDate',
    ]),
    ordersUnfiltered() {
      const orders = [];
      console.log('concat orders from dates', {dates: this.dates});
      this.getDatesInRange()
        .forEach(date =>
          orders.push(...(
            this.getOrdersOnDate({date}) || []
          )));
      return orders;
    },
    sortedHeaders() {
      const headers = this.headers.filter(h => this.selectedHeaders.find(i => i.value === h.value));
      return headers;
    },
    readOnly() {
      return !!this.$route.meta.readOnly;
    },
    customersUnfiltered() {
      if (this.loading) {
        return [];
      }
      const customers = this.customersRaw;
      const formatCustomer = (c, isSub = false) => {
        // console.log('isSub', c.uid, isSub);
        const pendingAddress = c.pendingEdits && c.pendingEdits.address;
        const subCustomers = isSub === true // to avoid cycles in formatCustomer, we pass isSub=true
          ? []
          : Object.keys(c.subAccounts || {})
            .map(uid => customers.find(c => c.uid === uid))
            .filter(c => !!c)
            .map(c => formatCustomer(c, true));
        const isFirstOrder = c.isFirstOrder; //(this.ordersByCustomer[c.uid] || []).some((o) => o.isFirstOrder);
        const totalReferralCredit = (c.referralCoupons || []).map(c => c.credit).reduce((sum, i) => sum + (i || 0), 0);
        const referralCredits = (totalReferralCredit / 100).toFixed(2);
        const age = c.stats && c.stats.firstCharge ? Math.abs(moment(c.stats.firstCharge).diff(c.stats.lastCharge, 'days')) : '';
        let firstOrder = isFirstOrder ? 'yes!' : 'no!';
        if (c.stripe && c.stripe.created_at && firstOrder !== 'yes!') {
          if (moment(c.stripe.created_at).isAfter(this.dateFrom) && c.upcomingOrderCount > 0) {
            firstOrder = 'new!';
          }
        }
        return {
          ...c,
          ...(c.stats ? c.stats : {}),
          age,
          deliveryTimes: this.formatDeliveryTime(c.address),
          name: `${c.first_name} ${c.last_name}`.trim(),
          referralCredits,
          firstOrderDay: c.isFirstOrder ? `${moment(c.isFirstOrder).format('ddd')}!` : '',
          addressFormatted: formatAddress(c.address),
          pendingAddressFormatted: !pendingAddress ? '' : formatAddress(pendingAddress),
          ...Object.fromEntries(
            Object.keys(c.address)
              .map(k => ['address.' + k, c.address[k]])
          ),
          coupon: c.stripe && c.stripe.coupon ? c.stripe.coupon : c.coupon,
          subCustomers: subCustomers,
          subAccountCount: subCustomers.length,
          allergies: c.allergies ? c.allergies.map(a => this.getRestrictionText(a)) : [],
          stripe_id: c.stripe ? c.stripe.id : '',
          // upcomingOrderCount: (this.ordersByCustomer[c.uid] || []).reduce((a, i) => i.quantity + a, 0),
          isFirstOrder: firstOrder,
          neverOrdered: !(c.metrics && c.metrics.weeks),
          pauseFrom:  c.pause ? c.pause[0] : '',
          pauseTo: c.pause ? c.pause[1] : ''
        }
      }
      const result = customers
        .filter(c => c.address) //customer with no address is junk!
        .map(formatCustomer);

      const paidBy = {};
      result
        .filter(c => c.subAccounts)
        .forEach(c => {
          Object
            .keys(c.subAccounts)
            .forEach(uid => {
              paidBy[uid] = paidBy[uid] || [];
              paidBy[uid].push(c);
            })
        })
      result.forEach(c => {
        const paidByCustomer = paidBy[c.uid] || [];
        c.paidBy = paidByCustomer;
        c.paidByEmails = paidByCustomer.map(c => c.email).join(', ');
        c.lastChargeMaster = paidByCustomer.map(c => c.lastCharge).join(', ');
        c.lastChargeEither = [c.lastCharge, ...paidByCustomer.map(c => c.lastCharge)]
          .filter(s => !!s)
          .sort()
          .reverse()[0];
      });
      // console.log('cusotmersUnfiltered', result);
      return result;
    },
    customers() {
      let customers = this.customersUnfiltered;
      if (!this.showDeleted) {
        customers = customers.filter(c => !c.isDeleted);
      }
      if (this.firstWeekOnly) {
        customers = customers.filter(c => c.isFirstOrder);
      }
      const filterDaysBack = (c) => {
        if (this.hasUpcomingMeals) {
          return c.upcomingOrderCount > 0;
        }

        if (this.applyFilter) {
          const lastCharge = c.stats && c.stats.lastCharge;
          if (lastCharge) {
            const daysSince = moment.duration(moment().diff(moment(lastCharge))).asDays();
            // console.log('filter', [lastCharge, daysSince]);
            return daysSince <= this.daysBack;
          } else {
            return false;
          }
        } else
          return true;
      }

      if (this.filterByPlan) {
        customers = customers.filter(c => c.plan === this.filterByPlan);
      }
      return customers.filter(filterDaysBack);
    },
    totalCount() {
      let count = 0;
      this.customers.forEach(o => count += o.quantity);
      return count;
    },
    totalMealCount() {
      let count = 0;
      this.customers.forEach(o => count += (o.upcomingOrderCount || 0));
      return count;
    },
    views() {
      return [];
    },
    plans() {
      return [...new Set(this.customersUnfiltered.map(c => c.plan))].sort();
    },
    upcomingDateFrom() {
      return this.dateFrom
        ? moment(this.dateFrom).add(1, 'week').format(moment.HTML5_FMT.DATE)
        : '';
    },
    upcomingDateTo() {
      return this.dateTo
        ? moment(this.dateTo).add(1, 'week').format(moment.HTML5_FMT.DATE)
        : '';
    },
    metricsShown() {
      return this.hasUpcomingMeals || this.selectedHeaders.some(h => this.metricHeaders.includes(h.value));
    },
    dataAsTable() {
      return csvAsTable(this.customers, this.sortedHeaders, this.search);
    }
  },
  methods: {
    formatAddress,
    ...mapActions([
      'fetchRestrictions',
      'fetchPlans',
      'fetchOrdersByDate',
    ]),
    dateFormatted,
    getDatesInRange() {
      const dates = [];
      if (!this.dateFrom || !this.dateTo) {
        return dates;
      }
      const from = moment(this.dateFrom);
      const to = moment(this.dateTo);

      const diff = moment.duration(to.diff(from));
      const maxDays = 31;
      if (diff.asDays() >= 0 && diff.asDays() <= maxDays) {
        // console.log('adding days ', {from, to})
        while (from.isSameOrBefore(to)) {
          const dateString = getDateString(from);
          // console.log('added date', dateString);
          dates.push(dateString);
          from.add(1, 'day');
        }
      } else {
        window.alert(diff.asDays() + ' days is above the limit of ' + maxDays);
      }
      return dates;
    },
    fetchData() {
      this.error = this.post = null
      this.loading = true;
      return Promise.all([
        this.fetchCustomers2(),
        this.fetchRestrictions(),
        // Promise
        //   .all(this.getDatesInRange()
        //     .map(date => this.fetchOrdersByDate({date})))
        //   .then(orders => {
        //     // console.log('orders',orders);
        //     this.setOrdersByCustomer(orders.flatMap(o => o));
        //   }),
      ])
        .catch((e) => {
          console.error('something went wrong', e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
//     setOrdersByCustomer(orders) {
// //      console.log('setOrdersByCustomer',orders);
//       const customerToOrderMap = {};
//       for (const order of orders) {
//         // const {uid, paidBy} = order;
//         // const id = paidBy || uid;
//         const {uid: id} = order;
//         customerToOrderMap[id] = customerToOrderMap[id] || [];
//         customerToOrderMap[id].push(order);
//       }
//       console.log('customerToOrderMap', customerToOrderMap);
//       this.ordersByCustomer = customerToOrderMap;
//       return customerToOrderMap;
//     },

    meal_sizes() {
      return 'small,medium,large'.split(',');
    },
    saveSettings() {
      const selectedHeaders = this.selectedHeaders;
      localStorage.setItem(this.viewName, JSON.stringify(selectedHeaders));
    },
    loadView(viewName) {
      try {
        this.selectedHeaders = JSON.parse(localStorage.getItem(viewName));
        if (this.viewName !== viewName) {
          this.viewName = viewName;
        }
      } catch (e) {
        console.warn('failed to restore ', viewName);
      }
      if (!this.selectedHeaders) {
        this.selectedHeaders = this.defaultHeaders;
      }
    },
    async saveBulkEdit() {
      const message = `Are you sure you want to change ${this.selected.length} customers' ${this.attributeToEdit} to ${this.newValue}?`
      if (confirm(message)) {
        const update = {};
        this.cancelBulkEdit = false;
        this.savingCount = this.selected.length;
        update[this.attributeToEdit] = this.newValue;
        for (const customer of this.selected) {
          const {uid} = customer;
          if (!this.cancelBulkEdit) {
            await api.put(`users/firebase/${uid}`, update)
              .then(() => {
                customer[this.attributeToEdit] = this.newValue;
                this.savingCount--;
              })
          }
        }
      }
      this.showBulkEditDialog = false;
    },
    copyEmails() {
      let textToCopy = this.$refs.emailsToCopy.$el.querySelector('textarea')
      textToCopy.select()
      document.execCommand("copy");
    },
    async finaliseInvoices() {
      if (confirm('all draft invoices created within the last day will be finalised')) {
        this.finaliseRunning = true;
        let result = await api.post('v2/stripe/finalise-all');
        this.showSnack = true;
        this.snackMessage = result.data.progress;
        while (result && result.data.progress !== 'stopped') {
          result = await api.post('v2/stripe/finalise-all?check=true');
          this.showSnack = true;
          this.snackMessage = result && result.data.progress;
          await new Promise(resolve => {
            setTimeout(() => resolve(1), 1000)
          });
        }
        this.finaliseRunning = false;
        alert('invoices finalised');
      }
    },
    adjustDate(amount, unit) {
      this.dateFrom = moment(this.dateFrom).add(amount, unit).format(moment.HTML5_FMT.DATE);
      this.dateTo = moment(this.dateTo).add(amount, unit).format(moment.HTML5_FMT.DATE);
      return this.fetchData();
    },
    fetchCustomers2() {
      if (!this.fetchedWithMetrics && this.metricsShown) {
        this.fetchedWithMetrics = true;
      } else {
        if (this.fetchedWithMetrics && this.customersRaw) {
          // already loaded
          console.log('already fetched customers');
          return;
        }
      }

      if (!this.fetchCustomerPromise) {
        this.fetchCustomerPromise = api
          .get('v2/customers', {
            params: {
              skipShopify: !this.showShopify,
              orderMetrics: this.metricsShown,
            }
          })
          .then(({data}) => {
            console.log('got customers', data);
            this.customersRaw = data;
          })
          .finally(() => {
            this.fetchCustomerPromise = false;
          });
      }

      return this.fetchCustomerPromise;
    },
    csvExport,
    formatDeliveryTime({earliest_time, latest_time}) {
      if (!earliest_time && !latest_time) {
        return '';
      }
      if (earliest_time && latest_time) {
        return `${earliest_time} to ${latest_time}`;
      }
      if (earliest_time) {
        return `after ${earliest_time}`;
      }
      if (latest_time) {
        return `before ${latest_time}`;
      }
    }
  },
  props: {
    finance: {type: Boolean, default: false, required: false},
    canBulkEdit: {type: Boolean, default: false, required: false}
  }
}
</script>

<style>
.v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: none !important;
}
</style>
