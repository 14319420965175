<template>
  <v-data-table
    dense
    :items="metrics"
    :headers="headers"
    :show-group-by="false"
  >
    <template v-slot:item.net="{item : { net}}">
      {{ formatCurrency(net) }}
    </template>
    <template v-slot:body.prepend>
      <tr>
        <th v-for="(h,i) of headers" v-bind:key="i">
          <span v-if="i===0">Totals</span>
          <span v-if="h.value==='total' || isDate(h.value)">
            {{ getSum(metrics, h.value) }}
          </span>
        </th>
      </tr>
    </template>
  </v-data-table>
</template>
<script>
import moment from "moment";
import {formatCurrency} from "@/store/utils";

export default {
  name: 'MetricsTable',
  props: {
    headers: {},
    metrics: {}
  },
  computed: {},
  methods: {
    formatCurrency,
    isDate(s) {
      return moment(s, moment.HTML5_FMT.DATE, true).isValid()
    },
    getSum(metrics, key) {
      const sum = metrics.reduce((sum, r) => sum + r[key], 0);
      return sum;
    }
  }
}
</script>
