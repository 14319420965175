<template>
  <PackTasks team="packing"/>
</template>

<script>


import PackTasks from "@/components/tasks/PackTasks.vue";

export default {
  name: "PackingTasks",
  components: {PackTasks},
}
</script>

<style scoped>

</style>
