<template>
  <v-toolbar-items align="right">
    <v-dialog
      v-model="showProductionPicker"
      ref="dialog"
      width="350px"
    >
      <v-card>
        <v-card-title>Selection Production Group</v-card-title>
        <v-card-actions>
          <v-spacer/>
          <v-btn-toggle v-model="selectedProduction">
            <v-btn
              v-for="p of upcomingProductions"
              v-bind:key="p"
              :value="p"
            >
              {{ p }}
            </v-btn>
          </v-btn-toggle>
          <v-spacer/>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showDatePicker"
      ref="dialog"
      width="290px"
    >
      <v-date-picker
        v-model="selectedDate"
        @change="showDatePicker=false"
      />
    </v-dialog>
    <v-btn @click="showProductionPicker=true">
      Production:
      {{ production }}
    </v-btn>
    <v-btn @click="showDatePicker=true">
      {{ dateFormatted }}
    </v-btn>
    <v-btn :to="{ params: { date: `${dayBefore}`}}" icon
    >
      <v-icon>mdi-skip-previous</v-icon>
    </v-btn>
    <v-btn :to="{ params: { date: `${dayAfter}`}}" icon>
      <v-icon>mdi-skip-next</v-icon>
    </v-btn>
  </v-toolbar-items>
</template>

<script>
import moment from 'moment';
import {mapActions, mapState} from 'vuex';
import {extractDateFromDateProduction, extractProductionFromDateProduction} from "@/store/utils";

export default {
  name: "MealButtons",
  data() {
    return {
      showDatePicker: false,
      showProductionPicker: null,
      selectedProduction: null,
      selectedDate: null,
    }
  },
  async mounted() {
    this.selectedDate = extractDateFromDateProduction(this.date);
    this.selectedProduction = extractProductionFromDateProduction(this.date)
    await this.fetchUpcomingProductions();
  },
  watch: {
    selectedProduction: 'updateDateAndProduction',
    selectedDate: 'updateDateAndProduction',
  },
  methods: {
    ...mapActions(['setDate', 'fetchUpcomingProductions']),
    updateDateAndProduction() {
      this.showProductionPicker = false;
      this.showDatePicker = false;
      const value = {date: this.selectedDate || this.date, production: this.selectedProduction || this.production}
      console.log('updating', value);
      if (this.date !== value.date || this.production !== value.production) {
        const newDate = `${value.date}_${value.production}`;
        const newLocation = {name: this.$route.name, params: {date: newDate}};
        return this.$router.push(newLocation);
      }
    },
  },
  computed: {
    ...mapState(['productions']),
    upcomingProductions() {
      return [...new Set(this.productions.map(p => p.production))];
    },
    production() {
      return extractProductionFromDateProduction(this.$route.params.date);
    },
    date() {
      return extractDateFromDateProduction(this.$route.params.date);
    },
    dateFormatted() {
      return moment(this.date).format('dddd MMM DD');
    },
    dayBefore() {
      return moment(this.date).subtract(1, 'day').format('YYYY-MM-DD')
        + (this.production ? `_${this.production}` : '');
    },
    dayAfter() {
      return moment(this.date).add(1, 'day').format('YYYY-MM-DD')
        + (this.production ? `_${this.production}` : '');
    },
  }
}
</script>

<style scoped>

</style>
