import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VToolbar,{attrs:{"flat":""}},[_c('ComponentAutocomplete',{attrs:{"label":"Left","component":_vm.c1},on:{"update:component":function($event){_vm.c1=$event}}}),_c(VSpacer),_c('ComponentAutocomplete',{attrs:{"label":"Right","component":_vm.c2},on:{"update:component":function($event){_vm.c2=$event}}})],1),_c(VToolbar,{attrs:{"flat":""}},[_c(VCheckbox,{attrs:{"label":"Show Meals"},model:{value:(_vm.showMeals),callback:function ($$v) {_vm.showMeals=$$v},expression:"showMeals"}})],1),_c(VRow,[_c(VCol,{staticStyle:{"overflow":"scroll","height":"800px"}},[(_vm.idC1)?_c('ComponentDetail',{attrs:{"component-id":_vm.idC1,"hide-meals":!_vm.showMeals,"is-admin":"","read-only":false,"normalise-to100g":""}}):_vm._e()],1),_c(VCol,{staticStyle:{"overflow":"scroll","height":"800px"}},[(_vm.idC2)?_c('ComponentDetail',{attrs:{"component-id":_vm.idC2,"hide-meals":!_vm.showMeals,"is-admin":"","read-only":false,"normalise-to100g":""}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }