<template>
  <v-container fluid class="mt-0 pt-0">
    <v-dialog
      v-model="showDatePicker"
      ref="dialog"
      width="290px"
      persistent
    >
      <v-date-picker
        v-model="datePickerDate"
        no-title
        @change="closeDatePicker"
      ></v-date-picker>
    </v-dialog>
    <v-toolbar flat pa-0>
      <v-menu>
        <template v-slot:activator="{ on,attrs }">
          <v-btn
            outlined
            color="primary"
            v-bind="attrs"
            v-on="on"
            :loading="loading"
          >
            Select Meal
          </v-btn>
        </template>
        <v-card min-width="800">
          <v-card-text>
            Note that meal count shown here for Low Carb (LC) includes the Diabetes number.
            On the plating page the count is just for LC meals.
          </v-card-text>
          <v-card-text>
            <v-row>
              <v-col v-for="date of dates" v-bind:key="`${date}-stream`">
                <v-list dense>
                  <v-list-item>
                    <v-list-item-title><h3>{{ dateFormatted(date) }}</h3></v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    dense
                    v-for="(meal, i) in mealsWithOrders[date]"
                    :key="i"
                    @click="selectMeal(meal.id,date)"
                  >
                    <v-list-item-content class="text-capitalize">
                      <div :style="isMealPlated(meal.id) ? 'text-decoration: line-through':''">
                        <span v-if="getMealRack(meal.id)" class="red--text font-weight-bold">{{
                            getMealRack(meal.id)
                          }} </span>
                        <b v-if="meal.production_priority">P{{ meal.production_priority }}</b>
                        {{ mealNameShort(meal) }}
                        {{ getMealCount(meal.id) }}CT
                        <v-icon fab color="green" v-if="isComplete(meal.id)">mdi-check</v-icon>
                        <template v-if="isAllergyComplete(meal.id)">
                          <v-chip small color="green">A
                            <v-icon fab>mdi-check</v-icon>
                          </v-chip>
                        </template>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-menu>
      <template v-if="totalPlatingTime">
        <v-spacer/>
        <v-btn color="success" class="ml-2" outlined @click="stopTask({complete:true})" v-if="task && taskStarted">
          Mark Done
        </v-btn>
        <v-spacer/>
        <v-btn color="warning" class="ml-2" outlined @click="stopTask" v-if="task && taskStarted">
          Pause Timer
        </v-btn>
        <v-btn class="ml-2" outlined @click="startTask" v-if="task && taskStopped">
          Start Timer
        </v-btn>
      </template>
      <v-spacer/>
      <ProductionSelector
        :dates.sync="dates"
        :production.sync="production"
      />
      <!--      <v-btn outlined @click="showDatePicker=true">-->
      <!--        <span v-if="dates && dates.length>0">-->
      <!--          {{ dates.map(d => dateFormatted(d, {formatString: 'ddd MMM DD'})).join(', ') }}-->
      <!--        </span>-->
      <!--        <span v-else>Choose Production Dates</span>-->
      <!--      </v-btn>-->
      <v-spacer/>
      <v-btn :outlined="!showRestrictions" color="warning" @click="showRestrictions=!showRestrictions">Restrictions
      </v-btn>
      <v-btn icon @click="SET_HIDE_TOP_NAV({hideTopNav:!hideTopNav})">
        <v-icon>mdi-fullscreen</v-icon>
      </v-btn>
    </v-toolbar>
    <v-row
      class=""
      :style="warningColor ? `border: 10px solid ${warningColor}` : ''"
    >
      <v-col :cols="12">
        <v-row dense class="">
          <v-col cols="4" v-if="meal">

            <p style="font-weight: bolder; font-size: 25px">
              <v-chip outlined v-if="meal.production_priority">P{{ meal.production_priority }}</v-chip>
              {{ meal.name }}
            </p>
            <p style="font-weight: bolder; font-size: 25px" class="text-capitalize ">
              {{ getDietName(meal.diet) }} | {{ getTimeOfDay(meal.tod) }} |
              {{ dateFormatted(meal.date, {formatString: 'ddd'}) }}</p>
            <v-img v-if="imageUrl && imageZoom" @click="imageZoom=false" v-bind:key="imageUrl"
                   style=""
                   :src="`${imageUrl.replace('/small','')}/rotate=90,width=500,trim=350;600;350;600`"/>
            <!--                  <img v-if="imageUrl && imageZoom" @click="imageZoom=false" v-bind:key="imageUrl"-->
            <!--                       :style="`width: 100%; height:100%; background: url(${imageUrl.replace('/small','')}/rotate=90); background-size: 150%;  background-position: center;`">-->
            <v-img @click="imageZoom=true" v-if="imageUrl && !imageZoom" :src="imageUrl"/>
          </v-col>
          <v-col cols="8" v-if="meal && !loading">
            <v-form v-if="!totalPlatingTime">
              <v-text-field
                label="How many people on the team?"
                v-model="teamCount"
                type="number"
              />
              <v-btn :disabled="!(teamCount>0)" @click="calculateTime">Calculate Time</v-btn>
            </v-form>
            <template v-if="totalPlatingTime">
              <Plating
                hide-header
                hide-controls
                read-only
                :meal-id="mealId"
                :production="production"
                :date="selectedDate"
                dense
                smaller
                :team-count="teamCount"
              />
            </template>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import Plating from "@/components/Plating";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import {dateFormatted} from "@/store/utils";
import urlState from "@/router/urlState";
import * as Sentry from "@sentry/vue";
import ProductionSelector from "@/components/ProductionSelector.vue";

export default {
  name: "PlatingTV",
  components: {ProductionSelector, Plating},
  mixins: [urlState],
  props: {
    date: {},
    initialHideNav: {}
  },
  data() {
    return {
      team: 'plating',
      team_members: [],
      meals: {},
      ccMeals: {},
      orders: {},
      mealId: null,
      meal: null,
      drawer: null,
      dates: [],
      showDatePicker: null,
      datePickerDate: null,
      showRestrictions: false,
      selectedDate: null,
      loading: false,
      imageZoom: true,
      teamCount: null,
      totalPlatingTime: null,
      task: null,
      tasks: [],
      taskStarted: false,
      taskStopped: false,
      coldWarningTimer: null,
      coldDangerTimer: null,
      warningColor: '',
      complete: {},
      componentTaskMap: {},
      platingTaskMap: {},
      platingTaskMealMap: {},
      allergyPlatingTaskMealMap: {},
      intervals: [],
      timers: [],
      production: undefined
    }
  },
  unmounted() {
    console.log('removing listeners/intervals');
    this.$socket.removeAllListeners();
    console.log('clearing timers');
    this.intervals.forEach(id => clearInterval(id));
    this.timers.forEach(id => clearTimeout(id));
    this.intervals = [];
    this.timers = [];
  },
  mounted() {
    this.syncToUrl({
      param: 'mealId', urlParam: 'meal', initFromRoute: true,
      // parseCallback: (v) => v === 'true'
    });
    this.syncToUrl({
      param: 'production', urlParam: 'production', initFromRoute: true,
      // parseCallback: (v) => v === 'true'
    });
    this.syncToUrl({
      param: 'showRestrictions', urlParam: 'restrictions', initFromRoute: true,
      parseCallback: (v) => v === 'true'
    });
    this.syncToUrl({
      param: 'teamCount', urlParam: 'team', initFromRoute: true,
      parseCallback: (v) => v ? Number(v) : null
    });
    this.syncToUrl({
      param: 'dates', urlParam: 'dates', initFromRoute: true,
      parseCallback: (v) => Array.isArray(v) ? v : [v]
    });

    if (this.initialHideNav) {
      this.SET_HIDE_TOP_NAV({hideTopNav: true});
    }
    this.$socket.on('task:started', (task) => {
      console.log('task started', task);
      this.startColdWarningTimers(task.elapsed);
      const index = this.tasks.findIndex(t => t.id === task.id);
      if (index > -1) {
        this.$set(this.tasks, index, task);
        this.task = task;
      } else {
        // console.warn('could not find started task', {task, tasks: this.tasks});
      }
      this.updateComponentTask(task);
      this.updatePlatingTask(task);
    });
    this.$socket.on('task:stopped', (task) => {
      console.log('task stopped', task);
      this.updateComponentTask(task);
      this.updatePlatingTask(task);
    });
    this.$socket.on('task:updated', (task) => {
      console.log('task updated', task);
      this.updateComponentTask(task);
      this.updatePlatingTask(task);
    });
    this.$socket.on('task:created', (task) => console.log('task created', task));
    this.$socket.emit('members:fetch',
      {team: this.team},
      (result) => {
        const {error, teamMembers} = result;
        if (error) {
          console.warn(`members:fetch failed: ${error}`);
        } else {
          console.log('plating members:fetch result', teamMembers);
          this.team_members = teamMembers;
        }
      }
    );
    this.loading = true;
    this.$nextTick(() => {
      if (this.date && this.production) {
        this.fetchData()
          .then(() => {
            this.loading = true;
            if (this.mealId) {
              return this.changeMeal(this.mealId);
            }
          })
          .then(() => {
            if (this.teamCount) {
              this.calculateTime();
              this.totalPlatingTime = true;
            }
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.loading = false;
      }
    })
  },
  computed: {
    ...mapState(['hideTopNav']),
    ...mapGetters(['getDietName', 'getTimeOfDay', 'getOrdersOnDate', 'getOrderSummaryForMeal']),    // meals(){
    imageUrl() {
      return this.meal && this.getMealImageUrl(this.meal);
    },
    mealsWithOrders() {
      const result = {};
      console.log('calc meals with orders', this.dates, this.meals, this.orders);
      for (const date of this.dates) {
        const mealsOnDate = this.meals[date];
        if (mealsOnDate && this.orders[date]) {
          result[date] = this.meals[date]
            .filter(m => this.orders[date].find(o => o.meal_id === m.id))
            .filter(({id}) => this.getMealCount(id) > 0);
          result[date] = this.sortMeals(result[date]);
        }
      }
      return result;
    }
  },
  watch: {
    dates: 'fetchData',
    production: 'fetchData',
    mealId(id) {
      this.changeMeal(id);
    }
  },
  methods: {
    ...mapActions(['fetchMeals', 'fetchDiets', 'fetchMeal', 'fetchOrdersByDate']),
    ...mapMutations(['SET_HIDE_TOP_NAV']),
    dateFormatted,
    sortMeals(meals) {
      meals.sort((m1, m2) => {
        const meal1Plated = this.isMealPlated(m1.id) && this.isAllergyComplete(m1.id);
        const meal2Plated = this.isMealPlated(m2.id) && this.isAllergyComplete(m2.id);
        if (meal1Plated && !meal2Plated) {
          return 1;
        }
        if (!meal1Plated && meal2Plated) {
          return -1;
        }

        const m1Racked = this.getMealRack(m1.id);
        const m2Racked = this.getMealRack(m2.id);
        if (m1Racked && !m2Racked) {
          return -1;
        }
        if (m2Racked && !m1Racked) {
          return 1;
        }
        const diff = this.getMealCount(m2.id) - this.getMealCount(m1.id);
        if (diff !== 0) {
          return diff;
        } else {
          return m1.name.localeCompare(m2.name);
        }
      });
      return meals;
    },
    changeMeal(id) {
      if (id) {
        if (this.meal && this.meal.id !== id) {
          // stop current task
          if (this.task) {
            this.stopTask({complete: true});
          }
        }
        return this.fetchMeal(id)
          .then(meal => {
            this.totalPlatingTime = false;
            this.meal = meal;
            this.selectedDate = meal.date;
          });
      } else {
        this.meal = null;
      }
    },
    startColdWarningTimers(elapsed = 0) {
      const coldWarningTime =
        (30 * 60 * 1000) // 30 mins
        // (1 * 60 * 1000) // 1 mins
        - (elapsed || 0); // minus any time already elapsed
      if (this.coldWarningTimer) {
        clearTimeout(this.coldWarningTimer);
      }
      if (coldWarningTime > elapsed) {
        this.coldWarningTimer = setTimeout(() => {
          this.warningColor = 'yellow';
          alert('COLD WARNING!\n30 minute food safe warning!  10 minutes of safe time left.');
        }, coldWarningTime);

        this.timers.push(this.coldWarningTimer);
      }

      const coldDangerTime =
        (40 * 60 * 1000) // 40 mins
        // (2 * 60 * 1000) // 1 mins
        - (elapsed || 0); // minus any time already elapsed
      if (this.coldDangerTimer) {
        clearTimeout(this.coldDangerTimer);
      }
      if (coldDangerTime > elapsed) {
        this.coldDangerTimer = setTimeout(() => {
          this.warningColor = 'red';
          alert('DANGER!\n40 minute food safe warning!');
        }, coldDangerTime);
        this.timers.push(this.coldDangerTimer);
      }


      this.warningColor = '';
      if (elapsed > coldWarningTime) {
        this.warningColor = 'yellow';
      }
      if (elapsed > coldDangerTime) {
        this.warningColor = 'red';
      }

      console.log('setting background', {elapsed, coldWarningTime, coldDangerTime, color: this.warningColor});
    },
    stopColdWarningTimers() {
      this.warningColor = '';
      if (this.coldWarningTimer) {
        clearTimeout(this.coldWarningTimer);
        this.coldWarningTimer = false;
      }
      if (this.coldDangerTimer) {
        clearTimeout(this.coldDangerTimer);
        this.coldDangerTimer = false;
      }
    },
    startTask() {
      const {id} = this.task;
      const team_members = this.team_members.filter(tm => tm.name === 'Main Line');
      console.log('task:starting - ', {id, team_members});
      this.$socket.emit('task:start', {id, team_members},
        (result) => {
          console.log('task:start - result', result);
          this.taskStarted = true;
          this.taskStopped = false;
          const {error} = result;
          if (error) {
            if (error === 'already executing task') {
              // stop and restart
              this.$socket.emit('task:stop', {id}, () => {
                this.startTask();
              });
            } else {
              console.warn('failed to start', result);
            }
            // alert(error);
          }
        });
    },
    stopTask({complete = false, note = `${this.teamCount} people`}) {
      const {id} = this.task;
      console.log('stop', 'task:stop', {id, complete, note});
      this.stopColdWarningTimers();
      this.$socket.emit('task:stop', {id, complete, note}, (result) => {
        console.log('task:stop - result', result);
        this.taskStarted = false;
        this.taskStopped = true;
      });
    },
    mealCountNoAllergy(mealId, date) {
      console.log('count', this.orders[date], mealId, date);
      return this.orders[date]
        .filter(o => o.meal_id == mealId && o.date === date)
        .filter(o => !o.allergyDetected)
        .reduce((sum, o) => sum + o.quantity, 0);
    },

    async getOrCreateTask(meal) {
      const count = this.mealCountNoAllergy(meal.id, this.selectedDate);
      const title = `M${meal.id} - ${meal.name} - T:${this.teamCount}, C:${count}`;
      const reference = `PLATING-M${meal.id}-${this.dates.join('-')}`;
      // lookup by reference first, then try title
      const existing = this.tasks.find(t => t.reference === reference) || this.tasks.find(t => t.title === title);
      if (existing) {
        console.log('found existing task', existing);
        return existing;
      } else {
        const task = {};
        task.from = this.date;
        task.to = this.date;
        task.team = this.team;
        task.title = title;
        task.reference = reference;
        task.description = `${this.teamCount} people, ${count} meals`;
        console.log('creating task', task);
        return new Promise((resolve, reject) => {
          this.$socket.emit('task:create', task, response => {
            console.log('create task response', response);
            if (response.id) {
              resolve(response);
            } else {
              console.warn('failed to create plating task: ' + JSON.stringify(response));
              reject(new Error('failed to create plating task'));
            }
          });
        });
      }
    },
    async fetchComponentTasks() {
      const dates = this.dates;
      this.incomplete = {};
      this.componentTaskMap = {};
      return new Promise(resolve => {
        const team = 'components';
        this.$socket.emit('tasks:fetch',
          {dates, team, productions: [this.production]},
          (result) => {
            const {error, tasks} = result;
            if (error) {
              alert(`fetch tasks ${dates} failed: ${error}`);
            } else {
              console.log(`tasks:fetch team=${team} - ${dates} result`, result);
              tasks.forEach(task => {
                const {id} = task;
                this.componentTaskMap[id] = {...(this.componentTaskMap[id] || {}), ...task};
                this.updateComponentTask(task);
              });
            }
            resolve();
          }
        );
      });
    },
    async fetchData() {
      if (this.dates.length === 0 && this.production) {
        console.log('dates and production not set yet', this.dates, this.production);
        return;
      }
      this.loading = true;
      this.totalPlatingTime = false;
      // this.dates = getProductionDays(this.date);
      this.meals = {};
      this.ccMeals = {};
      this.orders = {};
      this.mealId = null;
      return Promise
        .all([
          this.fetchDiets(),
          ...this.dates.map(date => this
            .fetchOrdersByDate({date: `${date}_${this.production}`})
            .then(orders => this.$set(this.orders, date, orders))),
          ...this.dates.map(date => this.fetchMeals(date).then(meals => this.$set(this.meals, date, meals))),
          this.fetchComponentTasks(),
          this.fetchPlatingTasks(),
        ])
        .then(async () => {
          // CC MEALS

          // function mealKey(m) {
          //   return `${m.tod} ${m.name}`;
          // }

          for (const date of this.dates) {
            console.log('loading orders on ' + date);
            const ccMealIds = {};
            this.getOrdersOnDate({date})
              .filter(o => o.order_type === 'chefs_choice')
              .forEach(o => {
                ccMealIds[o.meal_id] = ccMealIds[o.meal_id] || 0;
                ccMealIds[o.meal_id] += o.quantity
              });
            // console.log('ccmeals to fetch', ccMealIds);
            const meals = await Promise
              .all(Object
                .keys(ccMealIds)
                .map(mealId => this.fetchMeal(mealId))
              )

            // console.log('adding cc meals', meals);

            // meals.sort((m1, m2) => mealKey(m1).localeCompare(mealKey(m2)));
            this.$set(this.ccMeals, date, this.sortMeals(meals));
          }
        })
        .finally(() => {
          console.log('all done loading');
          this.loading = false;
        });
    },
    trunc(s, len) {
      return s.slice(0, len);
    },
    getMealImageUrl(meal) {
      if (meal.sku_images) {
        if (meal.sku_images.packaged) {
          return meal.sku_images.packaged.url;
        }
        if (meal.sku_images.plated) {
          return meal.sku_images.plated.url;
        }
        if (meal.sku_images.closeup) {
          return meal.sku_images.closeup.url;
        }
      }
    },
    closeDatePicker() {
      this.showDatePicker = false;
      this.meal = null;
      this.$nextTick(() => this.fetchData());
      this.$router.push({params: {date: this.datePickerDate}});
    },
    async calculateTime() {
      this.totalPlatingTime = true;
      this.task = await this.getOrCreateTask(this.meal);
      if (this.task) {
        this.startTask();
      }
    },
    selectMeal(mealId, date) {
      this.mealId = mealId;
      this.selectedDate = date;
      this.taskStarted = false;
      this.taskStopped = false;
    },
    isComplete(mealId) {
      if (this.complete[mealId]) {
        return Object.values(this.complete[mealId]).every(t => t);
      } else {
        return false;
      }
    },
    isAllergyComplete(mealId) {
      return this.platingTaskMealMap[mealId] && this.platingTaskMealMap[mealId].allergyComplete;
    },
    getMealRack(mealId) {
      return this.platingTaskMealMap[mealId] && this.platingTaskMealMap[mealId].rack;
    },
    getMealCount(mealId) {
      return this.platingTaskMealMap[mealId] && this.platingTaskMealMap[mealId].mealCount;
    },
    isMealPlated(mealId) {
      return this.platingTaskMealMap[mealId] && this.platingTaskMealMap[mealId].complete;
    },
    updatePlatingTask(task) {
      const {id} = task;
      if (!this.platingTaskMap[id]) {
        // console.log('skipping, not in map', task);
        return;
      }
      task = this.platingTaskMap[id] = {...this.platingTaskMap[id], ...task};
      if (task.source) {
        const mealId = task.source.mealId;
        if (task.source.allergy) {
          this.$set(this.allergyPlatingTaskMealMap, mealId, {...task.source, complete: task.complete});
        } else {
          this.$set(this.platingTaskMealMap, mealId, {...task.source, complete: task.complete});
        }
      } else {
        try {
          Sentry.setContext('task', task);
          Sentry.captureMessage('task did not have source')
        } catch (e) {
          console.warn('sentry capture failed', e);
        }
      }
      // console.log(`updating ${mealId} ac=${task.source.allergyComplete} r=${task.source.rack}`)
    },
    updateComponentTask(task) {
      const {id} = task;
      if (!this.componentTaskMap[id]) {
        // console.log('skipping, not in map', task);
        return;
      }
      task = this.componentTaskMap[id] = {...this.componentTaskMap[id], ...task};
      // console.log('updating task', task);
      const source = task.source;
      if (!source) {
        // console.log('no source, skipping', task);
        return;
      }
      const componentId = source.component && source.component.id;
      const componentName = source.component && source.component.name;
      if (componentName.match(/garnish/i)) {
        // console.log('skipping garnish', componentName);
        return;
      }
      const complete = task.complete;
      if (source && source.meals) {
        for (const meal of source.meals) {
          const {meal_id} = meal;
          this.$set(this.complete, meal_id, this.complete[meal_id] || {});
          this.$set(this.complete[meal_id], componentId, complete);
        }
      }
    },
    mealNameShort(meal) {
      const dietName = this.getDietName(meal.diet);

      function getInitials(str) {
        return str.split(' ').map(word => word[0]).join('');
      }

      let initials = getInitials(dietName).toUpperCase();
      if (initials === 'P') {
        initials = 'PB';
      }

      return `${initials}-` +
        `${this.trunc(this.getTimeOfDay(meal.tod), 1)}-` +
        `${dateFormatted(meal.date, {formatString: 'ddd'})}`
          .toUpperCase();
    },
    fetchPlatingTasks() {
      return new Promise(resolve => {
        const dates = this.dates;
        this.$socket.emit('tasks:fetch',
          {dates, team: this.team, productions: [this.production]},
          (result) => {
            const {error, tasks} = result;
            if (error) {
              console.warn(`fetch tasks ${dates} failed: ${error}`);
            } else {
              console.log(`tasks:fetch team=${this.team}- ${dates} result`, result);
              this.tasks = tasks;
              tasks.forEach(task => {
                const {id} = task;
                this.platingTaskMap[id] = {...(this.platingTaskMap[id] || {}), ...task};
                this.updatePlatingTask(task);
              });
            }
            resolve();
          }
        );
      });
    }
  }
}
</script>
