<template>
  <v-container  fluid>
    <v-toolbar flat>
      <ComponentAutocomplete
        label="Left"
        :component.sync="c1"
      />
      <v-spacer/>
      <ComponentAutocomplete
        label="Right"
        :component.sync="c2"
      />
    </v-toolbar>
    <v-toolbar flat>
      <v-checkbox
        label="Show Meals"
        v-model="showMeals"
      />


    </v-toolbar>
    <v-row>
      <v-col style="overflow: scroll; height: 800px">
        <ComponentDetail
          v-if="idC1"
          :component-id="idC1"
          :hide-meals="!showMeals"
          is-admin
          :read-only="false"
          normalise-to100g
        />
      </v-col>
      <v-col style="overflow: scroll; height: 800px">
        <ComponentDetail
          v-if="idC2"
          :component-id="idC2"
          :hide-meals="!showMeals"
          is-admin
          :read-only="false"
          normalise-to100g
        />

      </v-col>
    </v-row>
  </v-container>

</template>


<script>
import ComponentAutocomplete from "@/components/ComponentAutocomplete.vue";
import ComponentDetail from "@/components/ComponentDetail.vue";
import urlState from "@/router/urlState";
import {mapActions} from "vuex";

export default {
  name: "ComponentComparison",
  components: {ComponentDetail, ComponentAutocomplete},
  mixins: [urlState],
  data() {
    return {
      c1: null,
      c2: null,
      idC1: null,
      idC2: null,
      showMeals: null,
    }
  },
  watch: {
    c1(v) {
      this.idC1 = v && v.id;
    },
    c2(v) {
      this.idC2 = v && v.id;
    }
  },
  mounted() {
    this.syncToUrl({
      param: 'idC1', urlParam: 'c1', initFromRoute: true,
      parseCallback: (v) => v ? Number(v) : v
    });
    this.syncToUrl({
      param: 'idC2', urlParam: 'c2', initFromRoute: true,
      parseCallback: (v) => v ? Number(v) : v
    });
    this.syncToUrl({
      param: 'showMeals', urlParam: 'show_meals', initFromRoute: true,
      parseCallback: (v) => v && v === 'true'
    });
    this.$nextTick(async () => {
      if (this.idC1) {
        this.c1 = await this.fetchComponent(this.idC1);
      }
      if (this.idC2) {
        this.c2 = await this.fetchComponent(this.idC2);
      }

    });
  },
  methods: {
    ...mapActions(['fetchComponent'])
  }
}
</script>


<style scoped>

</style>