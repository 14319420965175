<template>
  <v-card flat>
    <v-card-text>
      <v-card>
        <v-card-title>{{ container.id ? 'Edit' : 'Add' }} Container</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                label="Name"
                type="text"
                v-model="container.name"
              />
            </v-col>
            <v-col>
              <v-text-field
                label="Description"
                type="text"
                v-model="container.description"
              />
            </v-col>
            <v-col>
              <v-text-field
                label="Weight (in grams)"
                type="number"
                min=0
                v-model="container.weight"
              />
            </v-col>
            <v-col>
              <v-btn
                @click="saveContainer(container)"
                outlined
              >
                Save
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mt-4" v-for="container of containers" v-bind:key="container.id">
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                label="Name"
                type="text"
                v-model="container.name"
              />
            </v-col>
            <v-col>
              <v-text-field
                label="Description"
                type="text"
                v-model="container.description"
              />
            </v-col>
            <v-col>
              <v-text-field
                label="Weight (in grams)"
                type="number"
                min=0
                v-model="container.weight"
              />
            </v-col>
            <v-col>
              <v-btn
                @click="saveContainer(container)"
                outlined
              >
                Save
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import api from "@/api";
import {mapActions, mapState} from "vuex";
import {formatWeightWithUnits} from "@/store/utils";

export default {
  name: "Container",
  data() {
    return {
      container: {},
    }
  },
  mounted() {
    return this.fetchContainers();
  },
  computed: {
    ...mapState(['containers'])
  },
  methods: {
    ...mapActions(['fetchContainers']),
    formatWeightWithUnits,
    saveContainer(container) {
      this.loading = true;
      const payload = container;
      payload.weight = Number(payload.weight);
      api.post('v2/container', payload)
        .then(() => {
          this.container = {};
          return this.fetchContainers({force: true});
        });
    }
  }
}
</script>

<style scoped>

</style>