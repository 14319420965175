import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VToolbar,{staticClass:"pb-10",attrs:{"flat":""}},[_c(VTextField,{staticClass:"d-print-none",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c(VSpacer),_c(VBtnToggle,{model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},_vm._l((_vm.teamFilter),function(team){return _c(VBtn,{key:team,attrs:{"value":("team:" + team)}},[_vm._v(_vm._s(team)+" ")])}),1),_c(VSpacer),_c(VTextField,{attrs:{"value":_vm.datesFormatted,"persistent-hint":"","hint":"Delivery Date","label":"Select dates","single-line":"","readonly":"","append-icon":"mdi-calendar"},on:{"click":function($event){_vm.datePickerDate=_vm.dates[0]; _vm.showDatePicker=true}}}),_c(VDialog,{ref:"dialog",attrs:{"width":"290px","persistent":""},model:{value:(_vm.showDatePicker),callback:function ($$v) {_vm.showDatePicker=$$v},expression:"showDatePicker"}},[_c(VDatePicker,{attrs:{"no-title":""},on:{"close":_vm.closeDatePicker},model:{value:(_vm.datePickerDate),callback:function ($$v) {_vm.datePickerDate=$$v},expression:"datePickerDate"}}),_c(VBtn,{on:{"click":_vm.closeDatePicker}},[_vm._v("Close")])],1)],1),(_vm.hasDates)?_c('TaskPanel',{attrs:{"team":_vm.team,"dates":_vm.getDatesInRange(_vm.dateFrom,_vm.dateTo),"search":_vm.search,"filter":_vm.filter},scopedSlots:_vm._u([{key:"task-title",fn:function(ref){
var task = ref.props.task;
return [_c(VCardTitle,[_vm._v(" "+_vm._s(task.title)+" "),_c(VSpacer),(task.complete)?_c(VIcon,[_vm._v("mdi-check")]):_vm._e()],1)]}},{key:"task-text",fn:function(ref){return [_c('div')]}}],null,false,1507835312)}):_vm._e(),(!_vm.hasDates)?_c(VAlert,[_vm._v(" Please select a production date ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }