import moment from 'moment';
import {calculateSummary, ensureNumber, getStartOfWeek} from './utils';

// const sizeScaleMap = {
//   small: 0.75,
//   medium: 1,
//   large: 1.25
// };


const UNKNOWN_DIET = {
  name: 'Unsorted',
  id: null,
  stream: ''
};


// eslint-disable-next-line no-unused-vars
function flattenOrders(orders, startOfWeek) {
  startOfWeek = moment(startOfWeek);
  const summary = {};
  if (orders) {
    for (const order of Object.values(orders)) {
      for (const day of Object.keys(order.meals)) {
        const {stream, meal_size, meals} = order;
        for (const meal of meals[day]) {
          let {tod, order_type, quantity} = meal;
          if (order_type === 'full') {
            order_type = stream;
          }
          summary[day] = summary[day] || {};
          summary[day][tod] = summary[day][tod] || {};
          summary[day][tod][order_type] = summary[day][tod][order_type] || {};
          summary[day][tod][order_type][meal_size] = summary[day][tod][order_type][meal_size] || [];
          summary[day][tod][order_type][meal_size].push({...order, ...{tod, order_type, quantity}});

          // const sizeScale = sizeScaleMap[meal_size];
          // summary[day][tod][order_type].total = summary[day][tod][order_type].total || 0;
          // summary[day][tod][order_type].total += (quantity * sizeScale);
        }
      }
    }
  } else {
    console.log('summary - no orders');
  }
  Object.keys(summary).forEach(day => {
    const date = startOfWeek.day(day).format('YYYY-MM-DD');
    summary[date] = summary[day];
    delete summary[day];
  })
  console.warn('using deprecated summary, fix me', new Error('deprecated'));
  return summary;
}


function computeSummary(orders, startOfWeek) {
  startOfWeek = moment(startOfWeek);
  const summary = {};
  if (orders) {
    for (const order of Object.values(orders)) {
      for (const day of Object.keys(order.meals)) {
        const {stream, meal_size, meals} = order;
        for (const meal of meals[day]) {
          let {tod, order_type, quantity} = meal;
          if (order_type === 'full') {
            order_type = stream;
          }
          summary[day] = summary[day] || {};
          summary[day][tod] = summary[day][tod] || {};
          summary[day][tod][order_type] = summary[day][tod][order_type] || {};
          summary[day][tod][order_type][meal_size] = summary[day][tod][order_type][meal_size] || 0;
          summary[day][tod][order_type][meal_size] += quantity;

          // const sizeScale = sizeScaleMap[meal_size];
          // summary[day][tod][order_type].total = summary[day][tod][order_type].total || 0;
          // summary[day][tod][order_type].total += (quantity * sizeScale);
        }
      }
    }
  } else {
    console.log('summary - no orders');
  }
  Object.keys(summary).forEach(day => {
    const date = startOfWeek.day(day).format('YYYY-MM-DD');
    summary[date] = summary[day];
    delete summary[day];
  })
  return summary;
}


export default {
  //orders: state => state.orders,
  getCurrentUser: state => state.user,
  getCurrentRole: state => state.user && state.user.role,
  getCurrentUserToken: state => state.token,
  date: state => state.date,
  meals: state => state.meals,
  diets: state => state.diets,
  components: state => state.components,
  isMealLoaded: state => (mealId) => {
    return !!state.meal[mealId];
  },
  getMeal: state => (mealId) => {
    const meal = state.meal[mealId];
    if (!meal) {
      console.warn('could not find meal', mealId);
    }
    return meal;
  },
  getMealNameAndDiet: state => (mealId) => state.mealNameAndDiet[mealId],
  getSubMeals: state => (mealId) => state.submeals[mealId],
  getParentMealId: state => (mealId) => state.parentMeals[mealId],
  getUser: state => (uid) => state.users[uid],
  meal: state => (mealId) => {
    console.log('deprecate use', new Error());
    return state.meal[mealId]
  },
  getMealIngredients: state => (mealId) => state.ingredients[mealId],
  ingredients: state => (mealId) => {
    console.log('deprecate use getMealIngredients instead', new Error());
    return state.ingredients[mealId];
  },
  getCookedAmounts: (state, getters) => (mealId, {excludeIgnoredPrepIngredients = false}) => {
    const mealIngredients = getters.getMealIngredients(mealId);
    if (!mealIngredients) {
      console.warn('meal ingredients not loaded', mealId);
      return [];
    }
    let ingredients = mealIngredients;
    if (excludeIgnoredPrepIngredients) {
      ingredients = ingredients.filter(i => !i.ignore_prep_weight);
    }
    // JSON.parse(JSON.stringify(mealIngredients));
    // console.log('ingredients', {mealId, ingredients});
    // ingredients.sort((a, b) => a.id < b.id);
    // console.log('ingredients cookedAmounts', {mealId, amounts: ingredients.map(i => i.cookedAmount).map(ensureNumber)});
    return ingredients.map(i => ensureNumber(i.cookedAmount));
  },
  getCookedAmountTotal: (state, getters) =>
    (mealId, {excludeIgnoredPrepIngredients = false} = {}) => getters
      .getCookedAmounts(mealId, {excludeIgnoredPrepIngredients})
      .reduce((amount, sum) => ensureNumber(amount) + sum, 0),
  getMealIngredient: state => ({mealid, foodid, component_id}) => {
    // TODO: index by food_id
    const mealIngredients = state.ingredients[mealid];
    if (mealIngredients) {
      const ingredient = mealIngredients.find(i => (i.foodid === foodid) && (i.component_id === component_id));
      return ingredient;
    } else {
      console.log('did not find meal ingredient', {mealid, foodid, component_id});
      return false;
    }
  },
  getOrder: state => (orderId) => state.ordersById[orderId],
  getOrders: state => (date) => state.orders[getStartOfWeek(date)],
  getOrdersForMeal: state => ({
                                date,
                                mealId,
                                production,
                              }) => {
    if (!production){
      throw new Error('production not specified');
    }
    return Object.values(state.ordersById).filter(o => o.date === date && o.meal_id == mealId && o.production === production)
  },
  getOrderSummaryForMeal: (state, getters) => ({date, mealId, production}) => {
    const orders = getters.getOrdersForMeal({date, mealId, production});
    return calculateSummary(orders);
  },
  getTotalOrderQuantityOnDate: (state, getters) => ({date}) =>
    getters.getOrdersOnDate({date}).reduce((sum, i) => sum + i.quantity, 0),
  getOrdersOnDate: state => ({date}) => {
    // console.log(`state.getOrdersOnDate looking for ${date}`, Object.values(state.ordersById));
    return Object.values(state.ordersById).filter(o => o.date === date)
  },
  getExportStatus: state => mealId => state.exportStatus[mealId],
  getRestrictionText: state => restrictionId => {
    const restriction = state.restrictions && state.restrictions[restrictionId];
    if (restriction) {
      return restriction.text;
    } else {
      console.log('warning : unknown restriction', restrictionId);
      return restrictionId;
    }
  },
  getSubstitutionText: state => (key) => {
    if (state.restrictions) {
      return (state.restrictions[key] && state.restrictions[key].text) || key;
    } else {
      console.warn('getSubstitutionText: restrictions not loaded yet', {key});
      return key;
    }
  },
  isChefsChoice(order) {
    const isChefsChoice = order.order_type === 'chefs_choice';
    return isChefsChoice;
  },
  getRestrictedTags: state => restrictionNames => {
    const result = {};
    restrictionNames.forEach(name => {
      const restriction = state.restrictions[name];
      if (restriction) {
        (restriction.restrictedTags || []).forEach(tagId => {
          result[tagId] = result[tagId] || [];
          result[tagId].push(restriction.text);
        })
      } else {
        console.log('unknown restriction', name);
      }
    })
    return result;
  },
  summary: (state, getters) => (date) => {
    if (!date) {
      console.log('no date passed', date);
      return;
    }
    const startOfWeek = getStartOfWeek(date);
    if (!state.summary[startOfWeek]) {
      state.summary[startOfWeek] = computeSummary(getters.getOrders(date), startOfWeek);
    }
    return state.summary[startOfWeek][date] || {};
  },
  getSummaryForDiet: (state, getters) => (date, dietId, todNumber) => {
    console.log('getSummaryForDiet', {date, dietId, todNumber});
    const tod = getters.getTimeOfDay(todNumber);
    const stream = getters.getDietStream(dietId);
    const summary = getters.summary(date);
    const summaryByTod = summary[tod] || {};
    return {small: 0, medium: 0, large: 0, total: 0, ...(summaryByTod[stream] || {})};

  },
  getDietIdFromStream: (state) => (stream) => {
    if (state.diets === null) {
      console.warn('diets not loaded');
      return UNKNOWN_DIET;
    }

    const diet = Object.values(state.diets || {}).find(d => d.stream === stream);
    if (!diet) {
      return null;
    }
    return diet.id;
  },
  getDiet: (state) => (dietId) => {
    if (state.diets === null) {
      console.warn('diets not loaded');
      return UNKNOWN_DIET;
    }

    const diet = state.diets[dietId];
    if (!diet) {
      return UNKNOWN_DIET;
    }
    return diet;
  },
  getMealsByDiet: (state, getters) => (date) => {
    const result = {};
    console.log('this.meals[this.date]', [state.meals, date]);
    if (!state.meals[date]) {
      console.warn('state.meals[date] not loaded for ', date)
    } else {
      state.meals[date].forEach(meal => {
        const diet = getters.getDiet(meal.diet);
        const id = diet.id;
        result[id] = result[id] || [];
        result[id].push(meal);
      });
    }
    return Object.keys(result)
      .map(id => ({id, meals: result[id]}));
  },
  getComponent: (state) => (componentId) => {
    return state.components[componentId];
  },
  getComponentTags: (state) => () => {
    return state.componentTags;
  },
  getComponentName: (state, getters) => (componentId) => {
    const component = getters.getComponent(componentId);
    return (component && component.name) || 'General ingredients';
  },
  getDietName: (state, getters) => (dietId) => {
    // console.log('getDietName', {dietId, name: getters.getDiet(dietId).name});
    return getters.getDiet(dietId).name;
  },
  getDietColor: () => (dietId) => {
    const colorMap = {
      1: 'blue',
      14: 'purple',
      16: 'orange',
      18: 'green',
      17: 'yellow',
    }
    return colorMap[dietId] || 'black';
  },
  getDietColorInverted: () => (dietId) => {
    const colorMap = {
      1: 'white',
      14: 'white',
      16: 'black',
      18: 'white',
      17: 'black',
    }
    return colorMap[dietId] || 'white';
  },
  getDietStream: (state, getters) => (dietId) => {
    return getters.getDiet(dietId).stream;
  },
  getTimeOfDayNumber: (state) => (name) => {
    const tod = state.timesOfDay.find(t => t.name === name);
    return tod && tod.id;
  },
  getStreamDiet: (state) => (streamId) => {
    return Object.values(state.diets || {}).find(d => d.stream === streamId);
  },
  getStreams: (state) => () => {
    return state.streams;
  },
  getStreamName: (state) => (id) => {
    return state.streams && state.streams[id] && state.streams[id].name;
  },
  getCustomers: (state) => () => {
    return state.customers;
  },
  getCustomer: (state) => (uid) => {
    return state.customers && state.customers[uid];
  },
  getCustomerName: (state) => (uid) => {
    const customer = state.customers && state.customers[uid];
    return customer ? `${customer.first_name} ${customer.last_name}` : uid;
  },
  getFeedback: (state) => () => {
    return state.feedback;
  },
  getStream: (state) => (id) => {
    return state.streams[id];
  },

  getTimeOfDay: () => (todNumber) => {
    const todMap = {
      1: 'breakfast',
      2: 'lunch',
      3: 'dinner',
      4: 'extras',
      'breakfast': 1,
      'lunch': 2,
      'dinner': 3,
      'extras': 4
    }
    if (todMap[todNumber]) {
      return todMap[todNumber];
    } else {
      return todMap[4];
    }
  },
  isRestrictionDetected: (state) => (meal, ingredient) => {
    const isDetected = (
      state.mealRestrictions &&
      state.mealRestrictions[meal.id] &&
      state.mealRestrictions[meal.id].violations[ingredient.food_id]
    ) || false;
    // console.log('isRestrictionDetected', {isDetected, meal, ingredient});
    return isDetected;
  },
  isRestrictionTypeDetected: (state) => (mealId, restrictionId) => {
    const isDetected = (
      state.mealRestrictions &&
      state.mealRestrictions[mealId] &&
      state.mealRestrictions[mealId].violationByType[restrictionId]
    ) || false;
    return isDetected;
  },
};
