<template>
  <v-container class="meals mt-0 pt-0" fluid>
    <v-dialog v-if="showMeal" v-model="showImageDialog" fullscreen>
      <v-card>
        <v-card-actions>
          <v-spacer/>
          {{ showMeal.name }}
          <v-spacer/>
          <v-btn text @click="showImageDialog=false">Close</v-btn>
        </v-card-actions>
        <v-card-text>
          <v-carousel height="100%">
            <v-carousel-item
              v-for="(image,i) of showMeal.sku_images" v-bind:key="i">
              <v-img contain
                     height="80%"
                     :src="image.url"/>
            </v-carousel-item>
          </v-carousel>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-container v-if="!isDateAndProductionSet" fluid class="justify-center text-center fill-height flex-column">
      <v-alert type="info">
        Please select production group and date
      </v-alert>

    </v-container>
    <template v-if="isDateAndProductionSet">
      <v-toolbar class="d-print-none" flat>
        <v-toolbar-title>
          <span v-if="!loading">{{ totalCount }} meals ordered </span>
        </v-toolbar-title>
        <v-btn
          text outlined small
          class="ml-2"
          @click="checkExportStatus"
          :loading="exportStatusLoading"
        >Export Status
        </v-btn>

        <v-spacer/>

        <v-btn
          text outlined
          :to="{ query : { view: multiview?undefined:'multiview' }}"
          :class="multiview?'info':'default'"
        >Multiview
        </v-btn>
        <v-btn
          text outlined
          :to="{ query : { view: showInstructions?undefined:'showInstructions' }}"
          :class="showInstructions?'info':'default'"
          class="ml-2"
        >Customer Prep Instructions
        </v-btn>
        <v-btn
          text outlined
          :to="{ query : { view: showSummary?undefined:'showSummary' }}"
          :class="showSummary?'info':'default'"
          class="ml-2"
        >Summary
        </v-btn>
      </v-toolbar>

      <v-card v-if="loading">
        <v-card-title justify="center">Loading</v-card-title>
      </v-card>

      <v-card v-if="!loading" flat>
        <v-list v-if="mealsByDiet.length===0">
          <v-list-item>
            <v-list-item-title>No meals available on this day</v-list-item-title>
          </v-list-item>
        </v-list>

        <v-list>
          <div
            v-for="(mealsInDiet,index) of mealsByDiet"
            v-bind:key="index"
            style="page-break-after: always;"
          >
            <v-alert v-model="multiview" color="success" text dense>select the meals you would like to show together
            </v-alert>
            <v-toolbar flat class="d-print-none" v-if="multiview">
              <v-spacer></v-spacer>
              <v-btn
                :to="selectedMeals.length>0 ? { name: 'MealDetailMulti', params: { selection : selectedMeals.join(',')}} :{}"
                :disabled="selectedMeals.length===0"
              >
                Show Selected Meals
              </v-btn>
              <v-btn
                class="ml-2"
                :to="selectedMeals.length>0 ? { name: 'MealNutrientsMulti', params: { selection : selectedMealIds.join(',')}} :{}"
                :disabled="selectedMeals.length===0"
              >
                Show Nutrient Analysis
              </v-btn>
            </v-toolbar>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title align="center">
                  <h3>
                    {{ mealsInDiet.count }} CT -
                    {{ mealsInDiet.name }} - {{ dateFormatted(date) }}
                  </h3>
                </v-list-item-title>
                <v-row>
                  <v-col align="right">
                    <v-btn class="d-print-none" small text outlined
                           :to="{ name: 'MealNutrientsMulti',
                        params: { selection : mealsInDiet.meals.map(m=>m.id).join(',')}
                  }">Nutrients (all {{ mealsInDiet.name }})
                    </v-btn>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="ordersWithErrorsByStream[mealsInDiet.streamId]">
              <v-list-item-content>
                <v-alert color="warning" text outlined>
                  <v-icon>mdi-alert</v-icon>
                  Unable to load information for {{ ordersWithErrorsByStream[mealsInDiet.streamId].length }}
                  orders. The totals below are missing these orders.
                  <v-btn x-small
                         text outlined
                         @click="$set(showErrorOrders,index,!showErrorOrders[index])"
                  >
                    Show Details
                  </v-btn>
                  <v-spacer/>

                </v-alert>
              </v-list-item-content>

              <v-dialog v-model="showErrorOrders[index]" max-width="1200px">
                <v-card>
                  <v-card-title>
                    There are problems with {{ ordersWithErrorsByStream[mealsInDiet.streamId].length }} orders in the
                    stream "{{ mealsInDiet.stream.name }}"
                  </v-card-title>
                  <v-simple-table>
                    <thead>
                    <tr>
                      <th v-for="column of errorColumns" v-bind:key="column">{{ column }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="order of ordersWithErrorsByStream[mealsInDiet.streamId]"
                        v-bind:key="order.id">
                      <td v-for="column of errorColumns" v-bind:key="column">{{ order[column] }}</td>
                    </tr>
                    </tbody>
                  </v-simple-table>
                </v-card>
              </v-dialog>
            </v-list-item>
            <v-list-item
              v-for="(meal,i) of mealsInDiet.meals"
              v-bind:key="i"
            >
              <v-list-item-icon v-if="getMealImageUrl(meal)" class="d-print-none">
                <v-img @click="showImageDialog=true; showMeal=meal" max-width="200"
                       :src="getMealImageUrl(meal)"/>
              </v-list-item-icon>
              <v-list-item-content>

                <v-list-item-title>
                  <h4> {{ orderSummary(meal.id).total }} CT - {{ meal.name }} ({{ getTimeOfDay(meal.tod) }})</h4>
                </v-list-item-title>
                <v-list-item-subtitle v-if="mainView">
                <span v-for="s of formatSummary(meal.id)" v-bind:key="s">
                  {{ s }}
                </span>
                </v-list-item-subtitle>

                <span v-if="showSummary" class="mt-2">
                <MealRestrictionSummary :meal="meal" :orders="orders.filter(o=>o.meal_id===meal.id)"/>
              </span>
                <InstructionEditor class="mt-4" v-if="showInstructions" :meal="meal" :read-only="readOnly"/>

              </v-list-item-content>
              <v-list-item-action>
                <v-checkbox v-if="multiview"
                            v-model="multiviewMealSelected[meal.id]"
                            :value="meal.id"
                ></v-checkbox>
                <v-toolbar flat v-if="mainView">
                  <v-btn text outlined class="mr-4" :to="{ name: 'MealDetail', params: { id : meal.id.toString()}}">
                    View
                  </v-btn>
                  <v-btn text outlined class="mr-4"
                         :to="{ name: 'MealDetail', params: { id : meal.id.toString()}, query: { plating: '1'}}">
                    Plating
                  </v-btn>
                  <v-btn text outlined class="mr-4"
                         :to="{ name: 'MealDetail', params: { id : meal.id.toString()}, query: { prep: '1'}}">Prep
                  </v-btn>
                  <v-btn text outlined class="mr-4"
                         :to="{ name: 'MealPrepWork', params: { id : meal.id.toString()}, query: { }}">Assign
                  </v-btn>
                  <v-btn text outlined class="mr-4"
                         :to="{ name: 'MealNutrientsMulti', params: { selection : meal.id.toString()}}">Nutr.
                  </v-btn>
                  <v-btn text outlined :disabled="readOnly" v-if="showExportStatus && exportStatus[meal.id]"
                         @click="exportTheMeal(meal)" :loading="isExporting">
                    {{ exportStatus[meal.id].isExported ? 're-export' : 'export' }}
                    {{ meal.id }}{{ meal.isChefsChoice && 'CC' }}
                  </v-btn>
                  <v-chip color="red" x-small
                          v-if="exportStatus[meal.id] && exportStatus[meal.id].matches >1">
                    {{ `${exportStatus[meal.id].matches}` }}
                  </v-chip>
                </v-toolbar>
              </v-list-item-action>

            </v-list-item>
          </div>
        </v-list>
      </v-card>
    </template>
  </v-container>

</template>


<script>
import moment from 'moment';
import {mapActions, mapGetters} from 'vuex'
import InstructionEditor from '@/components/InstructionEditor';
import {dateFormatted, extractDateFromDateProduction, extractProductionFromDateProduction} from '@/store/utils';
import MealRestrictionSummary from '@/components/MealRestrictionSummary';

import urlState from "@/router/urlState";

export default {
  components: {
    MealRestrictionSummary,
    InstructionEditor
  },
  mixins: [urlState],
  data() {
    return {
      readOnly: true,
      loading: false,
      loadingPromise: undefined,
      post: null,
      error: null,
      multiviewMealSelected: {},
      view: '',
      mealIds: [],
      showErrorOrders: [],
      errorColumns: 'email,stream,tod,quantity,error'.split(','),
      exportStatus: {},
      showExportStatus: false,
      exportStatusLoading: false,
      isExporting: false,
      showMeal: null,
      showImageDialog: false,
    }
  },
  async created() {
    this.fetchData();
    this.readOnly = !!this.$route.meta.readOnly;
    await Promise.all([
      this.fetchStreams(),
      this.fetchRestrictions()
    ]);
  },
  mounted() {
    this.view = this.$route.query.view;

  },
  watch: {
// call again the method if the route changes
    '$route.params.date': 'fetchData',
    '$route.query.view'(val) {
      this.view = val;
    }
  },
  computed: {
    ...mapGetters([
      'meals',
      'diets',
      'getDietName',
      'getDietStream',
      'getStream',
      'getMeal',
      'getTimeOfDay',
      'getOrdersForMeal',
      'getOrdersOnDate',
      'getOrderSummaryForMeal',
      'getTotalOrderQuantityOnDate',
      'getRestrictionText',
      'getExportStatus',
      'getCurrentRole'
    ]),
    isDateAndProductionSet() {
      return this.date && this.production;
    },
    orders() {
      return this.getOrdersOnDate({date: this.date});
    },
    ordersWithErrorsByStream() {
      const result = {};
      this.orders
        .filter(m => m.error)
        .forEach(o => {
          result[o.stream] = result[o.stream] || [];
          result[o.stream].push(o);
        });
      return result;
    },
    mainView() {
      // default view (nothing selected)
      return !this.view;
    },
    showSummary() {
      return this.view === 'showSummary';
    },
    multiview() {
      return this.view === 'multiview';
    },
    showInstructions() {
      return this.view === 'showInstructions';
    },
    totalCount() {
      return this.getTotalOrderQuantityOnDate({date: this.date});
    },
    mealsOnDate() {
      return this.mealIds.map(id => this.getMeal(id));
    },
    mealsByDiet() {
      const result = {};
      if (this.loading) {
        return [];
      }

      Object.values(this.diets).forEach(diet => {
        result[diet.id] = result[diet.id] || [];
      });
      this.mealsOnDate.forEach(meal => {
        let diet;
        if (this.chefsChoiceIds.indexOf(meal.id) > -1) {
          diet = 'chefs_choice';
          meal.isChefsChoice = true;
        } else {
          diet = meal.diet;
        }
        result[diet] = result[diet] || [];
        result[diet].push(meal);
      });
      const mealsByDiet = Object
        .keys(result)
        .map(diet => ({
          id: diet,
          name: (diet === 'chefs_choice' ? "Chef's Choice" : this.getDietName(diet)),
          streamId: this.getDietStream(diet),
          stream: (diet === 'chefs_choice' ? {} : this.getStream(this.getDietStream(diet))),
          meals: result[diet].sort((a, b) => {
            if (a.tod !== b.tod) {
              return a.tod - b.tod
            } else {
              return a.name.localeCompare(b.name);
            }
          }),
          count: (result[diet].map(meal => this.orderSummary(meal.id).total)).reduce((a, i) => a + i, 0)
        }));

      return mealsByDiet.filter(m => m.meals.length > 0 || this.ordersWithErrorsByStream[m.streamId]);
    },
    dateProductionKey() {
      return this.$route.params.date;
    },
    production() {
      return extractProductionFromDateProduction(this.$route.params.date);
    },
    date: {
      get() {
        return extractDateFromDateProduction(this.$route.params.date || '');
      },
      set(val) {
        const newDate = moment(val).format('YYYY-MM-DD');
        const newLocation = {name: this.$route.name, params: {date: newDate}};
        // console.log('new ', newLocation)
        this.$router.push(newLocation);
      }
    },
    selectedMeals() {
      console.log('selectedMeals : this.multiviewMealSelected', this.multiviewMealSelected);
      return Object
        .keys(this.multiviewMealSelected)
        .filter(dietAndTod => !!this.multiviewMealSelected[dietAndTod]);
    },
    selectedMealIds() {
      console.log('selectedMealIds : this.multiviewMealSelected', this.multiviewMealSelected);
      return Object
        .values(this.multiviewMealSelected)
        .filter(id => !!id);
    },
  },
  methods: {
    ...mapActions([
      'fetchDiets',
      'fetchMeal',
      'fetchMeals',
      'fetchOrdersByDate',
      'fetchRestrictions',
      'fetchStreams',
      'fetchExportStatus',
      'exportMeal'
    ]),
    async fetchMealAndUpdateMealIds(id, missingMeals) {
      if (!id) {
        console.warn('failed to fetch meal (id is undefined!) ' + id);
        missingMeals[id] = 0;
      } else {
        return this
          .fetchMeal(id)
          .then(() => this.mealIds.push(id)) // only add id if meal was loaded correctly!
          .catch(e => {
            console.warn('failed to fetch meal ' + id, e.message);
            missingMeals[id] = 0;
          });
      }
    },
    async fetchMealDetails(orders) {
      //console.log('fetch meal details', {orders});
      const orderMealIds = [...new Set(orders.map(o => o.meal_id))];
      const chefsChoiceIds = [...new Set(orders.filter(o => o.stream === 'chefs_choice').map(o => o.meal_id))];
      const missingMeals = {};
      await Promise.all(
        orderMealIds.map(id => this.fetchMealAndUpdateMealIds(id, missingMeals))
      );
      // count up orders for missing meals
      orders.forEach(o => {
        if (missingMeals[o.meal_id] !== undefined) {
          missingMeals[o.meal_id] += o.quantity;
        }
      });
      console.log('missing meal order count', missingMeals);
      return {
        // filter out any meals that were not loaded correctly
        chefsChoiceIds: chefsChoiceIds.filter(id => this.mealIds.indexOf(id) > -1),
      };
    },

    fetchData() {
      console.log('fetching', this.date);
      return this.fetchDataOnDate(this.date);
    },
    fetchDataOnDate(date) {
      this.error = this.post = null
      if (date !== this.date) {
        console.warn('stale fetch ', [date, this.date]);
        return;
      }
      if (!(this.date && this.production)) {
        console.log('date and production need to be set');
        // this.loading = false;
        return;
      }
      if (this.loading) {
        console.log('already loading, wait before loading ', date);
        return this.loadingPromise.then(() => this.fetchDataOnDate(date));
      }
      this.loading = true

      // reset the meal ids shown
      this.mealIds = [];
      // this.$set(this, 'mealIds', []);
      this.loadingPromise = Promise.all([
        this.fetchOrdersByDate({date: this.dateProductionKey})
          .then(this.fetchMealDetails)
          .then(({chefsChoiceIds}) => {
            this.chefsChoiceIds = chefsChoiceIds;
          })
          .then(() => this.fetchMeals(this.date))
          .then((meals) => {
            const missingMeals = {};
            const mealIdsWithNoOrders = meals.map(m => m.id).filter(id => !this.mealIds.includes(id));
            console.log('mealIdsWithNoOrders', mealIdsWithNoOrders);
            return Promise.all(
              mealIdsWithNoOrders.map(id => this.fetchMealAndUpdateMealIds(id, missingMeals))
            );
          })
          .then(() =>
            Promise.all([
              // ...this.mealIds.map(id => this.fetchExportStatus(id)),
              // ...this.chefsChoiceIds.map(id => this.fetchExportStatus(`${id}CC`))
            ])
          )
        ,
        this.fetchDiets()]
      )
        .then(() => this.loading = false);
    },
    meal_sizes() {
      return 'small,medium,large'.split(',');
    },
    orderSummary(mealId) {
      return this.getOrderSummaryForMeal({date: this.date, mealId: mealId, production: this.production});
    },
    formatSummary(mealId) {
      const s = this.orderSummary(mealId);
      return s && ['small:' + s.small, 'medium:' + s.medium, 'large:' + s.large];
    },
    addCommaIfNeeded(str, i, length) {
      if (i < length - 1) {
        return str + ',';
      } else {
        return str;
      }
    },
    isRestrictionDetected(meal, ingredient) {
      const isDetected = (
        this.mealRestrictions &&
        this.mealRestrictions[meal.id] &&
        this.mealRestrictions[meal.id].violations[ingredient.food_id]
      ) || false;
      // console.log('isRestrictionDetected', {isDetected, meal, ingredient});
      return isDetected;
    },
    isRestrictionTypeDetected(mealId, restrictionId) {
      const isDetected = (
        this.mealRestrictions &&
        this.mealRestrictions[mealId] &&
        this.mealRestrictions[mealId].violationByType[restrictionId]
      ) || false;
      return isDetected;
    },
    lowerCase(s) {
      return s && s.toLowerCase();
    },
    getSizeTitle(stream, size) {
      // console.log('getSizeTitle', {stream, size});
      try {
        return stream.sizes[size].kitchen_description;
      } catch (e) {
        return size.toUpperCase();
      }
    },
    dateFormatted,
    isRole(role) {
      return this.getCurrentRole === role;
    },
    async checkExportStatus({ids}) {
      this.exportStatusLoading = true;
      const checkExportStatus = meal => {
        let s;
        if (meal.isChefsChoice) {
          s = this.getExportStatus(`${meal.id}CC`);
        } else {
          s = this.getExportStatus(meal.id);
        }
        this.exportStatus[meal.id] = s;
        if (s) {
          if (!s.isExported) {
            console.log('export status : ' + (s.isExported ? 'exported' : 'not exported'), {mealId: meal.id, s});
          }
        } else {
          console.warn('no export status for', meal.id);
        }

      };

      console.log('checking export status');
      const promises = [];
      if (ids) {
        for (const id of ids) {
          promises.push(this.fetchExportStatus(id));
        }
      } else {
        for (const id of this.mealIds) {
          promises.push(this.fetchExportStatus(id));
        }
        for (const id of this.chefsChoiceIds) {
          promises.push(this.fetchExportStatus(`${id}CC`));
        }
      }
      await Promise.all(promises);


      this.mealsOnDate.map(checkExportStatus);

      this.showExportStatus = true;
      this.exportStatusLoading = false;
    },
    async exportTheMeal(meal) {
      this.isExporting = true;
      if (meal.isChefsChoice) {
        await this.exportMeal(`${meal.id}CC`)
          .then(() => this.checkExportStatus({ids: [`${meal.id}CC`]}));
      } else {
        await this.exportMeal(meal.id)
          .then(() => this.checkExportStatus({ids: [meal.id]}));
      }
      this.isExporting = false;
    },
    getMealImageUrl(meal) {
      if (meal.sku_images) {
        if (meal.sku_images.closeup) {
          return meal.sku_images.closeup.url;
        }
        if (meal.sku_images.plated) {
          return meal.sku_images.plated.url;
        }
        if (meal.sku_images.packaged) {
          return meal.sku_images.packaged.url;
        }
      }
    }
  }
}
</script>
