import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import socket from './socket';
import vuetify from './plugins/vuetify';

import * as Sentry from "@sentry/vue";
import {checkVersion} from "@/check-version";

// import {Integrations} from "@sentry/tracing";

const isDevelopment = process.env.NODE_ENV === 'development';
const sentryEnv = process.env.VUE_APP_SENTRY_ENVIRONMENT || 'development';

const SENTRY_DSN = process.env.VUE_APP_SENTRY_DSN;
if (!SENTRY_DSN) {
  console.warn('no sentry config');
} else {
  const sentryOptions = {
    Vue,
    dsn: SENTRY_DSN,
    environment: sentryEnv,
    release: process.env.VUE_APP_RELEASE,
    // integrations: [new Integrations.BrowserTracing()],
    //
    // // Set tracesSampleRate to 1.0 to capture 100%
    // // of transactions for performance monitoring.
    // // We recommend adjusting this value in production
    // tracesSampleRate: 1.0,
  };
  Sentry.init(sentryOptions);
  if (isDevelopment) {
    console.log('sentry option', sentryOptions)
  }
}

if (isDevelopment) {
  // no sentry means it is likely dev environtment
  Vue.config.errorHandler = function (err, vm, info) {
    // handle error
    // `info` is a Vue-specific error info, e.g. which lifecycle hook
    // the error was found in. Only available in 2.2.0+
    console.error('errorHandler: ' + info, err);
  }
  Vue.config.warnHandler = function (err, vm, info) {
    // handle error
    // `info` is a Vue-specific error info, e.g. which lifecycle hook
    // the error was found in. Only available in 2.2.0+
    console.error('warnHandler: ' + info, err);
  }
}
Vue.config.productionTip = false

// make socket accessible to components
Vue.prototype.$socket = socket;

try {
  let user = localStorage.getItem('user');
  if (user && localStorage.getItem('token')) {
    user = JSON.parse(user);
    console.log('hello again', user);
  }
} catch (e) {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
} finally {
  new Vue({
    store,
    router,
    vuetify,
    render: h => h(App)
  })
    .$mount('#app')
}


checkVersion({
  checkInterval: 600000,
  hostname: 'k.fedfedfed.com',
  srcRoot: 'https://k.fedfedfed.com/js/app.',
  // debug: true
});


