<template>
  <v-container fluid class="">
    <v-toolbar flat class="d-print-none">
      <ProductionSelector
        :dates="dates" :production="production"
        v-on:update:dates="dates=$event"
        v-on:update:production="production=$event"
      />
      <v-spacer/>
      <v-select
        label="Streams/Diets"
        v-model="selectedDiets"
        item-text="name"
        item-value="id"
        :items="activeDiets"
        single-line
        hide-details
        multiple
      />
      <v-checkbox
        single-line
        hide-details
        label="Show Restrictions"
        v-model="showRestriction"
      />
    </v-toolbar>
    <!--    {{  {loaded, loadedCount, 'filteredMeals.length': filteredMeals.length, isLoaded} }}-->
    <v-alert v-if="!loaded" type="info" class="d-print-none">
      <span v-if="loadingOrders">
        Please wait while the orders are loaded.
      </span>
      <span v-if="!loadingOrders">
        Please wait while the meals are loaded {{ loadedCount }} of {{ filteredMeals.length }}
      </span>
    </v-alert>
    <v-container
      fluid class="page-break full-page-portrait ma-0 pa-0 "
      v-for="({id,date,restrictions, ordersForMeal}) of platingPages"
      v-bind:key="`${id}-${restrictions?'a':''}`"
    >
      <!--
       v-if="restrictions?isLoaded[id]:true"
       NOTE: this delays loading rendering the allergy version until the non-allergy version is loaded
       because rendering both at the same time causes a vue infinite update loop.  :'(

           -->
      <Plating
        class=""
        :date="date"
        :dates="dates"
        :production="production"
        :orders-in="ordersForMeal"
        :meal-id="id"
        hideControls
        read-only
        :restrictions="restrictions"
        skip-url-sync
        v-on:is-loading="$set(isLoaded,id,!$event)"
      />
      <!--      -->
    </v-container>
  </v-container>
</template>


<script>
import moment from "moment/moment";
import {getProductionDays} from "@/store/utils";
import api from "@/api";
import Plating from "@/components/Plating.vue";
import urlState from "@/router/urlState";
import {mapActions, mapGetters, mapState} from "vuex";
import ProductionSelector from "@/components/ProductionSelector.vue";
import diff from "microdiff";

export default {
  name: "Platings",
  components: {ProductionSelector, Plating},
  mixins: [urlState],
  async mounted() {
    this.syncToUrl({
      param: 'dates', urlParam: 'dates', initFromRoute: true,
      parseCallback: (v) => Array.isArray(v) ? v : [v]
    });
    this.syncToUrl({
      param: 'production', urlParam: 'production', initFromRoute: true,
    });
    this.syncToUrl({
      param: 'selectedDiets', urlParam: 'diets', initFromRoute: true,
      parseCallback: (v) => (Array.isArray(v) ? v : [v]).map(i => Number(i))
    });
    this.$nextTick(() => this.fetchData());
    await this.fetchDiets();
  },
  methods: {
    ...mapActions(['fetchDiets', 'fetchMealIngredients', 'fetchMeal']),
    closeDatePicker() {
      this.showDatePicker = false;
      this.$nextTick(() => this.fetchData());
    },
    async fetchData() {
      this.meals = [];
      this.orders = [];
      this.hasAllergyDetected = {};
      this.isLoaded = {};
      if (this.dateFrom && this.dateTo) {
        this.loadingOrders = true;
        console.log('fetching meal ids in range', this.dateFrom, this.dateTo);
        const {data} = await api.get('v2/meal/ids', {params: {from: this.dateFrom, to: this.dateTo}});
        console.log('got ', data);

        const {dates, production} = this;
        if (!dates || dates.length === 0) {
          console.log('no dates, no fetch');
          this.loading = false;
          return;
        }
        if (!production) {
          console.log('no production, no fetch');
          this.loading = false;
          return;
        }

        if (this.currentData && diff(this.currentData, {dates, production}).length === 0) {
          console.log('already loaded', this.currentData);
          return
        } else {
          console.log('fetching prep', {dates, production});
          this.currentData = {dates, production};
        }

        const params = {
          from: this.dateFrom,
          to: this.dateTo,
          productions: [this.production],
          // properties: ['quantity', 'allergyDetected', 'allergies', 'meal_id', 'stream', 'tod', 'meal_size'],
        };
        console.log('fetching ', params);
        const orders = await api
          .get('v2/order/search', {params})
          .then(({data}) => data);
        this.ordersFetched = orders;

        console.log('orders', orders);
        this.orders = orders;
        orders.forEach(o => {
          if (o.allergyDetected) {
            this.hasAllergyDetected[o.meal_id] = true
          }
        });
        const orderedMealIds = new Set(orders.map(o => o.meal_id));
        console.log('orderedMealIds', orderedMealIds);

        // let meals = data.filter(({id}) => orderedMealIds.has(id));
        // meals = await Promise.all(meals.map(({id}) => this.fetchMeal(id)));
        const meals = await Promise.all(
          [...orderedMealIds]
            .filter(id => !!id)
            .map(id => this.fetchMeal(id)));

        const key = m => `${m.date}-${m.diet}-${m.tod}`;
        const mealSort = (a, b) => key(a).localeCompare(key(b));
        meals.sort(mealSort);

        // await Promise.all(meals.map(({id}) => this.fetchMealIngredients({id})));
        this.activeDiets = data.map(d => this.diets[d.diet]);
        this.activeDiets.sort((a, b) => a.id - b.id);
        this.meals = meals;
        console.log('filtered meals', this.meals);
        this.$nextTick(() => this.loadingOrders = false);
      } else {
        console.log('no dates selected');
      }
    },
  },
  data() {
    return {
      showDatePicker: null,
      datePickerDate: null,
      dates: [],
      meals: [],
      showRestriction: false,
      isLoaded: {},
      selectedDiets: [],
      activeDiets: [],
      orders: [],
      hasAllergyDetected: {},
      loadingOrders: null,
      production: null,
      currentData: null
    }
  },
  watch: {
    dates: 'fetchData',
    production: 'fetchData',
    showDatePicker(v) {
      if (v) {
        if (this.isAdmin) {
          this.datePickerDate = this.dates;
        } else {
          this.datePickerDate = this.dates[0];
        }
      } else {
        if (this.isAdmin) {
          this.dates = this.datePickerDate;
        } else {
          this.dates = getProductionDays(this.datePickerDate)
        }
      }
    },
  },
  computed: {
    ...mapState(['diets']),
    ...mapGetters(['getDietName']),
    loadedCount() {
      return Object.values(this.isLoaded).filter(s => s).length;
    },
    loaded() {
      return !this.loadingOrders && this.loadedCount >= Object.values(this.filteredMeals).length;
    },
    datesFormatted() {
      const format = 'dddd MMMM D';
      if (!this.dateFrom) {
        return '';
      } else if (this.dateFrom === this.dateTo) {
        return `${moment(this.dateFrom).format(format)}`;
      } else {
        return `${moment(this.dateFrom).format(format)} - ${moment(this.dateTo).format(format)}`
      }
    },
    dateFrom() {
      return [...this.dates].sort()[0];
    },
    dateTo() {
      return [...this.dates].sort().reverse()[0];
    },
    filteredMeals() {
      if (this.selectedDiets && this.selectedDiets.length > 0) {
        return this.meals.filter(m => this.selectedDiets.includes(m.diet));
      } else {
        return this.meals;
      }
    },
    platingPages() {
      let platingPages = [];
      if (!this.showRestriction) {
        this.filteredMeals.forEach(m => {
          platingPages.push(m);
        })
      } else {
        this.filteredMeals.forEach(m => {
          if (this.hasAllergyDetected[m.id]) {
            platingPages.push({...m, restrictions: true});
          }
        })
      }


      // handle CC meals
      const orders = this.orders;
      const ccOrders = orders.filter(o => o.order_type === 'chefs_choice');
      const ccOrderMap = {};
      ccOrders.forEach(o => {
        ccOrderMap[o.meal_id] = ccOrderMap[o.meal_id] || [];
        ccOrderMap[o.meal_id].push(o);
      })
      console.log('ccOrderMap', ccOrderMap);
      platingPages = platingPages.map(m => {
        if (ccOrderMap[m.id]) {
          const date = ccOrderMap[m.id][0].date;
          return {
            ...m,
            ordersForMeal: ccOrderMap[m.id],
            date
          }
        } else {
          return m;
        }
      })

      return platingPages; //.slice(0, 2);
    }
  }
}
</script>

<style scoped>
.page-break {
  page-break-after: always;
}
</style>