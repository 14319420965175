import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VToolbar,{staticClass:"d-print-none",attrs:{"flat":""}},[_c('ProductionSelector',{attrs:{"dates":_vm.dates,"production":_vm.production},on:{"update:dates":function($event){_vm.dates=$event},"update:production":function($event){_vm.production=$event}}}),_c(VSpacer),_c(VSelect,{attrs:{"label":"Streams/Diets","item-text":"name","item-value":"id","items":_vm.activeDiets,"single-line":"","hide-details":"","multiple":""},model:{value:(_vm.selectedDiets),callback:function ($$v) {_vm.selectedDiets=$$v},expression:"selectedDiets"}}),_c(VCheckbox,{attrs:{"single-line":"","hide-details":"","label":"Show Restrictions"},model:{value:(_vm.showRestriction),callback:function ($$v) {_vm.showRestriction=$$v},expression:"showRestriction"}})],1),(!_vm.loaded)?_c(VAlert,{staticClass:"d-print-none",attrs:{"type":"info"}},[(_vm.loadingOrders)?_c('span',[_vm._v(" Please wait while the orders are loaded. ")]):_vm._e(),(!_vm.loadingOrders)?_c('span',[_vm._v(" Please wait while the meals are loaded "+_vm._s(_vm.loadedCount)+" of "+_vm._s(_vm.filteredMeals.length)+" ")]):_vm._e()]):_vm._e(),_vm._l((_vm.platingPages),function(ref){
var id = ref.id;
var date = ref.date;
var restrictions = ref.restrictions;
var ordersForMeal = ref.ordersForMeal;
return _c(VContainer,{key:(id + "-" + (restrictions?'a':'')),staticClass:"page-break full-page-portrait ma-0 pa-0",attrs:{"fluid":""}},[_c('Plating',{attrs:{"date":date,"dates":_vm.dates,"production":_vm.production,"orders-in":ordersForMeal,"meal-id":id,"hideControls":"","read-only":"","restrictions":restrictions,"skip-url-sync":""},on:{"is-loading":function($event){return _vm.$set(_vm.isLoaded,id,!$event)}}})],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }