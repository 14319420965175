<template>
  <v-card flat>
    <v-dialog v-model="showReplaceIngredient" width="600px">
      <v-card>
        <v-card-text>
          <IngredientAutocomplete
            @updateValue="ingredientToReplace=$event"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn @click="showReplaceIngredient=false">Cancel</v-btn>
          <v-spacer/>
          <v-btn v-if="isAdmin" @click="setReplaceIngredient(ingredientToReplace); showReplaceIngredient=false">
            Select
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showAddIngredient" width="600px">
      <v-card>
        <v-card-text>
          <IngredientAutocomplete
            @updateValue="ingredientToAdd=$event"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn @click="showAddIngredient=false">Cancel</v-btn>
          <v-spacer/>
          <v-btn v-if="isAdmin" @click="addIngredient(ingredientToAdd); showAddIngredient=false">Add Ingredient</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card-title>Meal Ingredient Modifier
      <v-spacer/>
      <v-btn @click="clear">Clear/Reset</v-btn>
    </v-card-title>
    <v-card-text>
      This page lets you replace an ingredient (or ingredients) with other ingredients.
    </v-card-text>
    <v-card-text>
      <v-row>
        <v-col>
          <v-card-title>Ingredient to replace
            <v-spacer/>
            <v-btn small outlined @click="showReplaceIngredient=true">Select Ingredient</v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                {{ ingredientToReplace.fooddescriptionsimple }} {{ ingredientToReplace.foodid }}
                <v-icon color="blue" v-if="ingredientToReplace.verified">mdi-shield-check</v-icon>
              </v-col>
              <v-col>

              </v-col>
            </v-row>
          </v-card-text>
        </v-col>
        <v-col>
          <v-card-title>What to replace it with
            <v-spacer/>
            <v-btn v-if="isAdmin && !readOnly" small outlined @click="showAddIngredient=true">Add Ingredient</v-btn>
          </v-card-title>
          <v-card-text>
            <p class="caption" v-if="ingredientToReplace">
              The ingredient "{{ ingredientToReplace.fooddescriptionsimple }}" will be replaced by the ingredients
              below. The replaced total amount will be equal to
              the
              original ingredient amount
              (ie, the amounts below define the ratio of ingredients)
            </p>
            <MealDetailTable
              :read-only="readOnly"
              subtitle=""
              :ingredients="ingredients"
              :show-prep-controls="false"
              :show-prep-status="false"
              :save-ingredient-assignment="saveIngredientAssignment"
              :on-update-ingredient="onUpdateIngredient"
              :show-nutrient-controls="false"
            />
          </v-card-text>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-title>
      Preview Changes
    </v-card-title>
    <v-card-text>
      There are {{ totalMealCount }} meals containing this ingredient.
      Of which {{ meals.length }} meals are in the future.
      {{ futureComponentIds.length }} components are involved in these future meals.
      <br/>
      <br/>
      <v-row>
        <v-col>
          <v-alert type="warning" v-if="mealWarning">{{mealWarning}}</v-alert>
          <h2>Meals</h2>
          <ul>
            <li v-for="m of mealFoodWithIngredient" v-bind:key="m.meal.id">
              {{ formatDate(m.meal.date) }} {{ m.meal.name }} <strong>{{ getStream(m.meal.id) }}</strong>
              <br/>{{ getComponent(m.component_id).name }} C{{ m.component_id }}
            </li>
          </ul>
        </v-col>
        <v-col class="">
          <h2>Components</h2>
          <v-alert type="warning" v-if="componentWarning">{{componentWarning}}</v-alert>
          <v-row dense v-for="c of components" v-bind:key="c.id">
            <v-col cols="1" class="pa-0 ma-0" align-self="center">
              <v-checkbox
                dense
                v-model="selectComponent[c.id]"
                hide-details
              />
            </v-col>
            <v-col class="pa-0 ma-0" align-self="center">
              <router-link :to="{name:'ComponentDetail',params:{id:c.id}}">
                {{ c.name }} C{{ c.id }}
              </router-link>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

    </v-card-text>
  </v-card>

</template>

<style scoped>

</style>

<script>
import IngredientAutocomplete from "@/components/IngredientAutocomplete.vue";
import urlState from "@/router/urlState";
import api from "@/api";
import MealDetailTable from "@/components/MealDetailTable.vue";
import {convertIngredient, formatDate} from "@/store/utils";
import moment from "moment";
import {mapActions, mapState} from "vuex";


export default {
  name: "MealIngredientModifier",
  components: {MealDetailTable, IngredientAutocomplete},
  mixins: [urlState],
  data() {
    return {
      meals: [],
      foodid: undefined,
      ingredientToReplace: {},
      ingredients: [],
      showAddIngredient: null,
      ingredientToAdd: null,
      showReplaceIngredient: null,
      components: [],
      mealFoodWithIngredient: [],
      totalMealCount: null,
      mealMap: {},
      selectComponent: {},
      componentWarning: null,
      mealWarning: null
    }
  },
  async mounted() {
    this.syncToUrl({
      param: 'foodid', urlParam: 'id', initFromRoute: true,
      parseCallback: (v) => v && Number(v)
    });
    this.syncToUrl({
      param: 'ingredients', urlParam: 'ingredients', initFromRoute: true,
      parseCallback: (v) => v && JSON.parse(v),
      transformCallback: (v) => v && v.length > 0 ? JSON.stringify(v) : ''
    });
    this.fetchDiets();
  },
  watch: {
    ingredientToReplace(v) {
      if (v && v.foodid) {
        this.foodid = v.foodid;
      }
    },
    async foodid(v) {
      if (v) {
        if (this.ingredientToReplace && this.ingredientToReplace.foodid !== v) {
          this.ingredientToReplace = await this.fetchFoodId(v);
        }
        return this.generatePreview();
      }
    }
  },
  computed: {
    ...mapState(['diets']),
    futureComponentIds() {
      return [...new Set(this.mealFoodWithIngredient.map(m => m.component_id))];
    },
  },
  methods: {
    ...mapActions(['fetchDiets']),
    formatDate,
    async fetchFoodId(id) {
      const result = await api.get('v2/food/' + id).then(({data}) => data);
      console.log('fetched ' + id, result);
      return result;
    },
    saveIngredientAssignment() {
      console.log('saveIngredientAssignment', arguments);
    },
    async addIngredient(ingredientToAdd) {
      console.log('addIngredient', ingredientToAdd);
      const i = convertIngredient(await this.fetchFoodId(ingredientToAdd.foodid));
      this.ingredients.push({...i, cookedAmount: 100});
    },
    onUpdateIngredient() {
      console.log('onUpdateIngredient', arguments);
    },
    async setReplaceIngredient(ingredientToReplace) {
      this.ingredientToReplace = await this.fetchFoodId(ingredientToReplace.foodid);
    },
    clear() {
      this.foodid = undefined;
      this.ingredientToReplace = {};
      this.ingredients = [];
      // this.$set(this, 'ingredientToReplace', {});
      // this.$set(this, 'ingredients', []);
    },
    async generatePreview() {
      const result = await api.get('v2/food/', {params: {where: {foodid: this.ingredientToReplace.foodid}}}).then(({data}) => data);
      console.log('result', result);
      const [food] = result.rows;
      const now = moment();
      this.totalMealCount = food.meal_food.length;
      this.mealFoodWithIngredient = food.meal_food.filter(m => now.isBefore(m.meal.date))
      await this.fetchMeals(this.mealFoodWithIngredient.map(mf => mf.meal.id));
      const componentIds = this.futureComponentIds;
      await this.fetchComponents(componentIds);
    },
    async fetchMeals(mealIds) {
      const result = await api.get('v2/meal', {params: {where: {id: {in: mealIds}}}});
      const {count, meals} = result.data;
      if (count > meals.length) {
        alert(`only ${meals.length} of ${count} meals are shown`);
        this.mealWarning = `only ${meals.length} of ${count} meals are shown`;
      }else{
        this.mealWarning = undefined;
      }
      console.log('fetched full meal detail', meals);
      const dateSort = (a, b) => a.date.localeCompare(b.date);
      meals.forEach((meal) => {
        meal.stream = this.diets[meal.diet].stream;
      });
      this.meals = meals.sort(dateSort);
      this.mealMap = Object.fromEntries(meals.map(m => ([m.id, m])));
    },
    async fetchComponents(componentIds) {
      const componentResult = await api.get('v2/component/', {
        params:
          {
            where: {id: {in: componentIds}},
            limit: 1000
          },
      });
      const {total, rows} = componentResult.data;
      if (total > rows.length) {
        alert(`Too many components (${total} count), only ${rows.length} are shown`);
        this.componentWarning = `Too many components (${total} count), only ${rows.length} are shown`;
      }else{
        this.componentWarning = undefined;
      }
      console.log('componentResult.data', rows);
      this.components = rows;
    },
    getComponent(id) {
      return this.components.find(c => c.id === id) || {};
    },
    getStream(mealId) {
      const meal = this.mealMap[mealId];
      return meal && meal.stream
    }
  },
  props: {
    isAdmin: {type: Boolean, default: true, required: false},
    readOnly: {type: Boolean, default: false, required: false}
  }
}
</script>


