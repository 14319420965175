import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"flat":""}},[_c(VCardTitle,[_vm._v("Driver Confirmation for "+_vm._s(_vm.date)+" "),_c(VSpacer),_c(VChip,{staticClass:"mr-2",attrs:{"outlined":"","color":"green"}},[_vm._v(_vm._s(_vm.count(_vm.status, 'confirmed'))+" Confirmed")]),_c(VChip,{staticClass:"mr-2",attrs:{"outlined":"","color":"red"}},[_vm._v(_vm._s(_vm.count(_vm.status, 'rejected'))+" Rejected")]),_c(VChip,{staticClass:"mr-2",attrs:{"outlined":"","color":"orange"}},[_vm._v(_vm._s(_vm.count(_vm.status, 'sent'))+" Waiting for Reply")]),_c(VChip,{staticClass:"mr-2",attrs:{"outlined":"","color":"black"}},[_vm._v(_vm._s(_vm.count(_vm.status, 'new'))+" No Message Sent")])],1),_c(VCardText,[_vm._v(" Confirmation messages are sent at 7am to all drivers who are assigned deliveries today. If a driver is added after 7am, they will not receive any confirmation text. "),_c(VRow,{attrs:{"dense":""}},_vm._l((_vm.driverStatuses),function(ref){
var phone = ref.phone;
var rest = objectWithoutProperties( ref, ["phone"] );
var driverStatus = rest;
return _c(VCol,{key:phone,attrs:{"cols":"6","md":"3"}},[_c(VCard,[_c(VCardText,[_c(VChip,{attrs:{"small":"","outlined":"","color":_vm.getColor(driverStatus.status)}},[_vm._v(_vm._s(driverStatus.status))]),_vm._v(" "+_vm._s(driverStatus.name)+" "),_c('a',{attrs:{"href":("tel:" + phone)}},[_vm._v(_vm._s(phone))]),_c('br'),_vm._v(" first eta "+_vm._s(driverStatus.firstEta)+" - last eta "+_vm._s(driverStatus.lastEta)+" | "+_vm._s(driverStatus.deliveryCount)+" deliveries "),_c('ul',_vm._l((driverStatus.messages||[]),function(ref,i){
var time = ref.time;
var text = ref.text;
return _c('li',{key:i},[_vm._v(" "+_vm._s(_vm.dateFormatted(time, {formatString: 'LLL'}))+" : "+_vm._s(text)+" ")])}),0)],1)],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }