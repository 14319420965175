<template>
  <keep-alive>
    <v-container fluid>
      <!--      {{ {pendingEdits} }}-->
      <!--      {{ { edits }  }}-->
      <v-dialog v-model="showConfirmSave" width="600px">
        <v-card>
          <v-card-title>Confirm Changes</v-card-title>
          <v-card-text>
            <v-row v-for="(edit,i) of edits" v-bind:key="i">
              <v-col>
                {{ edit.name }}
              </v-col>
              <v-col>
                <p v-if="edit.tod !== undefined && edit.original_tod !== edit.tod"> {{ edit.original_tod }} => {{
                    edit.tod ? edit.tod : ' CLEAR'
                  }}</p>
                <p v-if="edit.date !== undefined && edit.original_date !== edit.date">
                  {{ dateFormatted(edit.original_date) }} &rightarrowtail;
                  {{ edit.date ? dateFormatted(edit.date) : ' CLEAR' }}</p>
                <p v-if="edit.production_priority">
                  Priority {{ edit.production_priority }}
                </p>
              </v-col>
            </v-row>

          </v-card-text>
          <v-card-actions>
            <v-btn @click="pendingEdits={}; showConfirmSave=false" color="warning" outlined>Cancel</v-btn>
            <v-spacer/>
            <v-btn :loading="saving" @click="save" outlined color="primary">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-toolbar flat>
        <v-btn
          icon
          class="ma-2"
          @click="prev"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-toolbar-title style="min-width: 400px" class="text-center">
          {{ dateFormatted(start) }}
          <template v-if="start!==end">- {{ dateFormatted(end) }}</template>
        </v-toolbar-title>
        <v-btn
          icon
          class="ma-2"
          @click="next"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
        <v-spacer/>
        <v-btn-toggle v-model="view" mandatory>
          <v-btn small value="calendar">Calendar</v-btn>
          <v-btn small value="meal_view">By Meal</v-btn>
          <v-btn small value="stream_view">By Stream</v-btn>
        </v-btn-toggle>
      </v-toolbar>
      <v-toolbar flat>
        <v-select
          v-if="!loading"
          label="Filter by diet"
          v-model="selectedDiet"
          :items="diets"
          item-value="id"
          item-text="name"
          clearable
          dense
        />
        <v-spacer/>
        <v-select
          class="ml-2"
          dense
          v-model="calendarType"
          :items="calendarTypes"
        />

        <template v-if="!readOnly">
          <v-spacer/>
          <v-btn @click="showConfirmSave=true" outlined :disabled="pendingEditCount===0">
            save ({{ pendingEditCount }} edits)
          </v-btn>
        </template>
      </v-toolbar>
      <v-row v-if="view==='calendar'">
        <v-col>
          <v-calendar
            v-if="!loading"
            :start="toTimeStamp(start)"
            :end="toTimeStamp(end)"
            v-model="focus"
            ref="calendar"
            :events="meals"
            :type="calendarType"
            :event-height="!!this.selectedDiet ? 70 : 20"
            class="meal-calendar"
            event-overlap-mode="column"
            :event-text-color="(e) => this.getEventColor(e.meal.diet)"
            @change="updateDateRange"
            :categories="categories"
            :category-show-all="true"
            interval-count="0"
            :event-more="true"
            @click:event="eventClicked"
            @click:more="showMore"
            weekdays="0, 1, 2, 3, 4, 5, 6, 7"
          >
            <template v-slot:event="{ event} ">
              <span v-if="selectedDiet" style="font-size: smaller; white-space: normal; overflow: visible;">
                {{ event.name }}
              </span>
              <span v-else>{{ event.name }}</span>
            </template>
          </v-calendar>
        </v-col>
      </v-row>
      <v-card flat v-if="view==='meal_view'">
        <v-card-title>Meal View</v-card-title>
        <v-card-actions>
          <v-checkbox
            :loading="loading"
            v-model="showCost"
            label="Show Meal Cost"/>
        </v-card-actions>
        <v-card-text>
          <v-row>
            <v-col cols="2">Date</v-col>
            <v-col v-if="showCost" cols="1">Cost</v-col>
            <v-col cols="5">Meal</v-col>
            <v-col>Streams</v-col>
            <v-col>Edit</v-col>
          </v-row>
          <v-row dense v-for="(meal,index) of mealsFiltered" v-bind:key="index">
            <v-col cols="2">
              {{ dateFormatted(meal.date) }}
              <template v-if="meal.tod">
                <br/>
                {{ getTimeOfDay(meal.tod) }}
              </template>
            </v-col>
            <v-col v-if="showCost" cols="1">
              <v-progress-circular width="2" v-if="loading" indeterminate/>
              <span v-if="meal.cost" class="text-right">{{ formatCurrency(meal.cost) }}</span>
            </v-col>

            <v-col cols="5">
              <router-link :to="{ name: 'MealDetail', params: { id: meal.id, date: formatDate(meal.date)}}">
                {{ meal.id }}
              </router-link> &nbsp;

              {{ meal.name }}
            </v-col>
            <v-col cols="2">
              <v-chip v-if="meal.diet" text-color="white" small :color="getDietColor(meal.diet)">
                {{ getDietName(meal.diet) }}
              </v-chip>
            </v-col>
            <v-col cols="1">
              <!--              <v-btn>Move</v-btn>-->
            </v-col>
            <v-col cols="3" align-self="end">
              <v-alert
                style="width: 250px"
                class="d-print-none pa-1" rounded type="info" outlined
                v-if="ratings[meal.id] && feedback[meal.id] && feedback[meal.id].length>1">
                <span class="caption">                expand feedback by clicking on it                  </span>
              </v-alert>
            </v-col>
            <v-col cols="5" class="ma-0 pa-0" v-if="ratings[meal.id]">
              <template v-if="feedback[meal.id] && feedback[meal.id].length===1">
                <v-row dense class="" v-for="(f,i) of feedback[meal.id]" v-bind:key="`${meal.id}-${i}`" no-gutters>
                  <v-col class="ma-0 pa-0">
                    <v-chip-group>
                      <v-chip small v-for="property of ratingProperties"
                              v-bind:key="property"
                              :color="getRatingColor(f[property])">
                        {{ property }} {{ f[property] }}
                      </v-chip>
                      <v-chip small outlined> {{ dateFormatted(f.date, {formatString: 'LL'}) }}</v-chip>
                    </v-chip-group>
                  </v-col>
                  <v-col cols="12" class="caption" v-if="f.comments"> {{ f.comments }}
                    <v-chip small outlined> {{ dateFormatted(f.date, {formatString: 'LL'}) }}</v-chip>
                  </v-col>
                </v-row>
              </template>
              <template v-if="feedback[meal.id] && feedback[meal.id].length>1">
                <v-expansion-panels flat class="">
                  <v-expansion-panel class="">
                    <v-expansion-panel-header class="ma-0 pa-0">
                      <v-chip-group>
                        <v-chip small v-for="property of ratingProperties"
                                v-bind:key="property"
                                :color="getRatingColor(ratings[meal.id][property].average)">
                          {{ property }} {{ ratings[meal.id][property].average }}
                        </v-chip>
                        <v-chip small outlined> average</v-chip>
                      </v-chip-group>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row dense class="" v-for="(f,i) of feedback[meal.id]" v-bind:key="`${meal.id}-${i}`"
                             no-gutters>
                        <v-col class="ma-0 pa-0">
                          <v-chip-group>
                            <v-chip small v-for="property of ratingProperties"
                                    v-bind:key="property"
                                    :color="getRatingColor(f[property])">
                              {{ property }} {{ f[property] }}
                            </v-chip>
                            <v-chip small outlined> {{ dateFormatted(f.date, {formatString: 'LL'}) }}</v-chip>
                          </v-chip-group>
                        </v-col>
                        <v-col cols="12" class="caption" v-if="f.comments"> {{ f.comments }}
                          <v-chip small outlined> {{ dateFormatted(f.date, {formatString: 'LL'}) }}</v-chip>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </template>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <template v-if="view==='stream_view'">
        <v-alert v-if="!readOnly" outlined type="info">Edits not allowed before {{
            dateFormatted(cutoffEditDate)
          }}
        </v-alert>
        <v-card v-for="stream of streams" v-bind:key="stream">
          <v-card-title>
            <v-spacer/>
            {{ stream }}
            <v-spacer/>
          </v-card-title>
          <v-card-text v-for="date of dates" v-bind:key="`${stream}-${date}`">
            <v-row>
              <v-col cols="2" md="1">{{ dateFormatted(date, {formatString: 'ddd MMM DD'}) }}</v-col>
              <v-col v-for="tod of tods" v-bind:key="tod">
                <h4>{{ tod }}</h4>
                <v-row no-gutters v-for="meal of findMeals({stream,date,tod})" v-bind:key="meal.id">
                  <v-col :style="pendingEdits[meal.id] ? 'background: lightgreen' : ''">
                    <v-chip small outlined v-if="meal.cost">{{ formatCurrency(meal.cost) }}</v-chip>
                    {{ meal.name }}
                    <v-chip v-if="ratings[meal.id]" small :color="getRatingColor(ratings[meal.id].overall.average)">
                      {{ ratings[meal.id].overall.average }}
                    </v-chip>
                  </v-col>
                  <v-col v-if="!readOnly">
                    <v-btn v-if="isAbleToEdit(meal)" @click="deleteMeal(meal)" fab icon>
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <v-select
                      label="Prod. Priority"
                      v-model="meal.production_priority"
                      :items="productionPriorities"
                      @change="saveMealPriority(meal)"
                    />
                  </v-col>
                </v-row>
                <v-row v-if="findMeals({stream,date,tod}).length===0">
                  <v-col>
                    <v-autocomplete
                      :items="mealsWithoutDates"
                      :item-text="i => `${i.name} - ${formatCurrency(i.cost)} ${ratings[i.id] ? (' - ' +ratings[i.id].overall.average+'★') :''}`"
                      item-value="id"
                      return-object
                      @change="setMealDate($event,date, tod)"
                    />
                    <v-col></v-col>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
    </v-container>
  </keep-alive>
</template>

<script>
import moment from 'moment';
import {mapActions, mapGetters, mapState} from 'vuex';
import {
  dateFormatted,
  formatCurrency,
  getDateString,
  formatDate,
  getDatesInRange,
  getNextOrderingDate,
  todMapToString
} from '@/store/utils';
import urlState from "@/router/urlState";
import api from "@/api";

export default {
  name: "MealSchedule",
  mixins: [urlState],
  data() {
    return {
      tods: 'breakfast lunch dinner'.split(' '),
      mealsRaw: [],
      focus: '',
      selectedDiet: null,
      // calendarType: 'month',
      calendarType: 'week',
      calendarTypes: ['month', 'week', 'day', '4day', 'category'],
      categories: [],
      start: undefined,
      end: undefined,
      loading: true,
      currentMenuDate: null,
      menu: {},
      view: null,
      ratingProperties: 'overall,portion,preparation,presentation,taste'.split(','),
      ratings: {},
      feedback: {},
      showCost: false,
      pendingEdits: {},
      showConfirmSave: null,
      saving: null,
      cutoffEditDate: null,
    }
  },
  watch: {
    // date() {
    //   this.fetchData();
    // }
    diets(diets) {
      this.categories = diets.map(d => d.name);
    },
    calendarType(v) {
      let unit = v;
      let amount = 1;
      if (unit === 'category') {
        unit = 'day';
      }
      if (unit === '4day') {
        unit = 'day';
        amount = 4;
      }

      const start = moment(this.start).startOf(unit).format(moment.HTML5_FMT.DATE);
      const end = moment(start).add(amount, unit).format(moment.HTML5_FMT.DATE);
      return this.updateDateRange({start, end});
    },
    showCost(v) {
      if (v) {
        this.loading = true;
        console.log('fetching costs');
        return api.post('v2/meal/get-cost', {mealIds: this.mealsRaw.map(m => m.id)})
          .then(r => r.data)
          .then(costs => {
            for (const meal of this.mealsRaw) {
              meal.cost = costs[meal.id];
            }
          })
          .finally(() => {
            console.log('fetched costs');
            this.loading = false;
          });
      } else {
        for (const meal of this.mealsRaw) {
          meal.cost = '';
        }
      }
    }
  },
  async mounted() {

    const nextOrderingDate = getNextOrderingDate();
    console.log('next ordering date', nextOrderingDate);
    this.cutoffEditDate = moment(nextOrderingDate).add(2, 'week');
    this.syncToUrl({
      param: 'start', urlParam: 'start', initFromRoute: true,
      // parseCallback: (v) => Array.isArray(v) ? v : [v]
    });
    this.syncToUrl({
      param: 'end', urlParam: 'end', initFromRoute: true,
      // parseCallback: (v) => Array.isArray(v) ? v : [v]
    });
    this.syncToUrl({
      param: 'selectedDiet', urlParam: 'diet', initFromRoute: true,
      parseCallback: (v) => v ? Number(v) : undefined
    });
    this.syncToUrl({
      param: 'calendarType', urlParam: 'range', initFromRoute: true,
      // parseCallback: (v) => Array.isArray(v) ? v : [v]
    });
    this.syncToUrl({
      param: 'view', urlParam: 'view', initFromRoute: true,
      // parseCallback: (v) => Array.isArray(v) ? v : [v]
    });
    this.syncToUrl({
      param: 'showCost', urlParam: 'cost', initFromRoute: true,
      parseCallback: (v) => v && v === 'true'
    });
    this.loading = true;
    await this.fetchDiets();
    return this.$nextTick(async () => {
      if (!this.start) {
        this.start = moment().startOf('week').format(moment.HTML5_FMT.DATE);
        this.end = moment().startOf('week').add(6, 'day').format(moment.HTML5_FMT.DATE);
      }
      if (!this.view) {
        this.view = 'calendar';
      }
      console.log('date range', this.start, this.end);
      await this.fetchData();
    });
  },
  computed: {
    ...mapState(['productionPriorities','todMapToInt']),
    ...mapGetters(['getDietColor', 'getDietColorInverted', 'getDietName', 'getTimeOfDay']),
    mealsFiltered() {
      return this.mealsRaw.filter(m => this.selectedDiet ? this.selectedDiet === m.diet : true);
    },
    meals() {
      const mealEvents = this.mealsFiltered
        .map(m => ({
          name: ` [${todMapToString[m.tod] || m.tod}] - ${m.name}`,
          color: this.getEventColorBackground(m.diet),
          start: moment(m.date).utc().format(moment.HTML5_FMT.DATE),
          // end: moment(m.date).format(moment.HTML5_FMT.DATETIME_LOCAL),
          timed: false, // ie, all day, not specific time
          // timed: true, // ie, all day, not specific time
          category: this.getDietName(m.diet),
          meal: m
        }));
      mealEvents.sort((a, b) => a.category.localeCompare(b.category));
      console.log('mealEvents', mealEvents);
      return mealEvents;
    },
    diets() {
      return [...new Set(this.mealsRaw.map(m => m.diet))]
        .map(id => ({id, name: this.getDietName(id)}));
    },
    currentWeek() {
      return moment(this.start).startOf('week').format(moment.HTML5_FMT.DATE);
    },
    streams() {
      let dietIds = [...new Set(this.mealsFiltered.map(m => m.diet))].sort();
      // console.log('dietIds', dietIds);
      const sortedIds = [1, 14, 17, 16, 18];
      dietIds = [...new Set([...sortedIds.filter(id => dietIds.includes(id)), ...dietIds])];
      return dietIds
        .map(id => this.getDietName(id));
    },
    dates() {
      return getDatesInRange(this.start, this.end)
        // skip sundays
        .filter(d => moment(d).format('ddd') !== 'Sun');
    },
    mealsWithoutDates() {
      return this.mealsFiltered.filter(m => !m.date);
    },
    pendingEditCount() {
      return Object.keys(this.pendingEdits).length
    },
    edits() {
      const edits = [];
      for (const mealId of Object.keys(this.pendingEdits)) {
        const meal = this.mealsRaw.find(m => m.id == mealId);
        const {name, id, original_tod, original_date} = meal;
        edits.push({meal, ...this.pendingEdits[mealId], original_tod, original_date, name, id})
      }
      return edits;
    }
  },
  methods: {
    formatDate,
    formatCurrency,
    dateFormatted,
    getRatingColor(value) {
      const colorRatings = [
        'red',
        'orange',
        'yellow',
        'light-green',
        'green',
      ]
      value = parseInt(value);
      return colorRatings[value - 1];
    },
    ...mapActions(['fetchMeals', 'fetchDiets']),
    getEventColor(diet) {
      if (this.selectedDiet) {
        return this.getDietColor(diet);
      } else {
        return this.getDietColorInverted(diet);
      }
    },
    getEventColorBackground(diet) {
      if (this.selectedDiet) {
        return '#f5f5f5';
      } else {
        return this.getDietColor(diet);
      }
    },
    async fetchData() {
      this.loading = true;
      const dates = [];
      const start = moment(this.start || '');
      const end = moment(this.end || '');
      do {
        dates.push(start.format(moment.HTML5_FMT.DATE));
        start.add(1, 'day');
      } while ( end.isSameOrAfter(start, 'day') );
      console.log('fetching dates', dates);
      // this.mealsRaw = (await Promise.all(dates.map(this.fetchMeals))).reduce((a, i) => a.concat(i), []);
      this.mealsRaw = (await Promise
          .all(dates
            .map(d => api
              .get('v2/meal/', {params: {where: {date: `${d}T00:00:00.000Z`}}})
              .then(({data}) => data.meals)))
      )
        .reduce((a, i) => a.concat(i), []);
      const mealIds = this.mealsRaw.flatMap(m => m.sku_meal_ids || [m.id]);

      function sortKey(m) {
        return `${m.date}-${m.diet}-${m.tod}`;
      }

      this.mealsRaw.forEach(m => {
        m.original_date = m.date;
        m.original_tod = this.getTimeOfDay(m.tod);
        m.sku_meal_ids = m.sku_meal_ids || [];
      })
      this.mealsRaw.sort((m1, m2) => sortKey(m1).localeCompare(sortKey(m2)));
      console.log('mealsRaw.', this.mealsRaw);
      // console.log('mealsRaw. with priority', this.mealsRaw.filter(m => !!m.production_priority));

      if (this.showCost) {
        await api.post('v2/meal/get-cost', {mealIds: this.mealsRaw.map(m => m.id)})
          .then(r => r.data)
          .then(costs => {
            for (const meal of this.mealsRaw) {
              meal.cost = costs[meal.id];
              meal.expandFeedback = false;
            }
          });
      } else {
        for (const meal of this.mealsRaw) {
          meal.cost = '';
          meal.expandFeedback = false;
        }
      }
      const otherMealIdToMealIdMap = Object.fromEntries(this.mealsRaw.flatMap(m => m.sku_meal_ids.map(otherId => [otherId, m.id]) || [m.id, m.id]));
      // console.log(otherMealIdToMealIdMap);
      const ratings = {};

      function addRating(mealId, property, value) {
        if (value) {
          const mealRating = ratings[mealId] || (ratings[mealId] = {});
          const mealPropertyRating = mealRating[property] || (mealRating[property] = {total: 0, count: 0});
          mealPropertyRating.total += value;
          mealPropertyRating.count++;
          mealPropertyRating.average = (mealPropertyRating.total / mealPropertyRating.count).toFixed(1);
        }
      }

      await api.post('feedback/get-by-id', {mealIds})
        .then(response => {
          const feedbackRecords = response.data;
          this.feedback = {};
          for (const f of feedbackRecords) {
            const originalMealId = otherMealIdToMealIdMap[f.meal_id];
            const mealId = originalMealId;
            this.ratingProperties.forEach(property => addRating(mealId, property, f[property]))
            this.feedback[originalMealId] = this.feedback[originalMealId] || [];
            this.feedback[originalMealId].push(f);
            this.feedback[originalMealId].sort((a, b) => b.date.localeCompare(a.date));
          }
          Object.assign(this.ratings, ratings);
        });

      console.log('done fetching');
      this.loading = false;


      if (this.currentMenuDate !== this.currentWeek) {
        await this.fetchMenu(this.currentWeek);
      }
    },
    fetchMenu(d) {
      this.currentMenuDate = d;
      if (this.menu[d]) {
        console.log('already have menu', d);
      } else {
        return api.get(`v2/menu/${d}`)
          .then(({data}) => {
            this.error = '';
            if (!data) {
              this.error = 'no menu found for this date';
            }
            console.log('fetched menu for ' + d, data);
            this.menu[d] = data;
          })
      }
    },
    updateDateRange({start, end}) {
      console.log('update date range', {start, end});
      if (this.start !== start.date && this.end !== end.date) {
        this.start = start.date || start;
        this.end = end.date || end;
        console.log('update date range', [this.start, this.end]);
        this.fetchData();
      } else {
        // console.log('already showing date range');
      }
    },
    prev() {
      if (this.view === 'calendar') {
        this.$refs.calendar.prev();
      } else {
        let unit = this.calendarType;
        let amount = -1;
        if (unit === 'category') {
          unit = 'day';
        }
        if (unit === '4day') {
          unit = 'day';
          amount = 4;
        }

        const start = moment(this.start).add(amount, unit).format(moment.HTML5_FMT.DATE);
        const end = moment(this.end).add(amount, unit).format(moment.HTML5_FMT.DATE);
        this.updateDateRange({start, end});
      }
    },
    next() {
      if (this.view === 'calendar') {
        this.$refs.calendar.next();
      } else {
        let unit = this.calendarType;
        let amount = 1;
        if (unit === 'category') {
          unit = 'day';
        }
        if (unit === '4day') {
          unit = 'day';
          amount = 4;
        }
        const start = moment(this.start).add(amount, unit).format(moment.HTML5_FMT.DATE);
        const end = moment(this.end).add(amount, unit).format(moment.HTML5_FMT.DATE);
        this.updateDateRange({start, end});
      }
    },
    eventClicked({event: {meal}}) {
      console.log('e', meal);
      this.$router.push({name: 'MealDetail', params: {date: getDateString(meal.date), id: `${meal.id}`}});
    },
    toTimeStamp(d) {
      return moment(d).format('YYYY-MM-DD hh:mm:ss');
    },
    showMore(e) {
      console.log('show more', e);
      this.start = e.date;
      this.end = e.date;
      this.calendarType = 'day'
    },
    async updateMealCosts() {
      this.loading = true;
      const mealIds = this.mealsRaw.map(m => m.id);
      const costs = await api.post('v2/meal/update-cost', {mealIds})
        .then(r => r.data);
      for (const meal of this.mealsRaw) {
        meal.cost = costs[meal.id];
      }
      this.loading = false;
    },
    findMeals({stream, date, tod}) {
      return this.mealsRaw
        .filter(m => m.date === date)
        .filter(m => this.getTimeOfDay(m.tod) === tod)
        .filter(m => this.getDietName(m.diet) === stream)
    },
    deleteMeal(meal) {
      meal.date = undefined;
      this.$set(this.pendingEdits, meal.id, {date: null, tod: null});
    },
    setMealDate(meal, date, tod) {
      console.log('set meal date', meal, date, tod, meal.original_date, meal.original_tod);
      meal.date = date;
      meal.tod = this.getTimeOfDay(tod);

      if (meal.original_date !== date || meal.original_tod !== tod) {
        const currentEdits = this.pendingEdits[meal.id] || {};
        this.$set(this.pendingEdits, meal.id, {...currentEdits, date, tod});
      } else {
        const currentEdits = this.pendingEdits[meal.id] || {};
        delete currentEdits.date;
        delete currentEdits.tod;
        if (Object.keys(currentEdits).length === 0) {
          this.$delete(this.pendingEdits, meal.id);
        } else {
          this.$set(this.pendingEdits, meal.id, currentEdits);
        }
      }
    },
    saveMealPriority(meal) {
      const currentEdits = this.pendingEdits[meal.id] || {};
      let {production_priority} = meal;
      if (production_priority === 'clear') {
        production_priority = null;
      }
      console.log('set priority ', production_priority, meal);
      this.$set(this.pendingEdits, meal.id, {...currentEdits, production_priority});
    },
    save() {
      this.saving = true;
      return Promise
        .all(Object.keys(this.pendingEdits).map(mealId => {
          const update = this.pendingEdits[mealId];
          if (update.tod && typeof update.tod === 'string') {
            update.tod = this.getTimeOfDay(update.tod);
          }
          return api.put(`v2/meal/${mealId}`, {meal: update});
        }))
        .then(() => {
          this.pendingEdits = {};
          this.saving = false;
          this.showConfirmSave = false;
        })
    },
    isAbleToEdit(meal) {
      if (meal.date && this.cutoffEditDate.isSameOrBefore(meal.date)) {
        return true;
      } else {
        return false;
      }
    },
  },

  props: {
    readOnly: {type: Boolean, default: false, required: false}
  }
}
</script>

<style>

</style>
