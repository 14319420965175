<template>

  <v-container v-if="meal">
    <v-row>
      <v-col>
        <h1>{{ meal.name }}</h1>
      </v-col>
    </v-row>
    <v-row v-if="missingComponentIds.length>0">
      <v-col>
        <v-alert text outlined type="warning" :value="missingComponentIds.length>0">Missing component ids {{
            missingComponentIds
          }}
        </v-alert>
      </v-col>
      <v-col>
        <v-btn  outlined color="success" @click="fixMissing">Create Missing Components</v-btn>
      </v-col>
    </v-row>

    <v-row
        v-for="mc of mealComponents"
        v-bind:key="mc.id"
        dense
    >
      <v-col cols="1">
        <router-link :to="{ name: 'ComponentDetail', params: { id : mc.component_id }}">C{{
            mc.component_id
          }}
        </router-link>
      </v-col>
      <v-col cols="1" class="my-0 py-0">
        <v-text-field
            class="mt-0"
            reverse
            prefix="g"
            dense
            single-line
            :value="formatWeight(mc.amount)"
            v-model="mc.amount"
            type="number"
        />
      </v-col>
      <v-col>
        {{ getComponentName(mc.component_id) }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="1"/>
      <v-col cols="1" style="text-align: right" class="my-0 py-0 pr-1">
        {{ formatWeight(totalWeight) }} g
      </v-col>
      <v-col/>
    </v-row>

    <v-row v-if="missingComponentIds.length>0">
      <v-col>
        <h2>The following ingredients do not have a meal component (debug, to clean up)</h2>
        <!--        <pre>{{ components }}</pre>-->
        <!--        <pre>{{ componentAmounts }}</pre>-->
        <pre>{{ ingredients.filter(i => missingComponentIds.includes(i.component_id)) }}</pre>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import api from '@/api';
import {formatWeight} from '@/store/utils';

export default {
  name: "MealTemplate",
  props: {
    mealId: {default: null, required: true}
  },
  data() {
    return {
      meal: null,
      mealComponents: [],
      ingredients: [],
      components: []
    }
  },

  mounted() {
    this.fetchMeal(this.mealId);
  },
  watch: {
    mealId(id) {
      this.fetchMeal(id);
    }
  },
  computed: {
    mealComponentIds() {
      return [...new Set((this.mealComponents || []).map(i => i.component_id))];
    },
    ingredientComponentIds() {
      return [...new Set(this.ingredients.map(i => i.component_id))];
    },
    componentIds() {
      return [...new Set([...this.ingredientComponentIds, ...this.mealComponentIds])];
    },
    missingComponentIds() {
      return this.ingredientComponentIds.filter(id => !this.mealComponentIds.includes(id));
    },
    componentAmounts() {
      const amounts = {};
      for (const id of this.componentIds) {
        amounts[id] = formatWeight(this.ingredients
            .filter(i => i.component_id === id)
            .reduce((s, a) => a.amount + s, 0));
      }
      return amounts;
    },

    totalWeight() {
      return this.mealComponents.map(mc => parseFloat(mc.amount)).reduce((a, b) => a + b, 0);
      // return Object.values(this.componentAmounts).reduce((a, b) => a + b, 0);
    }
  },
  methods: {
    formatWeight,
    async fetchMeal(id) {
      api.get(`v2/meal/${id}`)
          .then(({data}) => {
            console.log(data);
            this.meal = data;
          });
      api.get(`v2/meal/${id}/components`)
          .then(({data}) => {
            console.log(data);
            this.mealComponents = data.mealComponents;
            this.mealComponents.forEach(mc => mc.amount = formatWeight(mc.amount));
            this.ingredients = data.ingredients;
            this.components = data.components;
          });
    },
    fixMissing() {
      console.log('fix missing ', this.missingComponentIds);
      const promises = [];
      for (const id of this.missingComponentIds) {
        const component = this.components.find(c => c.id === id);
        console.log('comp', component);
        if (component) {
          const mealComponent = {
            component_id: id,
            amount: this.componentAmounts[id],
            large_ratio: component.large_ratio,
            small_ratio: component.small_ratio,
            monosized: component.monosized
          };
          promises.push(api.post(`v2/meal/${this.mealId}/components`, mealComponent)
              .then(({data}) => {
                console.log(data);
              }));
        }
      }
      return Promise
          .all(promises)
          .then(() => this.fetchMeal(this.mealId));
    },
    getComponentName(id) {
      const component = this.components.find(c => c.id === id);
      return component && component.name;
    }
  },
}
</script>

<style scoped>

</style>
