<template>
  <v-container fluid>
    <v-toolbar flat class="pb-10">
      <v-text-field
          class="d-print-none"
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          clearable
      />
      <v-spacer/>
      <v-btn-toggle
          v-model="search"
      >
        <v-btn
            v-for="team of teamFilter"
            v-bind:key="team"
            :value="`team:${team}`"
        >{{ team }}
        </v-btn>

      </v-btn-toggle>

      <v-spacer/>
      <v-text-field :value="datesFormatted"
                    persistent-hint
                    hint="Delivery Date"
                    label="Select dates"
                    single-line
                    readonly
                    @click="datePickerDate=dates[0]; showDatePicker=true"
                    append-icon="mdi-calendar"
      />

      <v-dialog
          v-model="showDatePicker"
          ref="dialog"
          width="290px"
          persistent
      >
        <v-date-picker
            v-model="datePickerDate"
            no-title
            @close="closeDatePicker"
        ></v-date-picker>
        <v-btn @click="closeDatePicker">Close</v-btn>
      </v-dialog>
    </v-toolbar>

    <TaskPanel
        v-if="hasDates"
        :team="team"
        :dates="getDatesInRange(dateFrom,dateTo)"
        :search="search"
        :filter="filter"
    >
      <template v-slot:task-title="{ props: { task}}">
        <v-card-title>
          {{ task.title }}
          <v-spacer/>
          <v-icon v-if="task.complete">mdi-check</v-icon>
        </v-card-title>
      </template>
      <!--      /* eslint-disable-next-line vue/no-unused-vars */-->
      <template v-slot:task-text="{ props: {}}">
        <div></div>
      </template>
    </TaskPanel>
    <v-alert v-if="!hasDates">
      Please select a production date
    </v-alert>
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex';
import {dateFormatted, extractFoodId, formatWeight, getDatesInRange} from '@/store/utils';
import TaskPanel from '@/components/tasks/TaskPanel';
import moment from 'moment';
import urlState from "@/router/urlState";

export default {
  name: "PackTasks",
  components: {TaskPanel},
  mixins: [urlState],
  mounted() {
    this.syncToUrl({
      param: 'taskFilter', urlParam: 'status', initFromRoute: true,
      // parseCallback: (v) => v === 'true'
    });
    this.syncToUrl({
      param: 'showAllergy', urlParam: 'allergy', initFromRoute: true,
      parseCallback: (v) => v === 'true'
    });
    this.syncToUrl({
      param: 'showGarnish', urlParam: 'garnish', initFromRoute: true,
      parseCallback: (v) => v === 'true'
    });
    this.syncToUrl({
      param: 'onlyGarnish', urlParam: 'garnish_only', initFromRoute: true,
      parseCallback: (v) => v === 'true'
    });
    this.syncToUrl({
      param: 'dates', urlParam: 'dates', initFromRoute: true,
      parseCallback: (v) => Array.isArray(v) ? v : [v]
    });
    this.syncToUrl({
      param: 'search', urlParam: 'search', initFromRoute: true,
      // parseCallback: (v) => v === 'true'
    });
  },
  computed: {
    ...mapGetters(['getComponent', 'getMeal',]),
    datesFormatted() {
      const format = 'dddd MMMM D';
      if (!this.dateFrom) {
        return '';
      } else if (this.dateFrom === this.dateTo) {
        return `${moment(this.dateFrom).format(format)}`;
      } else {
        return `${moment(this.dateFrom).format(format)} - ${moment(this.dateTo).format(format)}`
      }
    },
    dateFrom() {
      return [...this.dates].sort()[0];
    },
    dateTo() {
      return [...this.dates].sort().reverse()[0];
    },
    hasDates() {
      return this.dates.length > 0;
    },
    filter() {
      return this.showGarnish ? '' : 'garnish';
    }
  },

  methods: {
    getDatesInRange,
    extractFoodId,
    getComponents(componentIds) {
      return componentIds.map(this.getComponent).sort((a, b) => a.name.localeCompare(b.name));
    },
    getTimerId(id) {
      return `I${id}`;
    },
    dateFormatted,
    closeDatePicker() {
      this.showDatePicker = false;
      const date = moment(this.datePickerDate);
      const dates = [
        moment(date).format(moment.HTML5_FMT.DATE),
      ];
      console.log('setting dates', dates);
      this.dates = dates;
      return this.fetchData();
    },
    fetchData() {
      this.error = this.post = null
      this.loading = true
    },
    formatTaskAmount(task) {
      console.log('formatTaskAmount', task);

      if (task.source) {
        const {source: {totalAmount, cookedTotal, rawTotal}} = task;
        if (cookedTotal && cookedTotal === rawTotal) {
          return this.formatWeightWithUnits(cookedTotal);
        } else if (cookedTotal) {
          return `${this.formatWeightWithUnits(cookedTotal)} (raw ${this.formatWeightWithUnits(rawTotal)})`;
        } else if (totalAmount) {
          return this.formatWeightWithUnits(totalAmount);
        } else {
          return this.formatWeightWithUnits(0)
        }
      }
    },
    formatWeightWithUnits(amount) {
      const units = ['g', 'kg'];
      const decimalPlaces = [1, 2];
      return formatWeight(amount, units, decimalPlaces);
    },
    allowedDates(val) {
      const notAllowed = {
        0: true,
        // 2: true,
        // 4: true
      };

      return !notAllowed[moment(val).day()];
    }
  },
  data() {
    return {
      dates: [],
      search: null,
      teamsSelected: null,
      showDatePicker: null,
      loading: null,
      datePickerDate: null,
      showGarnish: null,
      onlyGarnish: null
    }
  },
  watch: {},
  props: {
    team: {type: String, required: true},
    teamFilter: {type: Array, default: null, required: false}
  }
}
</script>

<style scoped>

</style>
