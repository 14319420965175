<template>
  <v-container>
    <template v-if="user.first_name">
      <p class="text-center">
        Hi {{ user.first_name }}! This is the fed kitchen app.
      </p>
      <v-img
          min-height="700"
          max-height="700"
          contain
          src="https://source.unsplash.com/random?collection=239835"
      />
      <p class="text-center">
        a random image without an inspirational quote. (<a href="https://unsplash.com/collections/239835">attribution</a>)
        <template v-if="joke">
          <br/><br/>
          {{ joke }} (<a href="https://icanhazdadjoke.com">attribution</a>)
        </template>
      </p>

    </template>
    <h1 v-if="user.none">Please login to continue
      <router-link to="login">Login</router-link>
    </h1>
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex';
import axios from 'axios';

export default {
  name: "Home",
  computed: {
    ...mapGetters([
      'getCurrentUser'
    ]),
    user() {
      console.log('user ', this.getCurrentUser);
      return this.getCurrentUser;
    }
  },
  mounted() {
    axios
        .get('https://icanhazdadjoke.com/',
            {
              headers: {
                Accept: 'text/plain'
              }
            })
        .then(j => this.joke = j.data);
  },
  data() {
    return {
      joke: null,
      testProduction: {
        dates: [],
        production: null
      }
    }
  }
}
</script>

<style scoped>

</style>
