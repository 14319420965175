import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c(VCard,[_c(VCardTitle,[_vm._v(_vm._s(_vm.container.id ? 'Edit' : 'Add')+" Container")]),_c(VCardText,[_c(VRow,[_c(VCol,[_c(VTextField,{attrs:{"label":"Name","type":"text"},model:{value:(_vm.container.name),callback:function ($$v) {_vm.$set(_vm.container, "name", $$v)},expression:"container.name"}})],1),_c(VCol,[_c(VTextField,{attrs:{"label":"Description","type":"text"},model:{value:(_vm.container.description),callback:function ($$v) {_vm.$set(_vm.container, "description", $$v)},expression:"container.description"}})],1),_c(VCol,[_c(VTextField,{attrs:{"label":"Weight (in grams)","type":"number","min":"0"},model:{value:(_vm.container.weight),callback:function ($$v) {_vm.$set(_vm.container, "weight", $$v)},expression:"container.weight"}})],1),_c(VCol,[_c(VBtn,{attrs:{"outlined":""},on:{"click":function($event){return _vm.saveContainer(_vm.container)}}},[_vm._v(" Save ")])],1)],1)],1)],1),_vm._l((_vm.containers),function(container){return _c(VCard,{key:container.id,staticClass:"mt-4"},[_c(VCardText,[_c(VRow,[_c(VCol,[_c(VTextField,{attrs:{"label":"Name","type":"text"},model:{value:(container.name),callback:function ($$v) {_vm.$set(container, "name", $$v)},expression:"container.name"}})],1),_c(VCol,[_c(VTextField,{attrs:{"label":"Description","type":"text"},model:{value:(container.description),callback:function ($$v) {_vm.$set(container, "description", $$v)},expression:"container.description"}})],1),_c(VCol,[_c(VTextField,{attrs:{"label":"Weight (in grams)","type":"number","min":"0"},model:{value:(container.weight),callback:function ($$v) {_vm.$set(container, "weight", $$v)},expression:"container.weight"}})],1),_c(VCol,[_c(VBtn,{attrs:{"outlined":""},on:{"click":function($event){return _vm.saveContainer(container)}}},[_vm._v(" Save ")])],1)],1)],1)],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }