<template>
  <v-card flat>
    <v-card-title>Food Restrictions (allergies)</v-card-title>
    <v-card-text>
      <v-alert type="warning" outlined>
        You cannot edit the "id" of any allergies that are currently used by customers.
      </v-alert>
      <v-toolbar flat>
        <v-btn outlined @click="restrictions = [{ is_new:true, index: 'new'}]">Add New</v-btn>
      </v-toolbar>
      <v-row v-for="(r,i) of restrictions" v-bind:key="r.index">
        <v-col>
          <v-text-field
            label="id"
            v-model="r.id"
            @change="r.changed=true"
            :disabled="!r.is_new"
          />
          <!--          :disabled="readOnly"-->
          <v-text-field
            label="display text"
            v-model="r.text"
            @change="r.changed=true"
            :disabled="readOnly"
          />
        </v-col>
        <v-col>
          <v-checkbox
            label="Available On Signup"
            v-model="r.available"
            @change="r.changed=true"
            :disabled="readOnly"
          />
          <v-checkbox
            label="Available On Personal Plan"
            v-model="r.available_personal"
            @change="r.changed=true"
            :disabled="readOnly"
          />
        </v-col>
        <v-col>
          <v-select
            label="Tags"
            multiple
            chips
            clearable
            deletable-chips
            v-model="r.restrictedTags"
            :items="tags"
            item-text="name"
            item-value="id"
            @change="r.changed=true"
            :disabled="readOnly"
          />
        </v-col>
        <v-col cols="2" v-if="!readOnly">
          <v-btn outlined :disabled="!r.changed" @click="save(r)">SAVE</v-btn>
          <v-spacer/>
          <v-btn class="mt-2" outlined :disabled="!r.changed"
                 @click="$set(restrictions,i,deepCopy(restrictionsBeforeEdit[r.id]))">Cancel
          </v-btn>
        </v-col>
      </v-row>

    </v-card-text>
  </v-card>
</template>

<style scoped>

</style>


<script>
import {mapActions,} from "vuex";
import api from "@/api";
import {deepCopy} from "@/store/utils";

export default {
  name: "Restrictions",
  async mounted() {
    this.tags = (await api.get('tags').then(({data}) => data)).sort((a, b) => a.name.localeCompare(b.name));
    this.tagMap = Object.fromEntries(this.tags.map(({id, name}) => [id, name]));
    return this.fetchData();
  },
  methods: {
    ...mapActions(['fetchRestrictions']),
    deepCopy,
    async save(r) {
      await api.put('v2/restrictions/' + r.id, r)
        .then(() => this.fetchData())
        .catch(e => alert('Failed to save: ' + e.message));
    },
    async fetchData() {
      this.restrictions = await api.get('v2/restrictions').then(({data}) => data);
      this.restrictionsBeforeEdit = deepCopy(this.restrictions);
    }
  },
  data() {
    return {
      tags: null,
      restrictions: null,
      restrictionsBeforeEdit: null,
      tagMap: null
    }
  },
  props: {
    readOnly: {type: Boolean, default: true, required: false}
  }
}
</script>

