<template>
  <div class="fed-large-label">
    <v-container full-page class="page-break">
      <v-layout row wrap>
        <v-flex xs4 label-title-first>
          Day
        </v-flex>
        <v-flex xs4 label-title>
          Size
        </v-flex>
        <v-flex xs4 label-title>
          Meal
        </v-flex>
      </v-layout>
      <v-layout row wrap dash-on-bottom>
        <v-flex xs4 label-content label-content-first dash-right>
          {{ getFullDay }}
        </v-flex>
        <v-flex xs4 label-content>
          {{ capitalise(mealSize) }}
        </v-flex>
        <v-flex xs4 dash-left label-content style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
          {{ capitalise(orderItem.order_tod || orderItem.tod) }}
        </v-flex>
      </v-layout>
      <v-layout row wrap pad-top>
        <v-flex xs8 label-title-first>Type</v-flex>
        <v-flex xs4 label-title>Best Before</v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs8 label-content-first v-if="isChefsChoice">Chef's Choice</v-flex>
        <v-flex xs8 label-content-first v-else>{{ getStream.name }}</v-flex>
        <v-flex xs4 label-content dash-left>{{ getBestBefore }}</v-flex>
      </v-layout>
      <v-layout row wrap dash-on-top style="padding-top: 10px; padding-bottom: 10px; min-height: 60px;">
        <v-flex xs12 label-main-title>
          {{ orderItem.meal.name }}
        </v-flex>
        <v-flex text-xs-right pt-2>
          <v-chip outlined text-color="black" small style="font-size: 8px" v-for="(allergy, index) in allergies.keys()"
                  v-bind:key="index">
            {{ allergies.get(allergy) }}
            <v-icon x-small v-if="orderItem.allergyDetected && orderItem.allergyDetected[allergy]">mdi-alert</v-icon>
          </v-chip>

        </v-flex>
      </v-layout>
      <!--      <v-layout row wrap>-->
      <!--      </v-layout>-->
      <v-layout row wrap pad-top>
        <v-flex xs4 pl-0 class="fed-bin-label">{{ productionPrefix +shortenBin(orderItem.bin) }}</v-flex>
        <v-flex xs8 label-content>{{ orderItem.first_name }} {{ orderItem.last_name }}</v-flex>
      </v-layout>
      <v-layout row wrap dash-on-top>
        <v-flex xs4 label-title-under-first pad-top>Nutrition</v-flex>
        <v-flex xs4 dash-left label-title-under pad-top>
          Directions
        </v-flex>
        <v-flex xs4  label-title-under pad-top>
          <div class="text-right "><span class="label-number">{{ pageNumber }}</span></div>
        </v-flex>
      </v-layout>
      <v-layout row wrap pad-top>
        <v-flex xs4 label-content-first>
          <qrcode-vue :value="qrCodeContent" :size=70></qrcode-vue>
        </v-flex>
        <v-flex xs8>
          <v-layout justify-space-between column fill-height style="margin-left: 10px !important;">
            <div class="label-content label-instructions" v-if="getInstructions(orderItem).length>1">
              <ol>
                <li v-for="(instruction, index) in orderItem.meal.instructions" :key="index">{{ instruction }}</li>
              </ol>
            </div>
            <div class="label-content label-instructions" v-if="getInstructions(orderItem).length===1">
              {{ orderItem.meal.instructions[0] }}
            </div>
            <div class="label-content label-instructions" v-if="getInstructions(orderItem).length===0"></div>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout row dash-on-top>
        <v-flex xs6 label-title-under-first pad-top>Ingredients</v-flex>
        <v-flex xs6 label-title-under label-content-text-xx-small dash-left pad-top>Keep Refrigerated</v-flex>
      </v-layout>
      <v-layout row pad-top pad-bottom>
        <v-flex xs12 label-ingredients>
           <span
             v-for="(ingredient,index) of orderItem.meal.ingredients"
             v-bind:key="index"
             :style="orderItem.restrictedFoods && orderItem.restrictedFoods[ingredient.food_id]?'text-decoration: line-through':''"
           >{{ appendCommaUnlessLast(ingredient.name, index, orderItem.meal.ingredients.length) }}</span>

        </v-flex>
      </v-layout>
      <v-layout row dash-on-top pad-top>
        <v-flex xs6 label-title-first>{{ getFullDay }} {{ orderItem.tod }}</v-flex>
        <v-flex xs6 label-content text-right>{{ orderItem.first_name }} {{ orderItem.last_name }}</v-flex>
      </v-layout>
      <v-layout row pad-top label-fold-title>
        <v-flex xs12 label-fold-title>{{ orderItem.meal.name }}</v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>

import QrcodeVue from 'qrcode.vue';
import '@/assets/labels.scss';
import {capitalise,shortenBin} from "@/store/utils";

export default {
  name: 'MealLabelLarge',
  components: {
    QrcodeVue
  },
  props: {
    mealSize: String,
    date: {},
    getBestBefore: {},
    getFullDay: {},
    getStream: {},
    allergies: {},
    isChefsChoice: Boolean,
    orderItem: {},
    qrCodeContent: String,
    pageNumber: String,
    productionPrefix: {type: String}
  },
  methods: {
    capitalise,
    getInstructions(orderItem) {
      return orderItem.meal.instructions || [];
    },
    appendCommaUnlessLast(str, index, length) {
      return index < length - 1 ? `${str}, ` : str;
    },
    shortenBin
  }
};
</script>
