<template>
  <div>
    <v-dialog v-model="showAddIngredient" width="600px">
      <v-card>
        <v-card-text>
          <IngredientAutocomplete
            @updateValue="ingredientToAdd=$event"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn @click="showAddIngredient=false">Cancel</v-btn>
          <v-btn v-if="isAdmin" @click="addIngredient(ingredientToAdd); showAddIngredient=false">Add Ingredient</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <template v-if="!componentId">
      <v-text-field
        label="Enter a name for the new component"
        v-model="newComponentName"
      />
      <v-btn @click="createNewComponent(newComponentName)">Create</v-btn>
    </template>
    <div v-if="loading">
      <v-alert>Loading <span v-if="mealIds && mealIds.length>0">{{ mealIds.length }} meals</span></v-alert>
    </div>
    <template v-if="!loading && componentId">
      <v-alert v-if="notFound">Could not find component {{ this.componentId }}</v-alert>
      <v-dialog v-model="showApplyToUpcoming">
        <v-card v-if="showApplyToUpcoming">
          <v-card-title>
            <PrepTeamIcon :assignment="findAssignedTeam(component)"/>
            {{ component && component.name }}
            <v-spacer/>
            <v-btn class="mr-2" outlined @click="showApplyToUpcoming=false">Cancel</v-btn>
            <v-btn outlined :loading="applying" @click="applySelected">Apply</v-btn>

          </v-card-title>
          <v-card-subtitle>
            Apply to {{ upcomingNotUsingDefault.length }} upcoming meals.
          </v-card-subtitle>
          <v-card-text>

            <br/>
            The component amounts (per {{ formatWeight(componentTotal, units, decimalPlaces) }}):
            <span v-for="i of componentIngredients"
                  v-bind:key="i.foodid"> {{ i.cookedAmount }}g {{ i.fooddescriptionsimple }}
                  {{ i.team }} {{ i.instruction }}
              ,</span>
            <br/>
            This will apply the current default component to the following meals:
            <div>
              <v-row dense>
                <v-col cols="1" class="my-0 py-0">
                  <v-checkbox
                    v-model="allSelected"
                    :indeterminate="someSelected"
                    @click="setSelected"
                  />
                </v-col>
                <v-col cols="1"/>
                <v-col align-self="center">Select All/None</v-col>
              </v-row>
              <v-row v-for="(u) of upcomingAndSkuNotUsingDefault" v-bind:key="`${u.meal.id}-upcoming`">
                <v-col cols="1">
                  <v-checkbox
                    v-model="selected[u.meal.id]"
                  />
                  <v-icon v-if="u.meal.is_selected">
                    mdi-star
                  </v-icon>
                </v-col>
                <v-col cols="1">
                  <PrepTeamIcon
                    v-if="findAssignedTeam(component)!==findAssignedTeam(u)"
                    :assignment="findAssignedTeam(component)"
                  />
                </v-col>
                <v-col>
                  <h3>
                    <router-link
                      :to="{ name: 'MealDesign', params: { id: u.meal.id }}">
                      M{{ u.meal.id }}
                    </router-link>
                    {{ u.meal.date }} {{ u.meal.name }}
                    ({{ formatWeight(u.cookedAmount, units, decimalPlaces) }})
                    x{{ (u.ratio).toFixed(2) }}
                  </h3>
                  <ul>
                    <li v-for="i of computeAdjustmentToDefault(u.ingredients, u.ratio)"
                        v-bind:key="`adjust-${u.meal.id}-${i.foodid}`">
                      {{ formatWeight(i.cookedAmount, units, decimalPlaces) }} {{ i.fooddescriptionsimple }}
                      <template v-if="i.adjustedCookedAmount!==0 && i.adjustment!==0">
                        <strong>
                          <template v-if="i.adjustment>0"> adjust +</template>
                          <template v-if="i.adjustment<0"> adjust</template>
                          {{ formatWeight(i.adjustment, units, decimalPlaces) }}
                        </strong>
                      </template>
                      <template v-if="i.adjustedCookedAmount>0 && i.adjustment===0">
                        <v-chip small class="mx-2" color="" outlined> no change {{
                            formatWeight(i.cookedAmount, units, decimalPlaces)
                          }}
                        </v-chip>
                      </template>
                      <template v-if="i.adjustedCookedAmount===0">
                        <v-chip small class="mx-2" color="red" outlined> remove {{ i.fooddescriptionsimple }}</v-chip>
                      </template>
                      <v-chip v-if="i.adjustTeam" outlined class="mx-2" color="green">
                        <PrepTeamIcon :assignment="i.adjustTeam"/>
                      </v-chip>
                      <v-chip v-if="!i.adjustTeam && i.team" class="mx-2">
                        <PrepTeamIcon :assignment="i.team"/>
                      </v-chip>
                      <v-chip v-if="i.adjustInstruction" outlined class="mx-2" color="green">{{
                          i.adjustInstruction
                        }}
                      </v-chip>
                      <v-chip v-if="!i.adjustInstruction && i.instruction" small class="mx-2" outlined> no
                        change {{
                          i.instruction
                        }}
                      </v-chip>
                    </li>
                  </ul>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <div v-if="!loading && !notFound">
        <v-toolbar outlined height="90px;">
          <v-layout ma-6 v-if="isNameEdit">
            <v-row>
              <v-text-field v-model="component.name"/>
              <v-btn class="ml-4" @click="saveEditName">Save</v-btn>
              <v-btn class="ml-4" @click="cancelEdit">Cancel Edit</v-btn>
            </v-row>
          </v-layout>

          <v-toolbar-title>

          <span v-if="!isNameEdit">
          {{ component.name }} ({{ component.id }})
            <v-btn
              v-if="!readOnly"
              @click="editName"
              icon
            ><v-icon>mdi-pencil</v-icon>
            </v-btn>
          </span>

            <span
              v-if="!isNameEdit"
              class="right d-flex"
              style="font-size: small"
            >
          </span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon color="blue" v-if="readOnly && component.verified">mdi-shield-check</v-icon>
          <v-checkbox
            v-if="!readOnly"
            class="pt-6"
            v-model="component.verified"
            label="verified"
            @change="updateComponent({id: component.id, component})"
          />
          <v-checkbox
            :disabled="readOnly"
            class="pt-6 ml-2"
            v-model="component.monosized"
            label="monosized"
            @change="updateComponent({id: component.id, component})"
          />
        </v-toolbar>
        <v-card>
          <v-card-text>
            <v-row class="pa-2">
              <v-col>
                <v-text-field v-if="!readOnly || component.category"
                              :disabled="readOnly"
                              class="mr-2"
                              style="margin-top: 14px"
                              label="Category"
                              v-model="component.category"
                              @change="updateComponent({id: component.id, component})"
                />
              </v-col>
              <v-col>
                <v-autocomplete v-if="!readOnly || component.currentTagIds"
                                :disabled="readOnly"
                                prefix="Tags"
                                v-model="currentTagIds"
                                :items="componentTags"
                                item-text="name"
                                item-value="id"
                                multiple
                                chips
                                deletable-chips
                                no-data-text="No tag matches, maybe you need to add a new tag?"
                                @change="updateTags"
                />
              </v-col>
              <v-col cols="2">
                <v-btn
                  text outlined
                  v-if="!readOnly"
                  class="ma-3"
                  @click="addTag">
                  New Tag
                </v-btn>
              </v-col>
            </v-row>
            <v-textarea
              v-if="!readOnly || component.notes"
              rows="1"
              :disabled="readOnly"
              class="pa-3"
              v-model="component.notes"
              auto-grow
              label="here you can enter recipe notes"
              @change="updateComponent({id: component.id, component})"
            />


          </v-card-text>
        </v-card>
        <v-spacer/>
        <v-card class="my-2">
          <template v-if="!hideMeals">
            <v-card-text v-if="!componentHasTemplate && componentHasMeals">
              <v-alert type="warning" outlined>
                No default component set yet. Choose one by clicking 'Make Default' on the meal with
                the correct ingredients
              </v-alert>
            </v-card-text>
            <v-card-text v-if="componentHasTemplate && componentHasMeals">
              <ul>
                <li>
                  {{ upcomingUsingDefault.length }} of {{ ingredientsByMealUpcoming.length }}
                  upcoming meals (after today) are using the default component template.
                </li>
                <li>
                  {{ skuVersionNotUsingDefault.length }} of {{ ingredientsByMealSKUVersion.length }}
                  SKU versions are not using the default.
                </li>
                <li>
                  {{ countUsingDefault }} of {{ countMeals }} all meals (including past) are using the default component
                  template.
                </li>
              </ul>

            </v-card-text>
            <v-card-actions v-if="componentHasTemplate && componentHasMeals && !readOnly">
              <v-spacer/>
              <v-btn
                :disabled="upcomingUsingDefault.length - ingredientsByMealUpcoming.length ===0"
                @click="showApplyToUpcomingDialog"
                color="warning"
                outlined

              >
                <v-icon>mdi-alert-outline</v-icon>
                Replace Upcoming Meals and SKU Meals with DEFAULT
                <v-icon>mdi-alert-outline</v-icon>
              </v-btn>
              <v-spacer/>
            </v-card-actions>
          </template>
        </v-card>

        <v-card>
          <v-card-actions>
            <PrepTeamIcon
              :assignment="findAssignedTeam(defaultComponentIngredients)">
            </PrepTeamIcon>
            <template v-if="isDefault(defaultComponentIngredients)">
              <v-icon color="yellow"> mdi-star</v-icon>
              <h2>DEFAULT</h2>
            </template>
          </v-card-actions>

          <v-card-actions class="d-print-none">
            <v-spacer></v-spacer>
            <v-btn v-if="isAdmin && !readOnly" small text @click="showAddIngredient=true">Add Ingredient</v-btn>
            <v-btn v-if="!isAdmin" small text :to="{ name:'ComponentDetailAdmin', params: { id: componentId}}">Edit
              Ingredients
            </v-btn>
          </v-card-actions>
          <v-alert
            v-if="defaultComponentIngredients.ingredients.length===0"
            type="warning"
            outlined
          >
            No Default.
          </v-alert>
          <ComponentIngredients
            outlined
            :component="component"
            :dense="dense"
            :ingredients="defaultComponentIngredients.ingredients"
            :is-monosized="isMonosized(componentId)"
            :read-only="!isAdmin"
            :show-prep-controls="!readOnly"
            :save-component-assignment="saveComponentAssignment"
            :save-ingredient-assignment="saveComponentIngredientAssignment"
            :on-update-ingredient="onUpdateIngredient"
          />
        </v-card>

        <template v-if="!hideMeals">
          <v-card-title>Meals Using This Component</v-card-title>
          <div v-for="(componentOrMeal,index) of ingredientsByMeal"
               v-bind:key="index"
               class="my-4"
          >
            <v-card>
              <template v-if="componentOrMeal.meal">

                <template v-if="isDefault(componentOrMeal)">
                  <v-card-title>
                    <v-chip
                      class="mr-2"
                      v-if="componentOrMeal.meal.diet"
                      text-color="white"
                      small
                      :color="getDietColor(componentOrMeal.meal.diet)">
                      {{ getDietName(componentOrMeal.meal.diet) }}
                    </v-chip>
                    {{ componentOrMeal.meal.name }}&nbsp;
                    <router-link
                      :to="{ name: 'MealDesign', params: { date: formatDate(componentOrMeal.meal.date), id: componentOrMeal.meal.id }}"
                    >
                      M{{ componentOrMeal.meal.id }}
                    </router-link>&nbsp;
                    <router-link
                      :to="{ name: 'MealVersions', params: { sku: componentOrMeal.meal.sku }}"
                    >
                      {{ componentOrMeal.meal.sku }}
                    </router-link>&nbsp;
                    <template v-if="componentOrMeal.meal.is_selected">
                      <v-icon color="yellow">mdi-star</v-icon>
                      {{ componentOrMeal.meal.sku }}
                    </template>
                    {{ formatDate(componentOrMeal.meal.date) }}
                    <br/>
                  </v-card-title>
                  <v-alert type="success">
                    Using default component
                  </v-alert>
                </template>

                <template v-if="!isDefault(componentOrMeal)">
                  <v-card-actions>
                    <v-btn
                      v-if="!isDefault(componentOrMeal)"
                      @click="makeDefault(componentOrMeal)"
                      :disabled="readOnly"
                      outlined
                    >
                      Make Default
                    </v-btn>

                  </v-card-actions>

                  <ComponentIngredients
                    outlined
                    :component="component"
                    :meal="componentOrMeal.meal"
                    :dense="dense"
                    :ingredients="normaliseTo100g ? normalise(componentOrMeal.ingredients) : componentOrMeal.ingredients"
                    :is-monosized="isMonosized(componentOrMeal.id)"
                    :read-only="true"
                    :show-prep-controls="false"
                    :show-prep-status="true"
                    :save-ingredient-assignment="saveComponentIngredientAssignment"
                  />
                </template>
              </template>
            </v-card>
          </div>
        </template>
      </div>
    </template>
  </div>

</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import api from '@/api';
import moment from 'moment';
import ComponentIngredients from "@/components/ComponentIngredients";
import {deepCopy, formatWeight} from "@/store/utils";
import PrepTeamIcon from "@/components/PrepTeamIcon";
import IngredientAutocomplete from "@/components/IngredientAutocomplete.vue";

function normalise(ingredients, total) {
  if (!total) {
    total = ingredients.reduce((sum, i) => i.cookedAmount + sum, 0);
  }
  const ratio = 100.0 / total;
  // console.log('normalising', {total, ratio});
  const normalisedIngredients = ingredients.map(i => {
    const cookedAmount = i.cookedAmount * ratio;
    const rawAmount = i.rawAmount * ratio;
    // console.log('normalise ' + i.cookedAmount, cookedAmount);
    return {...i, cookedAmount, rawAmount};
  });
  // const newTotal = normalisedIngredients.reduce((sum, i) => i.cookedAmount + sum, 0);
  // console.log('normalised total after', newTotal);
  return normalisedIngredients;
}

export default {
  components: {IngredientAutocomplete, PrepTeamIcon, ComponentIngredients},
  props: {
    componentId: Number || String,
    date: String,
    dense: Boolean,
    readOnly: {type: Boolean, default: true},
    isAdmin: {type: Boolean, default: false},
    hideMeals: {type: Boolean, default: false},
    normaliseTo100g: {type: Boolean, default: false},
    noReloadOnAddIngredient: {type: Boolean, default: false},
  },
  data() {
    return {
      loading: false,
      showComponentDialog: false,
      newcomponentId: null,
      newcomponentOrders: null,
      showingChangeOrderDialog: false,
      subcomponentFeatureEnabled: false,
      mealIngredients: [],
      componentIngredients: [],
      currentName: null,
      mealIds: [],
      showApplyToUpcoming: false,
      units: ['g', 'kg'],
      decimalPlaces: [3, 2],
      allSelected: false,
      selected: {},
      applyMealIds: [],
      applying: null,
      epsilon: 0.00001,
      assigned: null,
      newComponentName: null,
      showAddIngredient: null,
      ingredientToAdd: null
    }
  },
  watch: {
    normaliseTo100g() {
      this.fetchData();
    },
    hideMeals() {
      this.fetchData();
    },
// call again the method if the route changes
    '$route': 'fetchData',
    componentId: 'fetchData',
    someSelected(isSome) {
      if (!isSome) {
        this.allSelected = this.countSelected > 0;
      }
    }
  },
  computed: {
    ...mapState(['defaultTeams']),
    ...mapGetters([
      'components',
      'getMeal',
      'isMealLoaded',
      'getComponentName',
      'getCookedAmountTotal',
      'getComponent',
      'getRestrictedTags',
      'getRestrictionText',
      'getComponentTags',
      'getDietName',
      'getDietColor'
    ]),
    notFound() {
      return !this.component;
    },
    component() {
      return this.getComponent(this.componentId);
    },
    componentTotal() {
      return this.componentIngredients.reduce((sum, i) => sum + (i.ignore_prep_weight ? 0 : i.cookedAmount), 0);
    },
    defaultComponentIngredients() {
      // default ingredients for the component
      const result = {
        ingredients: []
      };
      result.name = this.component.name;
      result.component_steps = deepCopy(this.component.component_steps);
      this.componentIngredients.forEach(i => {
        result.ingredients.push({...i, component_id: this.componentId});
      });

      const componentTotal = result.ingredients.reduce((sum, i) => sum + i.cookedAmount, 0);
      if (this.normaliseTo100g) {
        const diff = componentTotal - 100.0;
        if (diff > 0.00001) {
          // throw new Error('normalise fail', componentTotal);
          console.error('normalise fail', componentTotal, result);
        }
      }

      return result;
    },
    ingredientsByMeal() {
      if (!this.component) {
        return [];
      }
      const ingredientsByMealId = {};
      let mealIngredients = this.mealIngredients;
      mealIngredients.sort((a, b) => b.mealid - a.mealid);
      mealIngredients.forEach(i => {
        const id = i.mealid;
        ingredientsByMealId[id] = ingredientsByMealId[id] || {
          id,
          ingredients: []
        };
        i.component = i.component || this.component;
        ingredientsByMealId[id].ingredients.push(i);
        const meal = this.isMealLoaded(i.mealid) && this.getMeal(i.mealid);
        if (!meal) {
          if (!this.hideMeals) {
            console.warn('no meal found for id', i.mealid);
          }
        }

        ingredientsByMealId[id].name = meal ? meal.name : `M${i.mealid}`;
        if (!ingredientsByMealId[id].meal) {
          // console.log('meal.is_selected ', meal.sku, meal.date, meal.is_selected)
        }
        ingredientsByMealId[id].meal = meal;
        // very old meals don't have dates, so remove these
        if (!this.hideMeals && !(meal && meal.date)) {
          delete ingredientsByMealId[id];
        }
      });

      Object
        .values(ingredientsByMealId)
        .forEach(group => {
          // console.log('sorting', group);
          const ingredients = group.ingredients;
          ingredients.sort((a, b) => {
            return (a.fooddescriptionsimple || a.fooddescription || '').localeCompare(b.fooddescriptionsimple || b.fooddescription || '');
          });

          const mealComponentTotal = ingredients.reduce((sum, i) => sum + i.cookedAmount, 0);
          const ratio = mealComponentTotal / this.componentTotal;
          group.ratio = ratio;
          group.cookedAmount = mealComponentTotal;
        });

      // console.log('ingredientsByMealId', ingredientsByMealId);
      const sortedIngredients = Object
        .values(ingredientsByMealId)
        .sort((a, b) => {
          function key(i) {
            const meal = i && i.meal;
            let result;
            if (meal) {
              if (meal.is_selected) {
                result = 'Z';
              } else {
                result = meal.date || 'no date';
              }
            } else {
              result = 'X';
            }
            // console.log(`${i.meal.name} ${i.meal.date}  ${!!i.meal.is_selected}`, result);
            return result || '';
          }

          // Z meals no meal or date goes on top
          return -key(a).localeCompare(key(b));
        })
      // console.log('sorted', sortedIngredients.map(i => `${i.meal.name} ${i.meal.date}  ${!!i.meal.is_selected}`));
      return sortedIngredients;
    },
    ingredientsByMealUpcoming() {
      return this.ingredientsByMeal.filter(m => m.meal && moment(m.meal.date).isAfter(moment(), "day"));
    },
    ingredientsByMealSKUVersion() {
      return this.ingredientsByMeal.filter(m => m.meal && !!m.meal.is_selected);
    },
    isNameEdit() {
      return !!this.component.nameEdit;
    }
    ,
    componentTags() {
      return this.getComponentTags();
    }
    ,
    currentTagIds: {
      get() {
        return this.component.tags.map(t => t.component_tag_id);
      }
      ,
      set(values) {
        console.log('values', values);
        console.log('this.componentTags', this.componentTags);
        this.component.tags = values.map(v => ({component_tag_id: v, component_id: this.component.id}));
        console.log('this.component.tags', this.component.tags);
      }
    }
    ,
    countUsingDefault() {
      let count = 0;
      console.log('count')
      this.ingredientsByMeal.forEach(m => {
        if (m.meal && this.isDefault(m)) {
          count++;
        }
      });
      return count;
    }
    ,
    countMeals() {
      return this.mealIds.length;
    },
    upcomingUsingDefault() {
      return this.ingredientsByMealUpcoming.filter(i => this.isDefault(i));
    },
    upcomingNotUsingDefault() {
      return this.ingredientsByMealUpcoming.filter(i => !this.isDefault(i));
    },
    skuVersionNotUsingDefault() {
      return this.ingredientsByMealSKUVersion.filter(i => !this.isDefault(i))
    },
    upcomingAndSkuNotUsingDefault() {
      // Object stuff is to filter duplicates
      return Object.values(
        Object.fromEntries(
          [...this.skuVersionNotUsingDefault, ...this.upcomingNotUsingDefault]
            .map(m => ([m.id, m]))
        )
      );
    },
    countSelected() {
      return this.applyMealIds.filter(id => this.selected[id]).length;
    },
    someSelected() {
      const v = this.applyMealIds;
      return v.length !== this.countSelected && this.countSelected > 0;
    },
    componentHasTemplate() {
      return this.componentIngredients.length > 0;
    },
    componentHasMeals() {
      return this.mealIds.length > 0;
    }
  },
  mounted() {
    console.log('mounted', this.componentId);
    this.$nextTick(() => this.fetchData());
  },
  methods: {
    ...mapActions([
      'fetchMeal',
      'fetchComponent',
      'fetchComponentIngredients',
      'fetchDiets',
      'fetchRestrictions',
      'fetchComponentTags',
      'createComponent',
      'updateIngredient',
      'updateComponent',
      'replaceIngredientFood',
      // 'copycomponent',
      // 'deletecomponent',
      // 'updatecomponent',
      'updateOrder',
      'createComponentTag',
      'updateComponentTags'
    ]),
    ...mapMutations([
      'CLEAR'
    ]),
    saveComponentIngredientAssignment(options) {
      const {team, instruction, food_id, mealId} = options;
      console.log('saveComponentIngredientAssignment ', options);
      const payload = {
        team,
        instruction,
        food_id
      }

      if (mealId) {
        return api.post(`v2/component/${this.componentId}/step/${food_id}`, payload);
      } else {
        return api.post(`v2/component/${this.componentId}/step/${food_id}`, payload);
      }
      // .then(() => this.fetchData());

    },
    saveComponentAssignment(team) {
      // const {team, instruction} = options;
      // console.log('saveComponentAssignment ', options);
      const payload = {
        team,
        // instruction
      }
      const componentSteps = this.component.component_steps;
      const component_id = this.component.id;
      const assignment = componentSteps && componentSteps.find(mc => mc.component_id === component_id);
      if (assignment) {
        assignment.team = team;
      } else {
        componentSteps.push({component_id, team});
      }
      return api.post(`v2/component/${this.componentId}/step`, payload)
      // .then(() => this.fetchData());
    },
    formatWeight,
    fetchData() {
      console.log('fetch detail for component ', this.componentId);

      if (this.loading === this.componentId) {
        console.log('already loading');
        return;
      }
      this.loading = this.componentId;
      const where = {
        component_id: this.componentId
      };
      const url = `/v2/component/${this.componentId}/ingredients`;
      Promise.all([
        this.componentId && this.fetchComponent(this.componentId),
        this.componentId && api.get(url)
          .then(r => {
            const {data: {meal_foods, component_foods}} = r;
            console.log(url, {where, meal_foods, component_foods});

            function getTeam(f) {
              const steps = f.component_food_step || f.meal_food_step
              if (steps && steps[0]) {
                return steps[0].team;
              }
            }

            function getComponentInstruction(f) {
              const steps = f.component_food_step;
              if (steps && steps[0]) {
                return steps[0].instruction;
              }
            }

            function getMealFoodInstruction(f) {
              const steps = f.meal_food_step;
              if (steps && steps[0]) {
                return steps[0].instruction;
              }
            }

            const flattenMealFood = f => ({
              ...f,
              ...f.food_name,
              team: getTeam(f),
              instruction: getMealFoodInstruction(f),
              prep_instruction: getMealFoodInstruction(f),
            });
            const flattenComponentFood = f => ({
              ...f,
              ...f.food_name,
              team: getTeam(f),
              instruction: getComponentInstruction(f),
              prep_instruction: getComponentInstruction(f),

            });
            const mealFoods = r.data.meal_foods;
            this.mealIngredients = mealFoods.map(flattenMealFood);
            console.log('this.mealIngredients', this.mealIngredients);
            this.componentIngredients = r.data.component_foods.map(flattenComponentFood);
            if (this.normaliseTo100g) {
              this.componentIngredients = normalise(this.componentIngredients, this.componentTotal);
            }
            console.log('this.componentIngredients', this.componentIngredients);
            this.componentIngredients.sort((a, b) => {
              return (a.fooddescriptionsimple || a.fooddescription || '').localeCompare(b.fooddescriptionsimple || b.fooddescription || '');
            });

            if (this.hideMeals) {
              // prevent loading
              this.mealIds = [];
            } else {
              this.mealIds = [...new Set(this.mealIngredients.map(i => i.mealid))];
            }

            this.selected = {};
            for (const mealId of this.mealIds) {
              this.$set(this.selected, mealId, false);
            }
            return Promise.all(this.mealIds.map(mealId => this.fetchMeal(mealId, {force: true})));
          }),
        this.fetchDiets(),
        this.fetchRestrictions(),
        this.fetchComponentTags()
      ])
        .then(() => this.loading = false);
    },
    isDetected(restrictionName) {
      const restriction = this.getRestrictedTags([restrictionName]);
      const activeTags = this.showTags(restriction, this.ingredients);
      // console.log('activeTags', activeTags);
      return Object.keys(activeTags).length > 0;
    },
    showTags(restrictedTagIds, ingredients) {
      // console.log('restrictedTagIds', restrictedTagIds);
      const tags = {};
      ingredients
        .forEach(i => {
          (i.tagids || []).forEach(tagId => {
            // console.log('i',[tagId,i.tagids]);
            (restrictedTagIds[tagId] || []).forEach(text => {
              // console.log('i text',text);
              tags[text] = tags[text] || 0;
              tags[text]++;
            });
          });
        });
      // console.log('tags',tags);
      return tags;
    },
    isMonosized(id) {
      return this.components[id] && this.components[id].monosized;
    },
    addIngredient(ingredient) {
      console.log('adding', ingredient);
      const component_id = this.componentId;
      const componentName = this.getComponentName(component_id);
      ingredient = {
        foodid: ingredient.foodid,
        component_id,
        component_name: componentName,
        fooddescriptionsimple: '',
        editing: true,
        componentId: this.component.id,
        portionmultiplier: 1,
        portion_basis: 1455
      };
      console.log('adding', ingredient);
      api.post(`v2/component/${this.component.id}/ingredient/${ingredient.foodid}`, ingredient)
        .then(r => console.log('updated', r))
        .then(() => {
          if (this.noReloadOnAddIngredient) {
            //just reload this component
            this.fetchData();
          } else {
            // yucko - state should be reloaded correctly, but refresh works for now
            // i can't remember for sure, but i think this is on the component screen (checking all meals that use it, etc)
            window.location.reload();
          }
        })

        .catch(e => console.error('failed', e));
    },
    editName() {
      this.$set(this.component, 'nameEdit', true);
      this.currentName = this.component.name;
    },
    saveEditName() {
      this.$set(this.component, 'nameEdit', false);
      console.log('call update component in store');
      this.updateComponent({id: this.component.id, component: this.component});
    },
    cancelEdit() {
      this.component.nameEdit = false;
      this.component.name = this.currentName;
    },
    formatDate(date) {
      let text = '';
      if (date !== null) {
        // text = moment(date).format('Do MMM YYYY');
        text = moment(date).format(moment.HTML5_FMT.DATE);
      }
      return text;
    },
    addTag() {
      const newTag = prompt('Enter a new tag')
      if (newTag) {
        this.createComponentTag(newTag);
      }
    },
    updateTags() {
      console.log('component.tags', this.component.tags);
      this.updateComponentTags({id: this.component.id, tags: this.component.tags});
    },
    async makeDefault(componentInstance) {
      console.log('component instance', componentInstance);
      const ingredients = componentInstance.ingredients;
      const mealComponentStep = (componentInstance.meal.meal_component_steps || [])
        .find(mc => mc.component_id === this.component.id);
      console.log('found team assigned', mealComponentStep);
      if (mealComponentStep) {
        await this.saveComponentAssignment(mealComponentStep.team)
      }
      api.put(`v2/component/${this.component.id}/ingredients`, {ingredients})
        .then(r => console.log('updated', r))
        // reload after update
        .then(() => this.fetchComponent({id: this.component.id, force: true}))
        .then(() => this.fetchData())
        .catch(e => console.error('failed', e));
    },
    isDefault(componentInstance) {
      if (this.findAssignedTeam(this.component) !== this.findAssignedTeam(componentInstance)) {
        return false;
      }
      const ingredients = componentInstance.ingredients;
      if (ingredients.length !== this.componentIngredients.length) {
        return false;
      }
      const componentTotal = this.componentIngredients.reduce((sum, i) => sum + i.cookedAmount, 0);
      const mealComponentTotal = ingredients.reduce((sum, i) => sum + i.cookedAmount, 0);
      const ratio = componentTotal / mealComponentTotal;

      // console.log('compare ratio', {ratio, componentTotal, mealComponentTotal});
      for (let i = 0; i < ingredients.length; i++) {
        const ingredient = ingredients[i];
        const defaultIngredient = this.componentIngredients[i];

        // console.log('teams', defaultIngredient.team, ingredient.team);
        if (defaultIngredient.team !== ingredient.team) {
          return false;
        }
        // console.log('defaultIngredient.instruction', defaultIngredient.instruction);
        // console.log('ingredient.instruction', ingredient.instruction);
        if (defaultIngredient.instruction !== ingredient.instruction) {
          return false;
        }
        // console.log('compare foodid', ingredient.foodid, defaultIngredient.foodid, ingredient.fooddescriptionsimple, defaultIngredient.fooddescriptionsimple,);
        if (ingredient.foodid !== defaultIngredient.foodid) {
          return false;
        }
        // console.log('comparing amount', ingredient.cookedAmount, defaultIngredient.cookedAmount, ingredient.cookedAmount * ratio)
        // const isDifference = (ingredient.cookedAmount * ratio) !== defaultIngredient.cookedAmount;
        const difference = Math.abs((ingredient.cookedAmount * ratio) - defaultIngredient.cookedAmount);
        const isDifference = difference > this.epsilon;
        if (isDifference) {
          return false;
        }
      }
      return true;

    },
    computeAdjustmentToDefault(ingredients, ratio) {
      const adjustments = deepCopy(ingredients);
      const foodids = adjustments.map(({foodid}) => foodid);

      // add ingredients that aren't in the meal currently
      for (const componentIngredient of this.componentIngredients.filter(i => !foodids.includes(i.foodid))) {
        const i = deepCopy(componentIngredient);
        i.cookedAmount = 0; // as not currently in meal!
        i.team = '';
        i.instruction = '';
        adjustments.push(i);
      }

      for (const i of adjustments) {
        const {foodid} = i;
        const componentIngredient = this.componentIngredients.find(j => j.foodid === foodid);
        if (componentIngredient) {
          console.log('checking ' + i.fooddescriptionsimple);
          i.adjustedCookedAmount = componentIngredient.cookedAmount * ratio;
          if (i.team !== componentIngredient.team) {
            i.adjustTeam = componentIngredient.team;
          }
          if (i.instruction !== componentIngredient.instruction) {
            i.adjustInstruction = componentIngredient.instruction;
          }
        } else {
          // ingredient no longer in component
          i.adjustedCookedAmount = 0;
        }
      }

      for (const i of adjustments) {
        i.adjustment = i.adjustedCookedAmount - i.cookedAmount;
        if (Math.abs(i.adjustment) < this.epsilon) {
          i.adjustment = 0;
          i.adjustedCookedAmount = i.cookedAmount;
        } else {
          i.adjustment = Number(i.adjustment.toFixed(2));
          i.adjustedCookedAmount = Number(i.adjustedCookedAmount.toFixed(2));
        }
      }
      return adjustments;
    },
    setSelected() {
      for (const i of this.applyMealIds) {
        this.selected[i] = this.allSelected;
      }
    },

    applySelected() {
      const mealIds = this.applyMealIds.map(mealId => this.selected[mealId] && mealId).filter(id => !!id);
      if (mealIds.length === 0) {
        alert('no meals selected');
        return;
      }

      if (!confirm('are you sure, there is no undo?')) {
        return;
      }

      this.applying = true;

      api.post(`v2/component/${this.component.id}/update-meals`, {mealIds})
        .then(r => console.log('success', r))
        // UGH!!!
        .then(() => window.location.reload())
        // .then(() => this.fetchData())
        // .then(() => {
        //   this.showApplyToUpcoming = false;
        //   this.applying = false;
        // })
        .catch(e => console.error('fail', e));
    },
    showApplyToUpcomingDialog() {
      if (this.componentIngredients.length === 0) {
        alert('There is no default component yet.  Click "Make Default" below to make that the default component');
        return;
      }
      this.$set(this, 'selected', {});
      this.mealIds.forEach(id => this.$set(this.selected, id, false));
      const upcomingMealIds = this.upcomingNotUsingDefault.map(u => u.meal.id);
      const skuMealIds = this.skuVersionNotUsingDefault.map(u => u.meal.id);
      console.log('upcoming ids', upcomingMealIds);
      console.log('sku ids', skuMealIds);
      this.applyMealIds = [...skuMealIds, ...upcomingMealIds];
      this.applyMealIds.forEach(id => this.$set(this.selected, id, true));
      this.allSelected = true;
      this.showApplyToUpcoming = true;
    },
    findAssignedTeam(componentOrMeal) {
      if (!componentOrMeal) {
        return '';
      } else if (componentOrMeal.meal) {
        const assignment = componentOrMeal.meal && componentOrMeal.meal.meal_component_steps.find(mc => mc.component_id === this.component.id);
        return assignment && assignment.team
      } else {
        // console.log('find assigned team for component', componentOrMeal.component_steps)
        const assignment = componentOrMeal.component_steps && componentOrMeal.component_steps.find(mc => mc.component_id === this.component.id);
        return assignment && assignment.team
      }
    },
    onUpdateIngredient() {
      // sad panda face...  should not require reload, just update data.
      // window.location.reload();
      this.fetchData();
    },
    createNewComponent(name) {
      this.createComponent({name, monosized: false})
        .then(r => {
          this.$emit('update:componentId', r.id);
        });
    },
    normalise
  }
}
</script>

