<template>
  <v-container fluid>
    <v-row v-if="!loading">
      <v-col :cols="columnsPerMeal" v-for="({mealId,dietId, tod},index) of mealIds" v-bind:key="index">
        <h3>{{ getDietName(dietId) }} ({{ getTimeOfDay(tod) }})</h3>
        <Meal :mealId="`${mealId}`" :date="date" dense :read-only="readOnly"/>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import Meal from '@/components/Meal';
import {mapActions, mapGetters} from 'vuex';
import {extractDateFromDateProduction} from "@/store/utils";

export default {
  watch: {
    // call again the method if the route changes
    '$route': 'fetchData'
  },
  created() {
    this.readOnly = !!this.$route.meta.readOnly;
  },
  mounted() {
    console.log('mounted fetching meals', this.date);
    this.fetchData();
  },
  data() {
    return {
      loading: true,
      dialog: true,
      readOnly: null
    }
  },
  components: {
    Meal
  },
  computed: {
    ...mapGetters([
      'getDietName',
      'getMealsByDiet',
      'getTimeOfDay',
      'getMeal'
    ]),
    selection() {
      const result = {};
      this.$route.params.selection.split(',')
        .forEach(dietAndTod => {
          result[dietAndTod] = true;
        })
      return result;
    },
    date() {
      return this.$route.params.date;
    },
    mealIds() {
      return (this.$route.params.selection || '').split(',')
        .map(id => this.getMeal(id))
        .sort((m1,m2)=> m1.tod-m2.tod)
        .map(meal => {
          return {
            mealId: meal.id,
            dietId: meal.diet,
            tod: meal.tod
          };
        })
      // const mealIds = [];
      // this.getMealsByDiet(this.date).forEach(({id, meals}) => {
      //   meals.forEach(meal => {
      //     const dietAndTod = `${id}:${meal.tod}`
      //     if (this.selection[dietAndTod]) {
      //       mealIds.push({mealId: meal.id, dietId: id, tod: meal.tod});
      //     }
      //   })
      // });
      // return mealIds;
    },
    columnsPerMeal() {
      const numberOfMeals = this.mealIds.length;
      let cols = ((12 / numberOfMeals).toFixed(0) || 1);
      return cols;
    }
  },
  methods: {
    ...mapActions([
      'fetchMeals',
      'fetchDiets'
    ]),
    fetchData() {
      this.loading = true;
      Promise.all([
        this.fetchMeals(extractDateFromDateProduction(this.date)),
        this.fetchDiets()
      ])
        .then(() => {
          console.log('loaded')
          this.loading = false;
        });
    }
  }
}
</script>
