// const SMALL_BAG = 3.5;
// const LARGE_BAG = 10;

import {formatAddress} from "@/store/utils";


// const SNACKS = 0.5;
// const SMALL_MEAL = 0.83;
// const REGULAR_MEAL = 1;
const QRCode = require('qrcode');
const md5 = require('md5');

const QRCodeSize = 200;
export default class BagCalc {
  static QRCodeSize() {
    return QRCodeSize
  }

  static async generateQRCodes(qrCodeValue, {size = null}) {
    return QRCode.toDataURL(qrCodeValue, {type: 'svg', width: size || QRCodeSize, height: size || QRCodeSize});
  }

  static computeBags(groupedByCustomer) {
    const driverCounts = {};
    const customerLabels = Object.keys(groupedByCustomer).map((customerName, index) => {
      const orders = groupedByCustomer[customerName];
      const count = BagCalc.getNumberOfBags(orders);
      const driver = orders[0].driver;
      const eta = orders[0].eta || orders[0].ship_date;
      const address = formatAddress(orders[0].address, {short: true});
      driverCounts[driver] = driverCounts[driver] || 0;
      driverCounts[driver] += count;
      const qrCodeValues = BagCalc.generateQRValues(customerName, {count});
      return {
        eta,
        customerName: customerName,
        driver,
        bins: BagCalc.getBins(orders),
        qrCodeValues,
        count,
        bagSize: BagCalc.getSizeOfBags(orders),
        sequence: index + 1,
        address_line_1: address.split(',')[0],
        address_line_2: address.split(',').slice(1).join(', ')
      }
    })
    return {driverCounts, customerLabels};
  }

  static generateQRValues(qrBase, {count, orders}) {
    // strip base into just letters (ignore *, spaces, case changes)
    qrBase = qrBase.replace(/\W/g, '').toLowerCase();
    if (!count && orders) {
      count = BagCalc.getNumberOfBags(orders);
    }
    const qrCodeValues = [...Array(count).keys()]
      .map(i => `${qrBase}-${i + 1}`)
      .map(s => md5(s))
      .map(s => s.slice(0, 12));
    return qrCodeValues;
  }

  static getNumberOfBags(orders) {
    const useBags = false;
    const MEALS_PER_BAG = 4;

    let mealUnits = this.getSizeValue(orders);

    if (useBags) {
      return Math.ceil(mealUnits / MEALS_PER_BAG) || 1;
    } else {
      // standard box logic, presuming all box sizes available,
      // one sticker per 12 meals.
      return Math.max(1, Math.ceil(mealUnits / 12))


      // odd logic - when certain boxes were not available

      // 4 meals 1 sticker
      // 5/6 meals 2 stickers
      // 7-12 meals 2 stickers (no more red box)
      // 13 + 2 stickers too ( red + blue

      // if (mealUnits <= 4) {
      //   return 1;
      // } else if (mealUnits <= 6) {
      //   return 2;
      // } else if (mealUnits <= 12) {
      //   return 2;  // out of red boxes, so have to use 2 boxes
      // } else { // if total > 12
      //   return 2;
      // }
    }
  }

  static getSizeOfBags(/* orders */) {
    return 'standard';
    // let total = this.getSizeValue(orders);
    // if (total >= 8) {
    //   return 'large';
    // }
    // return 'small';
  }

  static getBins(orders) {
    const orderBins = {};
    orders.forEach(o => {
      if (o.bin) {
        let bins = (o.bin || '').split(',').filter(b => !!b);
        for (const bin of bins) {
          orderBins[bin] = 1
        }
      }
    });

    function extractLetterAndNumber(s) {
      // const regexp = /^([A-Za-z]).*?(\d+)?-(\d+)$/;
      // const [, rackLetter, rackNumber, binNumber] = (s || '').match(regexp);
      // return `${rackLetter}${rackNumber || ''}${rackNumber ? '.' : ''}${binNumber}`
      const parts = s.split('.');
      if (parts.length === 1) {
        return s;
      } else {
        const regexp = /^([A-Za-z]*)\.?([A-Za-z]).*-(\d+)$/;
        const [, rackName, rackNumber, binNumber] = (s || '').match(regexp);
        return `${rackName}`
          + (rackNumber ? `.${rackNumber}` : '')
          + `-${binNumber}`;
      }
    }

    return Object.keys(orderBins).map(extractLetterAndNumber).sort();
  }

  static getSizeValue(orders) {
    const categoryToSpaceMap = {
      'protein side': 1,
      'breakfast': 1,
      'single serve entree': 1,
      'snack': 0.5,
      // 'family size': 6 -- exclude
    }

    function isFruitCup(o) {
      return o.meal_id === 11308;
    }

    function getMealSpaceUsed(o) {
      if (o.category) {
        if (categoryToSpaceMap[o.category]) {
          return categoryToSpaceMap[o.category];
        } else {
          // logger.warn('category not found', o.category, o);
          return 0;
        }
      }
      return isFruitCup(o) ? 0.5 : 1;
    }


    let total = 0.0;
    // let mealValue = REGULAR_MEAL; //size === 'small' ? SMALL_MEAL : REGULAR_MEAL;

    for (const item of orders) {
      const mealValue = getMealSpaceUsed(item)
      total += item.quantity * mealValue;
    }

    if (total === 0 && orders.length > 1) {
      console.log('adding minimum of 1 sticker despite no space used', orders)
      total = 1;
    }
    return total;
  }
}
