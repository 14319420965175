<template>
  <v-card flat class="">
    <v-card-text v-if="isLoading">
      Fetching order metrics, please be patient, this takes time...
    </v-card-text>
    <template v-if="!isLoading">
      <v-card-actions>
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="pickerDates"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="pickerDates"
              label="Order Weeks"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              style="max-width: 250px"
              class="mx-2"
            />
          </template>
          <v-date-picker
            v-model="pickerDates"
            :allowed-dates="allowedDates"
            range
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="menu = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="setRange(pickerDates); menu=false"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
        <v-radio-group dense
                       v-model="group"
                       class="mx-4"
        >
          <v-radio value="plan" label="Plan"/>
          <v-radio value="stream" label="Stream"/>
        </v-radio-group>
        <v-select
          label="Include"
          class="mr-2"
          v-model="filterIncludeCode"
          :items="includeCodes"
          item-text="name"
          item-value="value"
        />
        <v-chip-group v-if="showChips">
          <v-chip>
            {{ includeStatus }}
          </v-chip>
          <v-chip>
            {{ includeRatio }}
          </v-chip>
        </v-chip-group>
        <v-select
          label="Churn Condition"
          class="mr-2"
          v-model="filterChurnCode"
          :items="churnCodes"
          item-text="name"
          item-value="value"
        />
        <v-chip-group v-if="showChips">
          <v-chip>
            {{ churnStatus }}
          </v-chip>
          <v-chip>
            {{ churnRatio }}
          </v-chip>
        </v-chip-group>
      </v-card-actions>

      <div>
        <v-alert v-if="!isValidTimeRange" type="warning" text outlined>
          You need to select a time period greater than two weeks
        </v-alert>
        <v-tabs
          v-model="tab"
          grow
        >
          <v-tab>
            Summary
          </v-tab>
          <v-tab>
            Churn
          </v-tab>
          <v-tab>
            Included
          </v-tab>
          <v-tab>
            All
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card flat>
              <v-card-text>

                <v-data-table
                  :items="churnSummaryForAllStreams"
                  :headers="churnSummaryHeaders"
                  disable-pagination
                  hide-default-footer
                >
                  <template v-slot:item.ratio="{item : { ratio}}">
                    {{ ratio }} %
                  </template>
                  <template v-slot:item.ratioValue="{item : { ratioValue}}">
                    {{ ratioValue }} %
                  </template>
                  <template v-slot:item.churnValue="{item : {churnValue} }">
                    {{ formatCurrency(churnValue) }}
                  </template>
                  <template v-slot:item.totalValue="{item : {totalValue} }">
                    {{ formatCurrency(totalValue) }}
                  </template>

                </v-data-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-actions>
                <v-select
                  label="Churn Condition"
                  class="mr-2"
                  v-model="filterChurnCode"
                  :items="churnCodes"
                  item-text="name"
                  item-value="value"
                />
                <v-text-field
                  spellcheck="false"
                  label="Churn Condition"
                  v-model="filterChurnCode"
                  :error="!!filterChurnCodeError"
                  :error-messages="filterChurnCodeError"
                />
                <v-chip-group v-if="showChips">
                  <v-chip>
                    {{ churnStatus }}
                  </v-chip>
                  <v-chip>
                    {{ churnRatio }}
                  </v-chip>
                </v-chip-group>
                <v-btn outlined @click="csvExport(churnRecords,`churn-${start}-${end}.csv`)">Download CSV</v-btn>
              </v-card-actions>
              <v-card-text>
                <MetricsTable :headers="headers" :metrics="churnRecords"/>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card>
              <v-card-actions>
                <v-select
                  label="Include"
                  class="mr-2"
                  v-model="filterIncludeCode"
                  :items="includeCodes"
                  item-text="name"
                  item-value="value"
                />
                <v-text-field
                  spellcheck="false"
                  label="Include Formula"
                  v-model="filterIncludeCode"
                  :error="!!filterIncludeCodeError"
                  :error-messages="filterIncludeCodeError"
                />
                <v-chip-group v-if="showChips">
                  <v-chip>
                    {{ includeStatus }}
                  </v-chip>
                  <v-chip>
                    {{ includeRatio }}
                  </v-chip>
                </v-chip-group>
                <v-spacer/>
                <v-btn outlined @click="csvExport(includedRecords,`included-${start}-${end}.csv`)">Download CSV</v-btn>
              </v-card-actions>
              <v-card-text>
                <MetricsTable :headers="headers" :metrics="includedRecords"/>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-actions>
                <v-spacer/>
                <v-btn outlined @click="csvExport(allRecords,`all-${start}-${end}.csv`)">Download CSV</v-btn>
              </v-card-actions>
              <v-card-text>
                <MetricsTable :headers="headers" :metrics="allRecords"/>
              </v-card-text>
            </v-card>
          </v-tab-item>

        </v-tabs-items>

      </div>
    </template>
  </v-card>
</template>

<script>
import api from "@/api";
import urlState from "@/router/urlState";
import moment from "moment";
import {csvExport, formatCurrency} from "@/store/utils";
import {compileExpression} from "filtrex";
import MetricsTable from "@/components/MetricsTable";

function toPercent(number) {
  return (Number(number) * 100).toFixed(2);
}

const expressionOptions = {}

export default {
  name: "Metrics",
  components: {MetricsTable},
  mixins: [urlState],
  async mounted() {

    // no bloody idea why, but chip-group produce an error when rendering initially, so delay showing them,
    // so, kludge it
    setTimeout(() => this.showChips = true, 500);

    this.syncToUrl({
      transformCallback: s => (s && s.toString) ? s.toString() : undefined,
      parseCallback: (s) => Number(s),
      param: 'tab', urlParam: 'tab', initFromRoute: true
    });

    this.syncToUrl({
      param: 'group', urlParam: 'group', initFromRoute: true
    });
    this.syncToUrl({
      param: 'start', urlParam: 'start', initFromRoute: true
    });
    this.syncToUrl({
      param: 'end', urlParam: 'end', initFromRoute: true
    });

    // does not work - too much funky stuff in churn code
    // this.syncToUrl({
    //   param: 'filterChurnCode', urlParam: 'churn-code', initFromRoute: true
    // });

    this.start = this.start || moment().startOf('week').subtract(1, 'weeks').format(moment.HTML5_FMT.DATE);
    this.end = this.end || moment(this.start).add(1, 'week').format(moment.HTML5_FMT.DATE);
    this.pickerDates = [this.start, this.end];
    this.filterIncludeCode = this.includeCodes[0].value;
    this.filterChurnCode = this.churnCodes[0].value;
    return this.fetch();
  },
  watch: {
    group: 'fetch',
    start() {
      this.pickerDates = [this.start, this.end];
    },
    end() {
      this.pickerDates = [this.start, this.end];
    },
  },
  data() {
    return {
      allRecords: [],
      group: 'plan',
      start: '',
      end: '',
      headers: [],
      isLoading: undefined,
      pickerDates: [],
      menu: null,
      filterIncludeCode: null,
      filterChurnCode: null,
      tab: null,
      includeCodes: [
        {
          name: 'no new users and email not @fedfedfed.com',
          value: 'invoices > 0 and not (email ~= "@fedfedfed.com")'
        },
        {name: 'all users', value: ''},
        {name: 'only allergy', value: 'not empty(restrictions)'},
        {name: 'meals>30', value: 'total > 30'},
        {name: 'stream is keto', value: 'stream == "keto"'},
        {name: 'plan is keto', value: 'plan == "keto"'},
      ],
      churnCodes: [
        {name: 'last order 1 week ago', value: 'W1 == 0 and W2 > 0'},
        {
          name: 'antichurn - customer ordered this week but not in previous week (but had ordered before)',
          value: 'W1 > 0 and W2 == 0 and last'
        },
        {name: 'last order 2 weeks ago', value: 'W1 == 0 and W2 == 0 and W3 > 0'},
        {name: 'last order 3 weeks ago', value: 'W1 == 0 and W2 == 0 and W3 == 0 and W4 > 0'},
        {name: 'last order 4 weeks ago', value: 'W1 == 0 and W2 == 0 and W3 == 0 and W4 == 0 and W5 > 0'},
        {name: 'no order for 1 week', value: 'total > 0 and W1 == 0'},
        {name: 'no order for 2 weeks', value: 'total > 0 and W1 + W2 == 0'},
        {name: 'no order for 3 weeks', value: 'total > 0 and W1 + W2 + W3 == 0'},
        {name: 'no order for 4 weeks', value: 'total > 0 and W1 + W2 + W3 + W4 == 0'},
        {name: 'show everything', value: ''},
      ],
      dateHeaders: [],
      showChips: false,
      churnSummaryHeaders: [
        {value: 'ratio', text: 'Churn %'},
        {value: 'stream', text: 'Stream'},
        {value: 'churnCount', text: 'Churn Count'},
        {value: 'totalCount', text: 'Total Count'},
        {value: 'churnValue', text: 'Churn Customer Total $'},
        {value: 'totalValue', text: 'Customer Total $'},
        {value: 'ratioValue', text: 'Rev %'}
      ]
    }
  },
  computed: {
    includedStreams() {
      return [...new Set(this.includedRecords.map(r => r.stream))];
    },
    churnSummaryForAllStreams() {
      const result = [];
      console.log('churnSummaryForAllStreams', this.includedStreams);

      const toSummaryRecord = (stream, churnCount, totalCount, churnValue, totalValue) => {
        return {
          stream,
          churnCount,
          totalCount,
          ratio: this.getChurnRatioPercent(churnCount, totalCount, false),
          churnValue, totalValue,
          ratioValue: this.getChurnRatioPercent(churnValue, totalValue, false),
          status: this.getStatus(churnCount, totalCount)
        }
      }

      this.includedStreams
        .forEach(stream => {
          const streamIncludedCode = this.filterIncludeCode ? `${this.filterIncludeCode} and stream == "${stream}"` : `stream == "${stream}"`;
          const streamChurnCode = `${this.filterChurnCode} and stream == "${stream}"`;
          const totalRecords = this.applyFilter(this.allRecords, streamIncludedCode);
          const churnRecords = this.applyFilter(totalRecords, streamChurnCode);
          const totalValue = this.getSum(totalRecords, 'net');
          const churnValue = this.getSum(churnRecords, 'net');
          result.push(toSummaryRecord(stream, churnRecords.length, totalRecords.length, churnValue, totalValue));
        })

      const totalValue = this.getSum(this.includedRecords, 'net');
      const churnValue = this.getSum(this.churnRecords, 'net');
      result.push({...toSummaryRecord('overall', this.churnRecords.length, this.includedRecords.length, churnValue, totalValue)});
      return result;
    },

    isValidTimeRange() {
      const {start, end} = this;
      const diff = moment(end).diff(start, 'weeks');
      const isValid = diff >= 1;
      // console.log('isValidTimeRange', start, end, diff, isValid);
      return isValid
    },
    filterIncludeCodeError() {
      return this.checkFilter(this.filterIncludeCode);
    },
    includedRecords() {
      console.log('includedRecords filter', this.allRecords);
      const result = this.applyFilter(this.allRecords, this.filterIncludeCode);
      console.log('includedRecords result', this.filterIncludeCode, result);
      return result;
    },
    filterChurnCodeError() {
      return this.checkFilter(this.filterChurnCode);
    },
    churnRecords() {
      return this.applyFilter(this.includedRecords, this.filterChurnCode);
    },
    allRecordsCount() {
      return this.allRecords.length;
    },
    includeCount() {
      return this.includedRecords.length;
    },
    churnCount() {
      return this.churnRecords.length;
    },
    includeStatus() {
      return `include ${this.includeCount} of ${this.allRecordsCount}`
    },
    churnStatus() {
      return this.getStatus(this.churnCount, this.includeCount);
    },
    includeRatio() {
      return this.getChurnRatioPercent(this.includeCount, this.allRecordsCount);
    },
    churnRatio() {
      return this.getChurnRatioPercent(this.churnCount, this.includeCount);
    }
  },
  methods: {
    formatCurrency,
    async fetch() {
      const {start, end, isLoading, isValidTimeRange} = this;

      // function toLetter(i) {
      //   return String.fromCharCode('A'.charCodeAt(0) + Number(i));
      // }
      function toWeekLabel(date, dates) {
        const dateIndex = dates.indexOf(date);
        if (dateIndex != -1) {
          return `W${dates.length - dateIndex}`;
        }
      }

      if (!isLoading && isValidTimeRange) {
        this.isLoading = true;
        const group = this.group;
        console.log('fetching metrics', {params: {start, end, group}});
        const {data: rows} = await api.get('v2/metrics', {params: {start, end, group}});
        console.log('api result', rows);
        const headers = rows.shift();
        //headers.shift();
        const dateHeaders = headers.filter(h => moment(h, moment.HTML5_FMT.DATE, true).isValid());
        this.dateHeaders = dateHeaders;
        this.allRecords = rows.map(r => {
          const metric = {};
          headers.forEach((h, i) => {
            metric[h] = r[i];
          });
          // metric.net = Number(((metric.net || 0) / 100).toFixed(2));
          metric.net = metric.net || 0;
          metric.invoices = metric.invoices || 0;
          metric.last_weeks = metric.last && moment().diff(metric.last, 'weeks');
          metric.total = dateHeaders.reduce((sum, date) => sum + metric[date], 0);
          dateHeaders.forEach(date => metric[toWeekLabel(date, dateHeaders)] = metric[date]);
          return metric;
        });

        headers.push('total');
        headers.push('last_weeks');
        this.headers = headers
          .filter(h => h !== 'uid')
          .map((h) => {
            let text;
            const weekLabel = toWeekLabel(h, dateHeaders)
            if (weekLabel) {
              text = `${h} (${weekLabel})`;
            } else {
              text = h;
            }

            return {text, value: h}
          });

        console.log('headers', this.headers);
        this.isLoading = false;
        console.log('done fetch');
      } else {
        console.log('skip fetch', {isLoading, isValidTimeRange});
      }
    },
    csvExport,
    allowedDates(d) {
      return moment(d).day() == 0;
    },
    setRange(pickerDates) {
      const [start, end] = pickerDates.sort();
      this.start = start;
      this.end = end;
      return this.fetch();
    },
    getSum(metrics, key) {
      const sum = metrics.reduce((sum, r) => sum + r[key], 0);
      console.log('sum', {key, sum, metrics});
      return sum;
    },
    applyFilter(records, filterCode) {
      // console.log('apply filter ', {filterCode, records});
      let result = records.slice();
      if (filterCode) {
        let filter;
        try {
          filter = compileExpression(filterCode, expressionOptions);
          result = result.filter(filter);
        } catch (e) {
          result = [];
        }
      } else {
        // console.log('filter empty')
        return result;
      }
      // console.log('filter result: ', {filterCode, result});
      return result;
    },
    checkFilter(filterCode) {
      if (filterCode)
        try {
          compileExpression(filterCode)
        } catch (e) {
          return e.message;
        }
      return undefined;
    },
    getStatus(churnCount, totalCount) {
      return `churn ${churnCount} of ${totalCount}`;
    },
    getChurnRatioPercent(churnCount, totalCount, asString = true) {
      if (totalCount === 0 && churnCount === 0) {
        return asString ? '0' : '';
      }
      const percent = toPercent(churnCount / totalCount);
      return asString
        ? `${percent}%`
        : ((churnCount / totalCount) * 100).toFixed(2);
    }
  }
}
</script>

